<template>
  <div class="model-item-container">
    <a ref="linkContainer" class="model-item-link-container" @click="onClick">
      <transition name="fast-transition">
        <img ref="image" class="model-item-img" onerror="this.onerror=null; this.src='/img/placeholder_16_9.jpg'"
          src="/img/placeholder_16_9.jpg" />
      </transition>
      <div class="scene-item-icons-container">
        <img class="scene-item-icon" src="../assets/scene_item_on_map.svg" v-show="hasGeolocation && showMapIcons" v-b-tooltip.hover.top.html.window="onMapTooltip">
        <img class="scene-item-icon" src="../assets/scene_item_public_on_map.svg" v-show="hasPublishedOnMap && showMapIcons" v-b-tooltip.hover.top.html.window="onPublishedOnMapTooltip">
        <img class="scene-item-icon" src="../assets/scene_item_configurator.svg" v-show="hasConfigurator"
          v-b-tooltip.hover.top.html.window="configuratorTooltip" />
      </div>
      <div v-show="isPublic && showPublicLabel" class="model-item-corner-label">
        public
      </div>
      <div v-show="isNew && showNew && !showPublicLabel" class="model-item-corner-label">
        new
      </div>
    </a>
    <div ref="stats" class="model-item-statistics"></div>
    <div class="model-item-info-container">
      <div ref="name" class="model-item-name"></div>
    </div>

    <div v-show="selectable" class="model-item-selection-container" @click="onClickSelection">
      <div class="model-item-selection-checkbox">
        <transition name="fast-transition">
          <div v-show="selected" class="model-item-selection-checkbox-on"></div>
        </transition>
      </div>
    </div>

    <b-tooltip boundary="window" triggers='hover' target="scene-item-icon" placement="top">
      Triangles count
    </b-tooltip>
  </div>
</template>

<script>

import { MODEL_FLAG } from "../const"

export default {
  data: function () {
    return {
      isPublic: false,
      showPublicLabel: false,
      selectable: false,
      selected: false,
      scene: null,
      onSelected: null,
      onSelectItem: this.onSelectItemDefault,
      hasImage: false,
      hasAnnotations: false,
      hasConfigurator: false,
      hasPublishedOnMap: false,
      configuratorTooltip: "Configurator",
      onMapTooltip: "Geolocation",
      onPublishedOnMapTooltip: "Published On Map",
      showNew: true,
      isNew: false,
      hasGeolocation: false,
      showMapIcons: true
    }
  },
  mounted() {

  },
  updated() {

  },
  methods: {
    configure(scene) {
      let img = this.$refs.image
      let name = this.$refs.name
      let stats = this.$refs.stats
      let link = this.$refs.linkContainer

      let locale = this.getModelLocale(scene)
      stats.innerHTML = scene.downloadsCount;
      this.hasImage = this.setupDefaultImagePreviewForModel(img, scene)

      //if (process.env.NODE_ENV === 'development') {
      //  name.innerHTML += scene.hash + " [" + scene.id + "]"
      //} else {
        name.innerHTML = locale ? locale.name : "";
      //}
      this.hasConfigurator = scene.enableConfigurator && scene.flags.includes(MODEL_FLAG.HAS_CONFIGURATOR)
      this.isPublic = !scene.flags.includes(MODEL_FLAG.IS_PRIVATE)
      this.isNew = scene.isNew
      this.scene = scene
      this.hasPublishedOnMap = scene.publishedOnMap
      this.hasGeolocation = !scene.publishedOnMap && Number(scene.latitude) != 0 && Number(scene.latitude) != 0

    },
    onClickSelection() {
      this.selected = !this.selected

      if (this.onSelected) {
        this.onSelected(this, this.selected)
      }
    },
    onClick() {
      this.onSelectItem(this)
    },
    onSelectItemDefault() {
      window.open(this.getURLForScene(this.scene.hash), "_self")
    }
  }
}
</script>

<style lang="scss">
.model-item-info-container {
  min-height: 30px;
}

.model-item-stats-section {
  position: relative;
}

.model-item-container {
  position: relative;
}

.model-item-link-container {
  width: 100%;
  aspect-ratio: 16/9;
  display: inline-block;
  position: relative;
}

.model-item-img {
  background-color: #FAFAFA;
  border-radius: 10px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  aspect-ratio: 16/9;
  width: 100%;
  min-height: 130px;
  object-fit: cover;
  overflow: hidden;
}

.model-item-statistics {
  font-weight: 500;
  right: 5;
  text-align: right;
  position: absolute;
  line-height: 35px;
}

.model-item-name {
  width: 100%;
  padding-right: 45px;
  padding-left: 5px;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Helvetica, sans serif;
}

.model-item-statistics::before {
  content: "";

  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -3px;
  margin-right: 3px;
  background-size: contain;
  vertical-align: middle;

  background-image: url("~@/assets/views.svg");
}

.model-item-selection-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.model-item-selection-checkbox {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #dddddd;
  background-color: white;
}

.model-item-selection-checkbox-on {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #5ab5ba;

  background-image: url("./../assets/checkmark_white.svg");
  background-repeat: no-repeat;
  background-size: 26px 26px
}

.scene-item-icons-container {
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
}

.scene-item-icon {
  width: 25px;
  height: 25px;
  -webkit-filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .4));
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .4));
  opacity: 0.7;
}

.model-item-corner-label {
  position: absolute;
  left: 7px;
  bottom: 7px;
  background-color: #eaac40;
  border-radius: 6px;
  color: black;
  padding: 0px 7px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.2));
}
</style>
