<template>
	<div class="editor-named-material-container" v-b-tooltip.hover.left.window="tooltip">
		<div v-show="headerTitle.length > 0" ref="header" class="editor-property-header">{{headerTitle}}</div>

		<div class="editor-named-material-subcontainer">
			<EditorPropertyString ref="nameInput"></EditorPropertyString>
			<MaterialSelector class="named-material-selector" ref="selector"></MaterialSelector>
		</div>
	</div>
</template>

<script>

import MaterialSelector from './MaterialSelector.vue'
import EditorPropertyString from './EditorPropertyString.vue'

import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			headerTitle: "",
			onChange: null,
			key: "",
			materialId: -1,
			name: "",
			tooltip: ""
		}
	},
	components: {
		EditorPropertyString,
		MaterialSelector
	},
	created() {

	},
	mounted() {

	},
	methods: {
		configure(value) {
			var name = ""	
			var materialId = -1
			if (value) {
				name = value.name;
				materialId = value.materialId
			}
			this.name = name
			this.materialId = materialId

			this.$refs.selector.configure(materialId)
			
			this.$refs.nameInput.propKey = "name"
			this.$refs.nameInput.value = name
			this.$refs.nameInput.headerText = "Display name"

			let _this = this
			this.$nextTick(() => {
				_this.$refs.selector.onChange = _this.onChangeMaterial
				_this.$refs.nameInput.onChange = _this.onNameChange
			});
		},
		onChangeMaterial(value, key) {
			this.materialId = value
			if (!this.name || this.name.length == 0) {
				var material = this.$editor.getMaterialById(value)
				if (material) {
					this.name = material.name
					this.$refs.nameInput.value = material.name
				}
			}
			this.onChange({materialId: value, name: this.name}, this.key)
		},
		onNameChange(value, key) {
			this.name = value
			this.onChange({materialId: this.materialId, name: value}, this.key)
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">

.named-material-selector {
	margin-top: 5px;
	position: relative;
}
.editor-named-material-container {
	margin-bottom: 5px;
	position: relative;
}
.editor-named-material-subcontainer {
	background-color: rgba(0, 0, 0, 0.05);
	padding: 5px;
	border: 1px solid rgba(0, 0, 0, 0.03);
	border-radius: 5px;
}
</style>
