<template>
	<div class="object-editor">
		<ObjectOutliner class="editor-objects-outliner" ref="outliner"></ObjectOutliner>

		<div v-html="selectedObjectName" class="object-selected-header"></div>

		<div ref="objectProperties" v-show="showObjectEdit" class="editor-object-properties-container">

		</div>

		<div class="object-edit-hint" v-show="!showObjectEdit">Select an object to edit</div>
    </div>
</template>

<script>

import ObjectOutliner from './ObjectOutliner.vue';


let sceneObjectsEditor;

export default {
	data: function() {
		return {
			selectedObjectName: "",
			showObjectEdit: false,
			userEditing: true,
			ignorePropertyChange: false,
			materials: [],
			selectedNodeId: -1,
			selectedMeshId: -1,
			// not localized
			propertyEditConfig: {
				"mesh" : [ 
					{"key": "name", "type": "string", "title": "Name" },
					{"key": "visible", "type": "bool", "title": "Visible" },
					{"key": "materialId", "type": "material", "title": "Material"},
					{"key": "", "type": "button", "title": "Edit Material", onClickCallback: function() { return sceneObjectsEditor.onClickEditMaterial(); }},
					{"key": "", "type": "button", "title": "Show Selection", onClickCallback: function() { return sceneObjectsEditor.onClickShowSelection(); }} 
				],
				"node" : [
					{"key": "visible", "type": "bool", "title": "Visible" },
					{"key": "", "type": "button", "title": "Show Selection", onClickCallback: function() { return sceneObjectsEditor.onClickShowSelection(); }}
				]
			},
			propertyEditItemMap: {
				"mesh": {},
				"node": {}
			}
		}
	},
	components: {
		ObjectOutliner
	}, 
	created() {
		sceneObjectsEditor = this
	},
	mounted() {
		this.$refs.outliner.onSelect = this.onObjectSelect
		document.addEventListener("keyup", this.onKeyUp);
		document.addEventListener('mousedown', this.onMouseDown);
	},
	methods: {
		onMouseDown(e) {
			var outliner = this.$refs.outliner

			let hasClickedThis = this.isChildOfParentElementDeep(e.target, this.$el)
			let hasClickedEditorContainer = this.hasElementParentWithId(e.target, 'editor-web-container')
			let hasSelectedAnything = outliner && outliner.selectedObject
			if (hasSelectedAnything && !hasClickedThis && !hasClickedEditorContainer) {
				this.deselect(false)
			}
		},
		deleteCurrentSelection() {
			var outliner = this.$refs.outliner

			if (!outliner) return
			if (!outliner.selectedObject) return

			var deletionEntries = []
			if (outliner.selectedObject.type == "mesh") {
				deletionEntries.push({nodeId: this.$refs.outliner.selectedNodeId, meshId: outliner.selectedObject.id})
			} else if (outliner.selectedObject.type == "node") {
				deletionEntries.push({nodeId: this.$refs.outliner.selectedNodeId, meshId: -1})
			}

			this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["deleteObjects", JSON.stringify(deletionEntries)]);
			this.$refs.outliner.deleteObjects(deletionEntries);
		},
		onKeyUp(e) {
			// avoid object deletion if any element is focused
			var nothingIsFocused = document.activeElement === document.body

			if (nothingIsFocused && (e.key == "Backspace" || e.key == "Delete")) {
				this.deleteCurrentSelection()

				e.preventDefault();
				e.stopPropagation()
			}
		},
		onObjectSelect(object, nodeId) {
			if (object) {
				this.selectedObjectName = object.name;
			} else {
				this.selectedObjectName = ""
			}
			this.showObjectEdit = object != null;

			if (object) {
				if (object.type == "node") {
					this.selectedNodeId = object.id;
					this.selectedMeshId = -1;
				} else if (object.type == "mesh") {
					this.selectedNodeId = nodeId;
					this.selectedMeshId = object.id;
				}
			} else {
				this.selectedNodeId = -1;
				this.selectedMeshId = -1;
			}

			if (this.showObjectEdit) {
				this.showPropertiesEditor(object);
			}
			this.$editor.enableTransformControls(object)
		},
		selectNodeById(isHighlight, nodeId, meshId, fromEngine) {
			if (!isHighlight) {
				this.selectedNodeId = nodeId
				this.selectedMeshId = meshId
			}
			this.$refs.outliner.selectNodeById(isHighlight, nodeId, meshId, fromEngine)
		},
		getMaterialOptions() {
			var result = []

			for (var key in this.materials) {
				var material = this.materials[key]
				result.push({ value: material.id, title: material.name })
			}	
			return result;
		},
		deselect(fromEngine) {
			if (!this.$refs.outliner) return;
			
			this.$refs.outliner.deselect(fromEngine);
		},
		updateObjectList() {
			this.$refs.outliner.configure()
		},
		configure(rootObject, materials) {
			var selectedObjectList = []
			this.$refs.outliner.configure(selectedObjectList)

			if (materials) {
				this.materials = materials;
			}
		},
		showPropertiesEditor(object) {
			var type = object.type

			this.ignorePropertyChange = true;
			this.$refs.objectProperties.innerHTML = ""

			this.$editor.addProperties(this.propertyEditConfig[type], object, this.$refs.objectProperties, this.propertyEditItemMap[type], this.onChangeProperty, null, this.getPropertyValue, null)

			var _this = this
			this.$nextTick(() => {
				_this.ignorePropertyChange = false
			});
		},
		getPropertyValue(key, type) {
			var selectedObject = this.$refs.outliner.selectedObject

			if (key == "visible") {
				var param = {}
				param["node"] = this.selectedNodeId
				param["mesh"] = this.selectedMeshId;
				var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["isObjectVisible", JSON.stringify(param, null, 4)]);
			
				var responseObject = eval( '(' + response + ')');
				return responseObject.visible
			}

			return selectedObject[key]
		},
		onChangeProperty(value, key) {
			var _this = this;
			
			if (this.ignorePropertyChange) return;

			var selectedObject = this.$refs.outliner.selectedObject
			var response = ""

	
			selectedObject[key] = value
			selectedObject["nodeId"] = this.selectedNodeId
			if (selectedObject.type == "mesh") {
				response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["editMesh", JSON.stringify(selectedObject, null, 4)]);
			} else if (selectedObject.type == "node") {
				response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["editNode", JSON.stringify(selectedObject, null, 4)]);
			}


			var responseObject = eval( '(' + response + ')');
			
			var errorObject = responseObject.error;
			if (errorObject && errorObject.length > 0) {
				this.$editor.showMessage(errorObject, true)
				this.$nextTick(() => {
					//this.showPropertiesEditor(object);
				});
			}

			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		onClickEditMaterial() {
			this.$editor.editMaterial(this.$refs.outliner.selectedObject.materialId)
		},
		onClickShowSelection() {
			var param = {}
			param["node"] = this.selectedNodeId
			param["mesh"] = this.selectedMeshId;
			this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["showSelection", JSON.stringify(param, null, 4)]);
		}
	},
	watch: {

	}
}

</script>

<style lang="scss">

.editor-object-properties-container {
	padding: 0px 10px;
}

.object-editor {
	text-align: left;
}
.object-edit-hint {
	text-align: center;
	margin: 15px 10px;
	color: #121212;
}
.object-selected-header {
	width: 100%;
	height: 30px;
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #e1e1e1;
	padding-left: 10px;
	font-weight: 600;
	text-align: left;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#object-outliner-button-container {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.04);
}
#object-outliner-button-container {
	width: 100%;
	height: 44px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
	grid-gap: 5px;
	justify-content: center;
	padding: 5px 5px;
}
.editor-objects-outliner {
	height: 300px !important;
}
</style>
