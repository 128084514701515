<template>
  <main class="explore in-container">
    <div class="website-page">
      <div class="promo__bg"></div>
      <div class="explore__header-container">
        <h2 class="explore__header highlighted">
          {{ $t("invitation.title") }}
        </h2>
      </div>
      <SearchWithMap :is-map-shown="isMapShown" />
    </div>
  </main>
</template>

<script>
import SearchWithMap from "./SearchWithMap/SearchWithMap.vue"

export default {
  components: {
    SearchWithMap,
  },
  props: {
    isMapShown: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
.explore {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  max-width: 1200px !important;

  @media (max-width: $mobile-width-only) {
    padding-top: 60px !important;
  }
  &__header {
    text-align: center;
    font-size: 36px !important;
    line-height: 44px !important;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: inline;

    @extend .font-title;

    @media (max-width: $mobile-width-only) {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }

  &__header-container {
    text-align: center;
    margin-bottom: 35px;

    @media (max-width: $mobile-width-only) {
      margin-bottom: 25px;
    }
  }
}
</style>
