<template>
  <div class="collection-item-container">
    <a ref="linkContainer" class="collection-item-link-container" @click="onClick">
      <transition name="fast-transition">
        <img ref="image" class="collection-item-img" onerror="this.onerror=null; this.src='/img/placeholder_16_9.jpg'" src="/img/placeholder_16_9.jpg" />
      </transition>
      <span ref="amount" class="collection-item-amount"></span> 
    </a>
    <div class="collection-item-info-container">
      <div ref="name" class="collection-item-name"></div>
    </div>
  </div>
</template>

<script>

import { MODEL_FLAG } from "../const"

export default {
  data: function() {
    return {
      collection: null,
      onSelect: this.onSelectDefault
    }
  },
  mounted() {
    
  },
  updated() {

  },
  methods: {
    configure(collection, isPublicOnly) {
      let img = this.$refs.image
      let name = this.$refs.name
      let link = this.$refs.linkContainer
      let amount = this.$refs.amount

      let amountValue = 0
      for (let modelIndex in collection.models) {
        let model = collection.models[modelIndex]
        if (isPublicOnly && model.flags.includes(MODEL_FLAG.IS_PRIVATE)) {
          continue
        }

        amountValue++
      }
      amount.innerHTML = amountValue;

      if (collection.models.length > 0) {
        this.setupDefaultImagePreviewForModel(img, collection.models[0])
      }

      let locale = this.getCollectionLocale(collection)

      name.innerHTML = locale ? locale.name : "";
      this.collection = collection
    },
    onClick() {
      this.onSelect(this)
    },
    onSelectDefault(item) {
      window.open(this.getURLForCollectionEdit(this.collection.hash), "_self")
    }
  }
}
</script>

<style lang="scss">
.collection-item-info-container {
  min-height: 30px; 
}
.collection-item-container {
  position: relative;
}
.collection-item-link-container { 
  width: 100%;
  aspect-ratio: 16/9;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.16), 0 6px 0 -3px #f2f2f2, 0 6px 1px -3px rgba(0, 0, 0, 0.2), 0 12px 0 -6px #f2f2f2, 0 12px 2px -6px rgba(0, 0, 0, 0.2), 0 7px 18px 0 rgba(30, 30, 30, 0.15);
  overflow: hidden;
  border-radius: 12px;
}
.collection-item-img {
  background-color: #FAFAFA;
  aspect-ratio: 16/9;
  width: 100%;
  min-height: 130px;
  object-fit: cover;
}
.collection-item-name {
  margin-top: 5px;
  width: 100%;
  padding-right: 45px;
  padding-left: 5px;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Helvetica, sans serif;
}
.collection-item-amount {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  color: #ffffffed;
  line-height: 30px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
  -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .4));
}
</style>
