<template>
	<div class="configurator-item">
		<img class="configurator-item-icon unselectable" ref="itemTypeLabel" src="/img/scene_menu_configurator.svg" />
		<div class="configurator-item-container" 
			@click="onPress"
			@mouseover="onHover"
			@mouseout="onOut"
		>
			<div v-show="selected" class="configurator-item-selected-background"></div>
			<div ref="titleElement" v-html="title" class="configurator-item-title unselectable"></div>
			<div v-show="highlighted" class="configurator-item-overlay"></div>
		</div>
    </div>
</template>

<script>

import SceneConfigurator from './SceneConfigurator.vue'

export default {
	data: function() {
		return {
			title: "",
			selected: false,
			highlighted: false,
			configuratorEditor: null,
			index: 0,
			configuratorEntry: null
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		configure(configuratorEditor, configurator, index) {
			this.configuratorEntry = configurator
			this.configuratorEditor = configuratorEditor;
			this.index = index;

			this.title = configurator.name
		},
		highlight(highlight) {
			this.highlighted = highlight;
		},
		select(select) {
			this.selected = select;
			if (select) {
				this.$refs.titleElement.className = "configurator-item-title-selected"
			} else {
				this.$refs.titleElement.className = "configurator-item-title"
			}
		},
		onPress() {
			this.configuratorEditor.select(this.index, false/*from engine*/)
		},
		onHover() {
			this.highlighted = true;
			//this.configuratorEditor.highlight(this.index)
		},
		onOut() {
			this.highlighted = false;
			//this.configuratorEditor.highlight(-1)
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.configurator-item-selected-background {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #59b5bb;
}
.configurator-item-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.configurator-item-container {
	width: 100%;
	height: 30px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	text-align: left;
}
.configurator-item-container:hover {
	cursor: pointer;
}
.configurator-item-title {
	padding-left: 30px;
	color: black;
	line-height: 30px;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.configurator-item-title-selected {
	padding-left: 30px;
	color: white;
	line-height: 30px;
	font-weight: 500;
	position: relative;
}
.configurator-item {
	position: relative;
}
.configurator-item-icon {
	z-index: 1;
	position: absolute;
	left: 5px;
	border-radius: 3px;
	background-color: #59b5bb;
	color: white;
	font-weight: 900;
    font-size: 12px;
    line-height: 21px;
    width: 21px;
    height: 21px;
    top: 4px;
    pointer-events: none;
    text-align: center;
    padding: 2px;
}
</style>
