<template>
  <main>
    <div class="website-page">
      <div class="promo__bg"></div>
      <h1 class="visually-hidden">{{ $t("business.main-title-charter") }}</h1>
      <div class="in-container">
        <h2 class="business__title highlighted">
          {{ $t("business.title-charter") }}
        </h2>
      </div>
      <section class="business__section">
        <div class="business__upper">
          <div id="archengine-embed-container-business">
            <iframe
              ref="businessWebSceneFrame"
              title="ARCHITEQUE 3D"
              allowfullscreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              frameborder="0"
            ></iframe>
          </div>
          <div class="business__upper-features">
            <div class="business__title-wrapper">
              <h3 class="business__title-section">
                {{ $t("business.title-section") }}
              </h3>
            </div>

            <FeaturesListLight
              name-of-features="business"
              class="business__features"
            />
            <b-link
              id="application-btn-try"
              :to="{ name: 'Register' }"
              class="button_configurator_promo btn"
              >{{ $t("btn-try-for-free") }}</b-link
            >
          </div>
        </div>

        <!--div class="business__upper in-container">
          <div id="archengine-embed-container-business"> 
            <iframe ref="businessWebFrame" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" title="ARCHITEQUE 3D" frameborder="0"   style="width: 100%; height: 100%;"></iframe>
          </div>
          <div class="business__upper-features">
            <h3 class="business__title-section">
              {{ $t("business.title-section") }}
            </h3>
            <FeaturesList nameOfFeatures="business"></FeaturesList>
            <b-link :to="{name: 'Register'}" class="button_promo btn" id="application-btn-try">{{$t('btn-try-for-free')}}</b-link>
          </div>
        </div-->
        <div class="business__lower in-container">
          <!--div style="width: 100%; aspect-ratio: 1.78; margin: auto;">
              <iframe src="https://www.youtube.com/embed/un819iJ2DDo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="embed-video"></iframe>
          </div-->
          
        </div>
        <div class="business__descriptions-bg">
          <DesctiptionsList
            nameOfDescroptions="business"
            :listOfDescriptions="descriptions"
          ></DesctiptionsList>
        </div>
      </section>
      <div class="business__subtitle in-container">
        <h2 class="business__title highlighted">
          {{ $t("business.for-clients") }}
        </h2>
      </div>
    </div>
  </main>
</template>

<script>
import DesctiptionsList from "./DesctiptionsList.vue";
import FeaturesListLight from "./FeaturesListLight.vue";

export default {
  components: { FeaturesListLight, DesctiptionsList },
  data: function() {
    return {
      descriptions: ["instant-demo", "scale", "access", "upload", "interact"]
    };
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "business"
    ];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "business"
    ];
  },
  mounted() {
    // Update frame src
    var src = this.getArchwebURL(true) + "transparent=1&scene=14553c4dac19c551";
    src += "&uiStyle=light";
    src += "&allowScale=1";
    src += "&allowMoveCameraSideways=1";
    src += "&showCredit=0";
    src += "&allow-fullscreen=0";
    src += "&annotations-toggle=0";
    src += "&annotations-visible=1";
    src += "&show-logo=0";
    src += "&show-info=0";
    src += "&toggle-configurator=0";

    if (process.env.NODE_ENV === 'development') {
      src += "&api=" + process.env.VUE_APP_API_BASEURL;
      src += "&website-url" + process.env.VUE_APP_WEBSITE_URL;
      src += "&allowCDN=0";
    }

    this.$refs.businessWebSceneFrame.src = src
  }
};
</script>

<style lang="scss">
.business__title {
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 36px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;
}

#archengine-embed-container-business {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 3px;
  position: relative;

  @media (max-width: $mobile-width-only) {
    margin-top: 30px;
  }
}

#archengine-embed-container-business iframe {
  /*background-color: #ededed;
  box-shadow: inset 0px 0px 180px rgba(0, 0, 0, 0.3);*/
  width: 110%;
  height: 110%;

  position: absolute;
  bottom: 0px;

  @media (max-width: $mobile-width-only) {
    width: calc(100% - 10px);
    height: 500px;

    margin-top: -70px;
  }
}

.business__title-section {
  margin-bottom: 20px !important;

  @media (max-width: $mobile-width-only) {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }

  @extend .title-section;
}

.business__upper-features {
  @media (min-width: $tablet-width) {
    padding-top: 30px;
  }
}

.business__img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 50px;
}

.business__upper {
  display: grid;

  background-color: #59b5bb;
  border-radius: 20px;
  padding: 30px;
  max-width: 1280px;
  margin: auto;
  color: white;

  @media (min-width: $tablet-width) {
    grid-gap: 2%;
    grid-template-columns: 55% 43%;
  }
}

.business__lower {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.descriptions__item--instant-demo::before {
  background-image: url("./../assets/instant-demo.png");
}

.descriptions__item--scale::before {
  background-image: url("./../assets/scale.png");
}

.descriptions__item--access::before {
  background-image: url("./../assets/access.png");
}

.descriptions__item--upload::before {
  background-image: url("./../assets/upload.png");
}

.descriptions__item--interact::before {
  background-image: url("./../assets/interact.png");
}

.business__subtitle {
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;

  .business__title {
    display: inline;

    @extend .title-chapter;
  }
}
</style>
