<template>
	<div class="textures-editor">
		<TextureOutliner ref="outliner"></TextureOutliner>

		<div>
			<div id="texture-outliner-button-container">
				<button                              @click="onAddTexture()" id="texture-add-btn" class="editor-default-btn">Add</button>
				<button :disabled='!showTextureEdit' @click="onRemoveTexture()" id="texture-remove-btn" class="editor-destructive-btn">Remove</button>
			</div>
	
			<div v-html="selectedTextureName" class="texture-selected-header"></div>
		</div>

		<div ref="textureProperties" v-show="showTextureEdit" class="editor-texture-properties-container">
			<div ref="dataSize"></div>
			<div ref="dimensions"></div>
			<div ref="usageCount"></div>
			<div class="textures-preview-img-container">
				<img class="textures-preview-img" ref="texturePreviewImg"/>
			</div>
		</div>
		
		<div class="texture-edit-hint" v-show="!showTextureEdit">Select a texture to show info</div>
    </div>
</template>

<script>

import TextureOutliner from './TextureOutliner.vue'

import Vue from 'vue'
import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			showTextureEdit: false,
			userEditing: true,
			ignorePropertyChange: false,
			selectedTextureName: "",
			propertyEditConfig: [ 

			],
			propertyEditItemMap: {}
		}
	},
	components: {
		TextureOutliner
	}, 
	created() {

	},
	mounted() {
		let _this = this;
		this.$refs.outliner.onSelect = function (index) {
			_this.select(index)
		}
	},
	methods: {
		configure(textureList) {
			this.$refs.outliner.configure(textureList)
		},
		showPropertiesEditor() {
			this.ignorePropertyChange = true;

			var params = {}
			params["textureId"] = Number(this.$refs.outliner.selectedTextureObject.id)
			params["isThumbnail"] = 0;
			var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["getTextureData", JSON.stringify(params)]);
			response = eval("(" + response + ")")

			if (response.error && response.error.length > 0) {
				this.$editor.dismissErrorMessageCountDown = 5;
				this.$editor.errorMessage = response.error;
			} else {
				var previewImageElement = this.$refs.texturePreviewImg
				previewImageElement.src = "data:image/png;base64," + response["data"];
	
				this.$refs.dataSize.innerHTML = "<b>Size:</b> " + response["dataSize"];
				this.$refs.dimensions.innerHTML = "<b>Dimensions: </b>" + response["dimensions"];
				this.$refs.usageCount.innerHTML = "<b>Usage: </b>" + response["usageCount"];
			}
			var _this = this
			this.$nextTick(() => {
				_this.ignorePropertyChange = false
			});
		},
		select(index) {
			var _this = this;

			this.userEditing = false;

			this.showTextureEdit = index >= 0;

			if (this.showTextureEdit) {
				this.selectedTextureName = this.$refs.outliner.selectedTextureObject.name;
				this.showPropertiesEditor();
			} else {
				this.selectedTextureName = ""
			}
			this.$nextTick(() => {
				_this.userEditing = true;
			});
		},
		onAddTexture() {
			var input = this.$editor.$refs.fakeFileInput;
			input.type = 'file';
			input.accept = "image/x-png, image/jpeg";

			var _this = this;
			input.onchange = e => { 
				var file = e.target.files[0]; 
				let fileName = file.name;
				let filePath = "/" + fileName
				
				var reader = new FileReader();
				reader.readAsArrayBuffer(file);
				reader.onload = function(evt) {
					let data = new Uint8Array(evt.target.result);
					
					// Store the file
					let stream = _this.$editor.fs.open(filePath, 'w+');
					_this.$editor.fs.write(stream, data, 0, data.length, 0);
					_this.$editor.fs.close(stream);
					
					var response = _this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["addTexture", filePath]);
					response = eval("(" + response + ")");

					if (Object.hasOwn(response, "error") && response.error.length > 0) {
						_this.$editor.dismissErrorMessageCountDown = 5;
						_this.$editor.errorMessage = response["error"];
					} else {
						var textures = response["textureArray"]
						_this.configure(textures);

						_this.$refs.outliner.$el.scrollTo(0, _this.$refs.outliner.$el.scrollHeight);
	
						_this.$refs.outliner.select(_this.$refs.outliner.textureItemList.length - 1);
						_this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
					}
				}
				reader.onerror = function (evt) {
					_this.$editor.dismissErrorMessageCountDown = 5;
					_this.$editor.errorMessage = "Failed to read file";
				}
				reader.onprogress = function(data) {
					_this.$editor.setProgress(data.loaded / data.total);
				}
			}
			input.click();
		},
		onRemoveTexture() {
			var textures = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["removeTexture", this.$refs.outliner.selectedTextureObject.id.toString()]);
			textures = eval(textures);

			this.configure(textures);
			this.select(-1);
			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		updatePropertyItems() {
			this.$refs.outliner.updateItems()
		},
		onChangeProperty(value, key) {
			var _this = this;

			if (this.ignorePropertyChange) return;
			
			var texture = this.$refs.outliner.getSelectedTexture()
			texture[key] = value

			var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string', 'string'], ["editTexture", this.$refs.outliner.toString(), JSON.stringify(texture, null, 2)]);

			var responseObject = eval( '(' + response + ')');
			var textureObject = responseObject.texture;
			var errorObject = responseObject.error;
			
			this.$refs.outliner.textureList[this.$refs.outliner.selectedIndex] = textureObject;
			
			if (errorObject.length > 0) {
				this.$editor.showMessage(errorObject, true)
				this.$nextTick(() => {
					_this.updatePropertyItems()
				});
			}

			this.$refs.outliner.selectedTextureName = textureObject.name
			this.$refs.outliner.textureItemList[this.$refs.outliner.selectedIndex].title = textureObject.name

			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		}
	},
	watch: {

	}
}

</script>

<style lang="scss">
.textures-editor {
	display: flex;
  flex-direction: column;
  height: 100%;
}
.editor-texture-properties-container {
	padding: 5px 10px;
	width: 100%;
	overflow: auto;
	overflow-x: hidden;
	flex-grow: 1;
}
#texture-outliner-background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
#texture-outliner-button-container {
	width: 100%;
	height: 42px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
	grid-gap: 5px;
	justify-content: center;
	padding: 5px 5px;
	width: 100%;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.04);
}
.texture-editor {
	text-align: center;
}
.texture-edit-hint {
	text-align: center;
	color: #121212;
	position: absolute;
	top: 340px;
	width: 100%;
}
.texture-selected-header {
	width: 100%;
	height: 30px;
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #e1e1e1;
  padding-left: 10px;
  font-weight: 600;
  text-align: left;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.texture-outliner {
	width: 100%;
	height: 220px;
	overflow: auto;
	overflow-x: hidden;
	position: relative;
}
.textures-preview-img {
	width: auto;
	height: 200px;
	max-height: 200px;
}
.textures-preview-img-container {
	margin-top: 5px;
	text-align: center;
	background-color: rgb(220, 220, 220);
    border-radius: 3px;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}
</style>
