<template>
  <transition name="fast-transition">
    <div v-show="visible" :class="`fullscreen-popup ${classes}`">
      <div
        ref="background"
        class="fullscreen-popup-background"
        @click="onClickClose"
      ></div>

      <div class="fullscreen-popup-outer">
        <div class="fullscreen-popup-inner">
          <div v-if="title" class="fullscreen-popup-title">
            {{ title }}
          </div>
          <div v-show="title" class="fullscreen-popup-separator"></div>
          <div ref="content" class="fullscreen-popup-content">
            <component :is="item" ref="componentRef" v-bind="props"></component>
          </div>
          <div v-show="onApply" class="fullscreen-popup-separator"></div>
          <div v-if="onApply" class="fullscreen-popup-button-container">
            <button class="fullscreen-popup-button" @click="onClickApply">
              {{ $t("btn-apply") }}
            </button>
          </div>
        </div>

        <div class="fullscreen-popup-close-button" @click="onClickClose">
          <img src="../assets/close-popup.svg" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      title: "",
      visible: false,
      onApply: null,
      item: null,
      classes: "",
      props: null,
      showSeparators: true
    }
  },
  watch: {},
  created() {},
  updated() {},
  mounted() {
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel"
    function preventDefault(e) {
      e.preventDefault()
    }
    this.$refs.background.addEventListener(wheelEvent, preventDefault, true)
  },
  methods: {
    onClickApply() {
      if (this.onApply) {
        this.onApply(this.$refs.componentRef)
        this.onClickClose()
      }
    },
    onClickClose() {
      this.close()
      this.props = null
      this.onApply = null
      this.item = null
      this.title = ""
      this.classes = ""
    },
    close() {
      this.visible = false
      document.documentElement.removeAttribute("class")
    },
    configure({ item, title, applyCallback, classes, props, showSeparators }) {
      document.documentElement.setAttribute("class", "overflow--hidden")
      this.props = props

      if (showSeparators != undefined) {
        this.showSeparators = showSeparators
      }
      if (title) {
        this.title = title
      }
      this.item = item
      if (applyCallback) {
        this.onApply = applyCallback
      }
      if (classes) {
        this.classes = classes
      }
    },
  },
}
</script>

<style lang="scss">
.fullscreen-popup-background {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.fullscreen-popup {
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 10;

  @media (min-width: $tablet-width) {
    padding: 50px 100px;
  }
}

.fullscreen-popup-outer {
  position: relative;

  max-width: 1024px;
  margin: auto;
}
.fullscreen-popup-inner {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;

  height: 100%;
  overflow: auto;

  @media (min-width: $tablet-width) {
    margin: 0px 70px;
    border-radius: 15px;
  }
}
.fullscreen-popup-content {
  padding: 5px;
  min-height: calc(100% - 100px);
  height: 100%;

  .map__map {
    height: 100%;
  }

  @media (min-width: $tablet-width) {
    overflow: auto;
  }
}

.fullscreen-popup-close-button {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 35px;
  top: 20px;
  cursor: pointer;
  border-radius: 30px;
  padding: 10px;

  @media (min-width: $tablet-width) {
    right: 0px;
    top: 0px;
  }
}

.fullscreen-popup-close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.fullscreen-popup-title {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 500;
}

.fullscreen-popup-button-container {
  height: 50px;
  padding-top: 10px;
  display: flex;
  align-self: flex-end;
}

.fullscreen-popup-button {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #ffffff !important;
  background-color: #bc4444 !important;
  transition: 0.15s ease-in-out;
  padding: 10px 20px !important;
  border-radius: 40px !important;
  box-shadow:
    0 16px 24px rgba(188, 68, 68, 0.2),
    0 2px 6px rgba(188, 68, 68, 0.12),
    0 0 1px rgba(0, 0, 0, 0.04);
  border: none;
}
.fullscreen-popup-separator {
  background-color: #ebebeb;
  width: 100%;
  height: 1px;
}
</style>
