<template>
  <div class="collection-grid-wrapper">
    <div v-show="showGrid" ref="container" class="collection-grid-container">

    </div>

    <div v-show="showEmpty" ref="emptyContainer" class="collection-grid-container-empty">

    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import CollectionItem from './CollectionItem.vue'

export default {
  data: function () {
    return {
      emptyText: "No collections yet",
      onSelect: null,
      items: [],
      showGrid: true,
      showEmpty: false
    }
  },
  mounted() {

  },
  updated() {

  },
  methods: {
    setOnSelect(func) {
      this.onSelect = func

      for (let itemIndex in this.items) {
        let item = this.items[itemIndex]
        item.onSelect = func
      }
    },
    createCollectionItem(collection, isPublicOnly) {
      var ComponentClass = Vue.extend(CollectionItem)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.configure(collection, isPublicOnly)

      return item
    },
    configure(collectionArray, isPublicOnly) {
      this.items = []
      let container = this.$refs.container;

      container.innerHTML = ""

      var collectionsAdded = 0;

      if (collectionArray != null && collectionArray.length > 0) {
        for (var index in collectionArray) {
          var collection = collectionArray[index]

          if (isPublicOnly && !collection.public) {
            continue;
          }

          var locale = this.getModelLocale(collection)
          if (locale == null && collection.systemName) {
            if (collection.systemName == "SCANNED") {
              locale = { name: "Scanned" }
            } else if (collection.systemName == "FAVOURITE") {
              locale = { name: "Favorites" }
            } else {
   
            }
          }
          var item = this.createCollectionItem(collection, isPublicOnly);
          if (this.onSelect) {
            item.onSelect = this.onSelect
          }
          container.appendChild(item.$el);
          this.items.push(item)

          ++collectionsAdded;
        }
      }

      this.showGrid = collectionsAdded > 0
      this.showEmpty = collectionsAdded == 0
      if (collectionsAdded == 0) {
        this.$refs.emptyContainer.innerHTML = (this.emptyText && this.emptyText.length > 0) ? this.emptyText : "No collections yet";
      }
    }
  }
}
</script>

<style lang="scss">
.collection-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
  justify-content: center;
}

.collection-grid-container-empty {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
