<template>
  <transition name="fast-transition">
    <div id="embed-scene-container" v-show="show">
      <div @click="onclickEmbedWindowContainer()" id="embed-scene-overlay"></div>
      <div id="embed-scene-window">
        <div id="embed-scene-header">
          Embed 3D scene to a website
        </div>

        <div class="embed-scene-frame-container"> 
          <iframe ref="embedSceneIframe" title="ARCHITEQUE 3D" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="0" style="margin: 0px; height: 100%;"></iframe>
        </div>

        <div id="embed-code-explanation">
          Insert the code below to your website:
        </div>

        <textarea readonly ref="embedSceneCode" class="embed-scene-code-label">

        </textarea>


        <div id="embed-buttons-container">
          <button @click="onCopy()" class="default-nice-button" id="embed-copy-button">
            <span ref="copy">Copy HTML code</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  data: function() {
    return {
      sceneHash: "",
      show: false,
      copyButtonText: "Copy",
      title: ""
    }
  },
  created() {

  },
  mounted() {
    this.$refs.copy.innerHTML = this.copyButtonText;
  },
  methods: {
    showWindow(hash, title) {
      this.sceneHash = hash;
      this.show = true;
      this.title = title;

      this.updateFrameSrc();
      this.updateCode();
    },
    getCodeSrc() {
      let src = this.getArchwebURL(false) + "allowScale=1&uiStyle=dark&allowMoveCameraSideways=1&allow-fullscreen=1&scene=" + this.sceneHash;
      if (process.env.NODE_ENV === 'development') {
        src += "&api=" + encodeURIComponent(process.env.VUE_APP_API_BASEURL);
        src += "&website-url=" + encodeURIComponent(process.env.VUE_APP_WEBSITE_URL);
        src += "&allowCDN=0"
      }
      return src;
    },
    getCode() {
      var code = `<div><iframe width="640" height="360" src='__SRC__' title='__TITLE__' allowfullscreen='allowfullscreen' mozallowfullscreen='true' webkitallowfullscreen='true' frameborder='0'></iframe></div>`;

      code = code.replace("__SRC__", this.getCodeSrc());
      code = code.replace("__TITLE__", this.title);
      return code;
    },
    updateCode() {
      this.$refs.embedSceneCode.textContent = this.getCode();
    },
    updateFrameSrc() {
      this.$refs.embedSceneIframe.src = this.getCodeSrc();
      this.$refs.embedSceneIframe.title = this.title;
    },
    onclickEmbedWindowContainer() {
      this.close();
    },
    close() {
      this.show = false;
    },
    onCopy() {
      this.copyTextToClipboard(this.getCode());

      this.$refs.copy.innerHTML = "Copied!";

      let _this = this;
      setTimeout(function() {
        _this.$refs.copy.innerHTML = _this.copyButtonText;
      }, 400);
    }
  }
}
</script>

<style lang="scss">
#embed-scene-header {
  font-size: 22px;
  margin-bottom: 15px;
}
.embed-scene-code-label {
  font-family: monospace, monospace !important;
  padding: 5px;
  max-height: 140px;
  min-height: 140px;
  overflow-y: scroll;
  white-space: pre-wrap;
  color: #5e5e5e;
  font-size: 12px !important;
  resize: none !important;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  margin: 0 30px;
}
#embed-scene-container {
  width: 100%;
  height: 100%;
  z-index: 10;
}
#embed-scene-overlay {
  width: 100%;
  height: 100%;
  background-color: #00000085;
  position: fixed;
  top: 0px;
}

#embed-scene-window {
  width: 780px;
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background-color: #e9e9e9;
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
  text-align: center;
}
.embed-scene-frame-container {
  margin: 5px 0px;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 5px;
}
#embed-copy-button {
  border-radius: 5px !important;
  margin-left: 10px !important;
}
#embed-code-explanation {
  text-align: left;
  padding-left: 5px;
  margin-top: 10px;
}
#embed-buttons-container {
  position: relative;
  height: 35px;
  width: 100%;
  margin: 0 0 0 0;
  text-align: right;
  margin-top: 10px;
}
</style>