export const EARTH_RADIUS = 6378137.0

export const BOTTOM_LIMIT = 3

export const UPPER_LIMIT = 5

export const MARKER_SVG_ICON = `<svg class="map__icon-cluster" fill="red" xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 80 80">
  <circle cx="40" cy="40" opacity="1" r="40" />
</svg>`

export const MAP_KEY = "AIzaSyBm0UbBr-k6ITYAGzpnEybrYHXu8ToWmBE"

export const MARKER_IMG_NAME = "photo_small.jpeg"

export const DEFAULT_COORDINATES = { lat: 48.86, lng: 2.3 } // Paris

export const DEFAULT_ZOOM = 13

export const NO_MODELS_MESSAGE = {
  NO_IN_THIS_REGION: "map.no-models-in-region",
  TYPE_MORE: "map.type-more",
  NOTHING_FOUND: "map.nothing-found",
}
