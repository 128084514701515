import { BOTTOM_LIMIT, EARTH_RADIUS, UPPER_LIMIT } from "../const"

/**
 * Get new coordinates so that markers with the same coordinates do not overlap.
 */
const getNewCoordinates = (latitude, longitude) => {
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
  }

  const distanceNorth = getRandomArbitrary(BOTTOM_LIMIT, UPPER_LIMIT)
  const distanceEast = getRandomArbitrary(BOTTOM_LIMIT, UPPER_LIMIT)
  const dLat = distanceNorth / EARTH_RADIUS
  const dLon =
    distanceEast / (EARTH_RADIUS * Math.cos((Math.PI * latitude) / 180))

  //New coordinates
  const newLat = latitude + (dLat * 180) / Math.PI
  const newLng = longitude + (dLon * 180) / Math.PI
  return { lat: newLat, lng: newLng }
}

export default getNewCoordinates
