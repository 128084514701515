<template>
  <b-container class="container not-found mw-500">
    <div class="promo__bg"></div>
    <div id="status-container">
      <img v-if="success" id="status-img" src="../assets/success-big.svg" />
      <img v-if="!success" id="status-img" src="../assets/not-found.svg" />
      <h2 id="status-text" class="not-found__text"></h2>
    </div>
    <b-button
      class="text-white login-button"
      block
      variant="primary mt-3"
      @click="$router.push({ name: 'Login' })"
      >{{ $t("login.login-btn") }}</b-button>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      success: false
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["email-confirm-status"];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["email-confirm-status"];
  },
  mounted() {
    var statusTextElement = document.getElementById('status-text');
    var error = this.$route.query.error
    if (error && error.length > 0) {
      this.success = false
      statusTextElement.innerHTML = error
    } else {
      this.success = true
      statusTextElement.innerHTML = this.$root.$i18n.messages[this.$root.$i18n.locale]["email-confirm-status"]["success"];
    }
  }
}
</script>

<style lang="scss">

#status-container {
  margin-top: 50px !important;
  margin-bottom: 30px !important;
  text-align: center;
  margin: auto;
}
#status-img {
  width: 100px;
  margin-bottom: 40px;
}

</style>