<template>
	<div class="editor-property-container">
		<div v-show="headerText.length > 0" ref="header" class="editor-property-header">{{headerText}}</div>
		<b-form-input :disabled="isDisabled" v-show="!multiline" class="editor-single-line-input" v-model="value" maxlength="maxLength"></b-form-input>
		<b-form-textarea :disabled="isDisabled" v-show="multiline" class="editor-multiline-input" v-model="value" maxlength="maxLength" :rows="rowsAmount" :max-rows="rowsAmount"></b-form-textarea>
    </div>
</template>

<script>


export default {
	props: {
	},
	data: function() {
		return {
			value: "",
			headerText: { type: String, default: "" },
			propKey: { type: String, default: "" },
			onChange: null,
			maxLength: 64,
			rowsAmount: 1,
			multiline: false,
			isDisabled: false
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		
	},
	methods: {

	},
	watch: {
		value (val, oldVal) {
			if (val === oldVal) return;

			if (this.onChange) this.onChange(val, this.propKey);
		}
	}
}

</script>

<style lang="scss">


</style>
