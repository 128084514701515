<template>
  <div class="store-bundle-container">
    <div class="store-bundle-header">{{ title }}</div>
    <div
      v-show="showValidUntil"
      ref="validUntilLabel"
      class="store-bundle-valid-until-label"
    ></div>
    <div class="store-bundle-separator"></div>

    <div
      v-show="!modelsConfigurable && showModelsAmount"
      class="store-bundle-models-amount-container"
    >
      <div
        ref="modelsAmountTitle"
        class="store-bundle-models-amount-label"
      ></div>
      <b-form-input
        ref="amountInput"
        v-model="modelAmount"
        class="store-bundle-model-amount-input"
        maxlength="4"
        @keypress="validateAmount($event)"
      ></b-form-input>
    </div>

    <div
      v-show="modelsConfigurable"
      class="store-bundle-model-configurator-button"
      @click="onShowScenePicker"
    >
      <transition name="fast-transition">
        <div
          ref="modelFillProgress"
          class="store-bundle-model-configurator-fill-progress"
        ></div>
      </transition>
      <div class="store-bundle-model-configurator-title">
        <div ref="modelConfiguratorTitle"></div>
        <div class="store-bundle-model-configurator-title-hint">
          <b>{{ clickToEditText }}</b>
        </div>
      </div>
    </div>

    <transition name="fast-transition">
      <div
        v-show="showOptions"
        ref="optionsContainer"
        class="store-bundle-options-container"
      >
        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox
            v-show="showPublicModels"
            ref="checkbox00"
            v-model="enablePublicModels"
            disabled="true"
            >{{ checkbox0Title }}</b-form-checkbox
          >
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox
            v-show="showPrivateModels"
            ref="checkbox0"
            v-model="enablePrivateModels"
            disabled="true"
            >{{ checkbox00Title }}</b-form-checkbox
          >
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox ref="checkbox1" v-model="enableConfigurator">{{
            checkbox1Title
          }}</b-form-checkbox>
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox ref="checkbox2" v-model="enableEmbedding">{{
            checkbox2Title
          }}</b-form-checkbox>
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox
            ref="checkbox3"
            v-model="enableAnnotations"
            disabled="true"
            >{{ checkbox3Title }}</b-form-checkbox
          >
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox
            ref="checkbox4"
            v-model="enableAnnotations"
            disabled="true"
            >{{ checkbox4Title }}</b-form-checkbox
          >
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox
            ref="checkbox5"
            v-model="enableAnnotations"
            disabled="true"
            >{{ checkbox5Title }}</b-form-checkbox
          >
        </div>

        <div class="store-bundle-configurator-checkbox-container">
          <b-form-checkbox
            ref="checkbox6"
            v-model="enableAnnotations"
            disabled="true"
            >{{ checkbox6Title }}</b-form-checkbox
          >
        </div>
      </div>
    </transition>

    <button
      v-show="collapsable"
      class="store-bundle-show-options-button"
      @click="onClickShowOptions"
    >
      <span ref="showOptionsLabel" class="store-bundle-show-options-label">{{
        showOptionsTitle
      }}</span>
      <transition name="fast-transition">
        <img
          ref="showOptionsDropdown"
          class="store-bundle-show-options-dropdown"
          src="~@/assets/dropdown.svg"
        />
      </transition>
    </button>

    <div class="store-bundle-separator store-bundle-last-separator"></div>

    <div
      v-show="showAutoRenewal"
      id="autorenewal-checkbox"
      class="store-bundle-configurator-checkbox-container store-bundle-auto-renewal-checkbox"
    >
      <b-form-checkbox ref="checkboxAutorenewal" v-model="enableAutoRenewal">{{
        checkbox7Title
      }}</b-form-checkbox>
    </div>

    <div
      v-show="showAutoRenewal"
      class="store-bundle-separator store-bundle-last-separator"
    ></div>

    <div class="store-bundle-cost-container">
      <b-spinner
        v-if="priceCalculating"
        class="store-bundle-cost-spinner"
        variant="dark"
      ></b-spinner>
      <div
        v-show="!priceCalculating"
        ref="priceLabel"
        class="store-bundle-cost-container-amount"
      >
        50
      </div>
      <span v-show="showCurrency" class="store-bundle-cost-container-currency"
        >$</span
      >
      <div ref="costSlash" class="store-bundle-cost-container-slash">/</div>
      <NiceSelector
        v-show="showPeriodSelector"
        ref="periodSelector"
      ></NiceSelector>
      <!--div ref="staticPeriod" class="store-bundle-static-period"></div-->
    </div>

    <RoundButton
      v-show="showActionButton"
      ref="actionButton"
      class="store-bundle-action-button"
    ></RoundButton>
    <RoundButton v-show="showCancelButton" ref="cancelButton"></RoundButton>

    <b-tooltip
      v-if="showAutorenewalUnavailableHint"
      triggers="hover"
      target="autorenewal-checkbox"
      placement="top"
    >
      {{ unavailableAutorenewalText }}
    </b-tooltip>
  </div>
</template>

<script>
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import moment from "moment"
import NiceSelector from "./NiceSelector.vue"
import RoundButton from "./RoundButton.vue"
import SceneGrid from "./SceneGrid.vue"

dayjs.extend(relativeTime)

export default {
  components: {
    NiceSelector,
    RoundButton,
  },
  data: function () {
    return {
      showModelsAmount: true,
      enablePublicModels: true,
      showPublicModels: false,
      enablePrivateModels: true,
      showPrivateModels: false,
      showValidUntil: true,
      enableAnnotations: true,
      enableConfigurator: true,
      enableEmbedding: true,
      modelAmount: 10,
      enableAutoRenewal: true,
      period: "month",
      priceCalculating: false,
      disabledDefaultOptions: true,
      title: "",
      purchaseTitle: "",
      needToRecalculatePrice: true,
      showOptions: true,
      collapsed: false,
      collapsable: false,
      editableOptions: true,
      showPeriodSelector: true,
      // alias function for $t because here it's undefined somehow
      loc: null,
      showAutoRenewal: true,
      showActionButton: true,
      showCancelButton: false,
      showCurrency: true,
      modelsConfigurable: false,
      subscription: null,
      price: 0,
      showAutorenewalUnavailableHint: false,
      checkbox0Title: "",
      checkbox00Title: "",
      checkbox1Title: "",
      checkbox2Title: "",
      checkbox3Title: "",
      checkbox4Title: "",
      checkbox5Title: "",
      checkbox6Title: "",
      checkbox7Title: "",
      unavailableAutorenewalText: "",
      clickToEditText: "",
      showOptionsTitle: "",
    }
  },
  watch: {
    enableConfigurator(newValue) {
      this.recalculatePrice()
    },
    enableEmbedding(newValue) {
      this.recalculatePrice()
    },
    modelAmount(newValue, oldValue) {
      newValue = Number(newValue)

      if (newValue > 1000) this.modelAmount = 1000

      this.recalculatePrice()
    },
    editableOptions(newValue) {
      const checkboxArray = this.getEditableCheckboxes()
      for (let i in checkboxArray) {
        let checkbox = checkboxArray[i]
        checkbox.disabled = !newValue
      }
    },
    purchaseTitle(newValue) {
      this.$refs.actionButton.title = newValue
    },
  },
  created() {},
  updated() {},
  mounted() {
    let periodConfig = []
    if (process.env.NODE_ENV === "development") {
      periodConfig.push({
        value: "day",
        additionalTitle: "",
        additionalTitleColor: "",
      })
    }
    periodConfig.push({
      value: "month",
      additionalTitle: "",
      additionalTitleColor: "",
    })
    periodConfig.push({
      value: "halfyear",
      additionalTitle: "15% off!",
      additionalTitleColor: "#59b5bb",
    })
    periodConfig.push({
      value: "year",
      additionalTitle: "30% off!",
      additionalTitleColor: "#59b5bb",
    })

    for (let i in periodConfig) {
      let configEntry = periodConfig[i]
      let locEntry = "period." + configEntry.value
      configEntry.title = this.loc(locEntry)
    }
    this.$refs.periodSelector.configure(periodConfig, null)
    this.$refs.periodSelector.setSelected(this.period)
    this.$refs.periodSelector.changeCallback = this.onChangePeriod

    this.$refs.actionButton.clickCallback = this.onclickBuy

    this.$refs.cancelButton.title = this.loc("store.bundle.cancel")
    this.$refs.cancelButton.setSecondary(true)
    this.$refs.cancelButton.backgroundColor = "#d8a432"
    this.$refs.cancelButton.clickCallback = this.onclickCancel

    this.$refs.modelsAmountTitle.innerHTML = this.loc(
      "store.bundle.models-amount",
    )

    this.checkbox0Title = this.loc("store.bundle.public-models")
    this.checkbox00Title = this.loc("store.bundle.private-models")
    this.checkbox1Title = this.loc("store.bundle.configurator")
    this.checkbox2Title = this.loc("store.bundle.website-embed")
    this.checkbox3Title = this.loc("store.bundle.annotations")
    this.checkbox4Title = this.loc("store.bundle.animation")
    this.checkbox5Title = this.loc("store.bundle.links")
    this.checkbox6Title = this.loc("store.bundle.publish")
    this.checkbox7Title = this.loc("store.bundle.autoRenewal")

    this.unavailableAutorenewalText = this.loc("store.unavailableAutorenewal")
    this.clickToEditText = this.loc("store.bundle.click-to-edit")
    this.showOptionsTitle = this.loc("store.bundle.show-options")
  },
  methods: {
    setEnableAutorenewal(enable) {
      this.$refs.checkboxAutorenewal.disabled = !enable
      if (!enable) {
        this.enableAutoRenewal = false
      }
      this.showAutorenewalUnavailableHint = !enable
    },
    getPickModelsTitle(selectedAmount, maxAmount) {
      const title =
        this.loc("store.bundle.pick-models") +
        " (" +
        selectedAmount +
        "/" +
        maxAmount +
        ")"
      return title
    },
    showModelPicker(sceneArray, selectedSceneArray, maxSelectedModels) {
      let title = this.getPickModelsTitle(
        selectedSceneArray.length,
        maxSelectedModels,
      )
      const _this = this
      this.$root.showFullscreenPopup({
        item: SceneGrid,
        title,
        applyCallback: _this.onClickModelPickApply,
        props: {
          selectable: true,
          showPublicLabel: true,
          sceneArray,
          isPublicOnly: false,
          selectedSceneArray,
          maxSelectedModels,
          onSceneSelected: function (grid, item, isSelected) {
            const title = _this.getPickModelsTitle(
              grid.selectedItems ? grid.selectedItems.length : 0,
              maxSelectedModels,
            )
            _this.$root.setFullscreenPopupTitle(title)
          },
        },
      })
    },
    onClickModelPickApply(sceneGrid) {
      let hashes = sceneGrid.getSelectedSceneHashArray()
      let _this = this
      let headers = this.getCommonHeaders()
      let params = {
        id: this.subscription.id,
        hashes,
      }
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL +
            "/api/v1/billing/subscription/updateScenes",
          params,
          { headers: headers },
        )
        .then(function (response) {
          _this.$root.closeFullscreenPopup()
          _this.configure(response.data)
        })
        .catch(function (error) {
          _this.dismissErrorMessageCountDown = 5
          _this.errorMessage = error.response
            ? error.response.data.cause || error.message
            : "Unknown error"
          console.log("error", error)
        })
    },
    onShowScenePicker() {
      let _this = this
      let headers = this.getCommonHeaders()

      this.axios
        .get(process.env.VUE_APP_API_BASEURL + "/api/v1/models/my", {
          params: {},
          headers: headers,
        })
        .then(function (response) {
          let sceneArray = response.data.value || response.data
          _this.showModelPicker(
            sceneArray,
            _this.subscription.models,
            _this.subscription.product.modelsAmount,
          )
        })
        .catch(function (error) {
          _this.dismissErrorMessageCountDown = 5
          _this.errorMessage = error.response
            ? error.response.data.cause || error.message
            : "Unknown error"
          console.log("error", error)
        })
    },
    getEditableCheckboxes() {
      return [
        this.$refs.checkbox1,
        this.$refs.checkbox2,
        // this.$refs.checkbox3,
        // this.$refs.checkbox4,
        // this.$refs.checkbox5,
        // this.$refs.checkbox6,
        this.$refs.checkboxAutorenewal,
      ]
    },
    onClickShowOptions() {
      if (this.collapsed) {
        this.expand()
      } else {
        this.collapse()
      }
    },
    collapse() {
      this.showOptions = false
      this.collapsed = true
      this.$refs.showOptionsLabel.innerHTML = this.loc(
        "store.bundle.show-options",
      )
      this.$refs.showOptionsDropdown.style["transform"] = "translate(0, -50%)"
    },
    expand() {
      this.showOptions = true
      this.collapsed = false
      this.$refs.showOptionsLabel.innerHTML = this.loc(
        "store.bundle.hide-options",
      )
      this.$refs.showOptionsDropdown.style["transform"] =
        "rotate(180deg) translate(0, 50%)"
    },
    configureAsProduct(product) {
      const locale = this.getLocale(product.locales)
      this.title = locale ? locale.name : this.loc("store.bundle.custom-bundle")
      if (product.price == 0) {
        this.setPriceString(this.loc("store.bundle.free"))
        this.showCurrency = false
      } else {
        this.setPrice(product.price * 100)
      }
      this.modelAmount = product.modelsAmount

      let periodLocKey = "period." + product.periodString
      if (product.periodString == "unlimited") {
        this.showAutoRenewal = false
        this.showActionButton = false
        this.modelsConfigurable = true
        this.showValidUntil = false
      }
      this.$refs.costSlash.hidden = true
      this.enableConfigurator = product.enableConfigurator
      this.enableEmbedding = product.enableEmbedding

      this.showPeriodSelector = false
      this.needToRecalculatePrice = false
      this.$refs.amountInput.disabled = true
      this.expand()
    },
    configure(sub) {
      this.configureAsProduct(sub.product)

      if (sub.active) {
        let expireDate = new Date(sub.endTime)

        if (sub.autoRenewal) {
          if (sub.status == "completed") {
            this.enableAutoRenewal = true
            this.$refs.validUntilLabel.innerHTML =
              "Next payment " +
              dayjs(expireDate).fromNow() +
              "<br>" +
              moment(String(expireDate)).format("DD.MM.YYYY HH:mm")
            this.showCancelButton = true
          } else if (sub.status == "canceled") {
            this.showCancelButton = false
            this.$refs.validUntilLabel.innerHTML =
              "<b>Canceled</b>. Expires " +
              dayjs(expireDate).fromNow() +
              "<br>" +
              moment(String(expireDate)).format("DD.MM.YYYY HH:mm")
            this.enableAutoRenewal = false
          }
        } else {
          this.$refs.validUntilLabel.innerHTML =
            "Expires " +
            dayjs(expireDate).fromNow() +
            "<br>" +
            moment(String(expireDate)).format("DD.MM.YYYY HH:mm")
          this.enableAutoRenewal = false
          this.showCancelButton = false
        }

        this.modelsConfigurable = true
        this.showActionButton = false
      } else {
        let expireDate = new Date(sub.endTime)
        this.$refs.validUntilLabel.innerHTML =
          "Expired " +
          dayjs(expireDate).fromNow() +
          " (" +
          moment(String(expireDate)).format("DD.MM.YYYY HH:mm") +
          ")"
        this.$refs.validUntilLabel.style["color"] = "#a12b15"
        this.showActionButton = false
        this.modelsConfigurable = false
        this.enableAutoRenewal = sub.autoRenewal
      }

      let currentModelsAmount = sub.models.length
      let maxModelAmount = sub.product.modelsAmount

      this.$refs.modelConfiguratorTitle.innerHTML =
        this.loc("store.bundle.modelsAssigned") +
        " (" +
        currentModelsAmount +
        "/" +
        maxModelAmount +
        ")"
      //this.setModelsFillProgress(currentModelsAmount / maxModelAmount)
      this.subscription = sub
    },
    setModelsFillProgress(progress) {
      if (progress < 0) progress = 0
      if (progress > 1) progress = 1
      this.$refs.modelFillProgress.style["width"] = progress * 100 + "%"
    },
    cancel() {
      this.$refs.cancelButton.showSpinner = true

      let _this = this
      let params = {
        id: this.subscription.id,
      }
      let headers = this.getCommonHeaders()
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL +
            "/api/v1/billing/subscription/cancel",
          params,
          { headers: headers },
        )
        .then(function (response) {
          _this.$refs.cancelButton.showSpinner = false
          _this.configure(response.data)
        })
        .catch(function (error) {
          console.error("Error: " + error)
          _this.$refs.cancelButton.showSpinner = false
        })
    },
    onclickCancel() {
      let _this = this
      this.$root.$emit(
        "showDialog",
        "Warning",
        this.loc("store.bundle.cancel-description"),
        this.loc("store.bundle.close"),
        this.loc("store.bundle.cancel-subscription"),
        function () {
          _this.cancel()
        },
      )
    },
    purchaseStripe() {
      this.$refs.actionButton.showSpinner = true

      let _this = this
      let params = {
        modelsAmount: this.modelAmount,
        includeConfigurator: this.enableConfigurator,
        includeEmbedding: this.enableEmbedding,
        period: this.period,
        autoRenewal: this.enableAutoRenewal,
        successURL: location.origin + "/account/checkout/status",
        cancelURL: location.origin + "/account/store",
        type: "stripe",
      }
      let headers = this.getCommonHeaders()
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL +
            "/api/v1/billing/subscription/checkoutCustom",
          params,
          { headers: headers },
        )
        .then(function (response) {
          let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK_KEY)
          stripe.redirectToCheckout({ sessionId: response.data.sessionID })
        })
        .catch(function (error) {
          // _this.alert.state = true;
          // _this.alert.variant = "danger";
          //_this.alert.message = error.response && error.response.data && error.response.data.cause;
          console.error("Error: " + error)
          _this.$refs.actionButton.showSpinner = false
        })
    },
    loadScriptByURL(id, url, callback) {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    },
    checkoutUnitpay(params, publicKey) {
      let url = "https://unitpay.ru/pay/442010-3bf55?"
      for (let key in params) {
        let value = params[key]
        url += "&" + key + "=" + value
      }

      window.open(url, "_self")
    },
    purchaseUnitPay() {
      this.$refs.actionButton.showSpinner = true

      let _this = this
      let params = {
        modelsAmount: this.modelAmount,
        includeConfigurator: this.enableConfigurator,
        includeEmbedding: this.enableEmbedding,
        period: this.period,
        autoRenewal: this.enableAutoRenewal,
        successURL: location.origin + "/account/checkout/status",
        cancelURL: location.origin + "/account/store",
        type: "unitpay",
      }
      let headers = this.getCommonHeaders()
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL +
            "/api/v1/billing/subscription/checkoutCustom",
          params,
          { headers: headers },
        )
        .then(function (response) {
          let params = response.data.params
          _this.checkoutUnitpay(params)
        })
        .catch(function (error) {
          // _this.alert.state = true;
          // _this.alert.variant = "danger";
          //_this.alert.message = error.response && error.response.data && error.response.data.cause;
          console.error("Error: " + error)
          _this.$refs.actionButton.showSpinner = false
        })
    },
    onclickBuy() {
      if (this.$root.country && this.$root.country == "ru") {
        this.purchaseUnitPay()
      } else {
        this.purchaseStripe()
      }
    },
    setPeriodString(period) {
      this.period = period
      this.recalculatePrice()
    },
    onChangePeriod(val) {
      this.setPeriodString(val)
    },
    validateAmount: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getMonthsAmount(period) {
      if (period == "month") {
        return 1
      } else if (period == "halfyear") {
        return 6
      } else if (period == "year") {
        return 12
      }

      return 0
    },
    getDaysAmount(period) {
      if (period == "day") {
        return 1
      }

      return 0
    },
    getOneModelPrice() {
      let oneModelPrice = 80 // cents
      if (this.enableConfigurator) {
        oneModelPrice += 20
      }
      if (this.enableEmbedding) {
        oneModelPrice += 30
      }

      return oneModelPrice
    },
    setPriceString(price) {
      this.$refs.priceLabel.innerHTML = price
    },
    setPrice(priceCents) {
      let priceDollars = priceCents / 100
      if (priceDollars % 1 != 0) {
        priceDollars = priceDollars.toFixed(1)
      }
      if (this.$refs.priceLabel) {
        this.setPriceString(priceDollars)
      }

      this.priceCalculating = false
      this.price = priceCents
    },
    recalculatePrice() {
      if (!this.needToRecalculatePrice) return

      let params = {
        enableConfigurator: this.enableConfigurator,
        enableEmbedding: this.enableEmbedding,
        modelsAmount: this.modelAmount,
      }

      params.months = this.getMonthsAmount(this.period)
      params.days = this.getDaysAmount(this.period)

      this.priceCalculating = true
      let _this = this
      this.axios
        .get(
          process.env.VUE_APP_API_BASEURL +
            "/api/v1/billing/subscription/price",
          { params: params, headers: null },
        )
        .then(function (response) {
          let priceCents = Number(response.data.price)
          _this.setPrice(priceCents)
        })
        .catch(function (error) {
          _this.priceCalculating = false
          _this.$refs.priceLabel.innerHTML = "?"
        })
    },
  },
}
</script>

<style lang="scss">
.store-bundle-container {
  display: flex;
  flex-direction: column;
  padding: 22px 31px 22px 31px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow:
    0 16px 24px rgba(0, 0, 0, 0.06),
    0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  min-width: 300px;
  max-width: 300px;
  position: relative;
  z-index: 0;
}

.store-bundle-header {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}

.store-bundle-models-amount-container {
  border-radius: 10px;
  position: relative;
}

.store-bundle-models-amount-label {
  font-weight: 500;
  line-height: 40px;
}

.store-bundle-purchase-button {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: $white !important;

  background-color: rgb(188, 68, 68) !important;
  transition: 0.15s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 10px 0px !important;
  border-radius: 40px !important;

  box-shadow:
    0 16px 24px rgba(188, 68, 68, 0.2),
    0 2px 6px rgba(188, 68, 68, 0.12),
    0 0 1px rgba(0, 0, 0, 0.04);

  @extend .font-link-btn-1;

  margin-top: 10px !important;

  @media (min-width: $tablet-width) {
  }

  @media (max-width: $mobile-width-only) {
    width: 100%;
  }
}

.store-bundle-purchase-button:hover,
.store-bundle-purchase-button:focus {
  background-color: #bc5555 !important;
}

.store-bundle-purchase-button:active {
  background-color: #bc5555 !important;
}

.store-bundle-purchase-button {
}

.store-bundle-separator {
  height: 10px;
  height: 1px;
  background-color: #00000014;
  margin-bottom: 15px;
}

.store-bundle-cost-container-amount {
  font-size: 26px;
  font-weight: 600;
  align-self: end;
  line-height: 55px;
}

.store-bundle-cost-container-currency {
  font-size: 26px;
  font-weight: 500;
  align-self: start;
  line-height: 55px;
  margin-left: 2px;
}

.store-bundle-cost-spinner {
  align-self: center;
}

.store-bundle-cost-container-slash {
  font-size: 30px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
  align-self: end;
  line-height: 55px;
}

.store-bundle-cost-container {
  display: flex;
  justify-content: right;
  margin-top: auto;
}

.store-bundle-model-amount-input {
  border: 1px solid #e2e2e2 !important;
  width: 80px !important;
  text-align: center;
  position: absolute !important;
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
  height: 32px !important;
  font-size: 18px !important;
  border-radius: 11px !important;
  position: relative;
  font-weight: 600 !important;
  background-color: #fafafa !important;
}

.store-bundle-model-amount-input:disabled {
  color: #6d6d6d !important;
}

.store-bundle-configurator-checkbox-container {
  margin-top: 10px;
}

.store-bundle-last-separator {
  margin-top: 15px;
}

.store-bundle-auto-renewal-checkbox {
  margin: 0px;
}

.store-bundle-show-options-button {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px !important;
  overflow: hidden;
  margin-top: 10px !important;
  padding: 5px 0px;
  border: 1px solid #e5e5e5 !important;
  position: relative;
}

.store-bundle-show-options-button:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.store-bundle-show-options-label {
  font-weight: 500;
}

.store-bundle-static-period {
  align-self: end;
  line-height: 45px;
  font-weight: 500;
  font-size: 14px;
}

.store-bundle-show-options-dropdown {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 5;
  top: 50%;
  transform: translate(0, -50%);
}

.store-bundle-valid-until-label {
  font-weight: 500;
  color: #15a139;
  font-size: 14px;
}

.store-bundle-model-configurator-button {
  border: 1px solid #dedede;
  padding: 10px;
  border-radius: 7px;
  background-color: #e9e9e9;
  cursor: pointer;
  position: relative;
}

.store-bundle-model-configurator-button:hover {
  background-color: #f2f2f2;
}

.store-bundle-model-configurator-title {
  pointer-events: none;
  position: relative;
}

.store-bundle-model-configurator-title-hint {
  font-size: 14px;
  pointer-events: none;
}

.store-bundle-model-configurator-fill-progress {
  background-color: #d3d3d3;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
}

.store-bundle-action-button {
  margin-top: 10px;
}
</style>
