<template>
  <main>
    <div class="website-page">
      <div class="promo__bg"></div>
      <h1 class="visually-hidden">{{ $t("configurator.main-title-charter") }}</h1>
      <div class="in-container configurator__main">
        <h2 class="configurator__title highlighted">
          {{ $t("configurator.title-charter") }}
        </h2>
      </div>
      <ConfiguratorPromo :sceneHash="sceneHash" />
    </div>
  </main>
</template>

<script>
import ConfiguratorPromo from "./ConfiguratorPromo.vue";

export default {
  data: function () {
    return {
      sceneHash: "7542cbe2ced9e29feb85",
    }
  },
  components: {
    ConfiguratorPromo
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "configurator"
    ];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "configurator"
    ];
  },
  mounted() {

  }
};
</script>

<style lang="scss">
.configurator-promo-2-container {
  height: 600px;
  margin: auto;
  margin-top: 50px;
  width: calc(100% - 100px);
}
</style>
