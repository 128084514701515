<template>
	<div class="editor-configurator-option-container" v-b-tooltip.hover.html.left.window="tooltip">
		<div class="editor-configurator-option-subcontainer">
			<div v-show="headerTitle.length > 0" ref="header" class="editor-configurator-option-header">{{headerTitle}}</div>

			<EditorPropertyString ref="nameInput"></EditorPropertyString>

			<div v-show="showMaterialsHeader" class="editor-property-header">Material variations</div>
			<div ref="modificationsContainer">

			</div>
			<EditorPropertyButton v-show="showAddModificationButton" :titleText="addButtonTitle" ref="addModificationButton"></EditorPropertyButton>
			<EditorDropDownMenu ref="modificationSelector"></EditorDropDownMenu>
		</div>
	</div>
</template>

<script>

import EditorPropertyString from './EditorPropertyString.vue'
import MaterialSelector from './MaterialSelector.vue'
import EditorPropertyButton from './EditorPropertyButton.vue'
import EditorDropDownMenu from './EditorDropDownMenu.vue'
import ObjectSelector from './ObjectSelector.vue'

import Vue from 'vue'
import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			headerTitle: "",
			onChange: null,
			key: "",
			name: "",
			tooltip: "",
			addButtonTitle: "Add modificator",
			option: null,
			showAddModificationButton: true,
			showMaterialsHeader: false
		}
	},
	components: {
		EditorPropertyString,
		EditorPropertyButton,
		EditorDropDownMenu
	},
	created() {

	},
	mounted() {

	},
	methods: {
		configure(option, itemConfig) {
			var name = ""	
			var materialId = -1
			if (option) {
				name = option.name;
			}
			this.name = name
			this.option = option;

			this.$refs.nameInput.propKey = "name"
			this.$refs.nameInput.value = name
			this.$refs.nameInput.headerText = "Display name"

			let _this = this
			this.$nextTick(() => {
				_this.$refs.nameInput.onChange = _this.onNameChange
				_this.$refs.addModificationButton.onClickCallback = _this.onClickAddModification
			});

			var modificationValues = itemConfig.modificationValues.slice() // copy
			var modificationTitles = itemConfig.modificationTitles.slice()
			var modificationTooltips = itemConfig.modificationTooltips ? itemConfig.modificationTooltips.slice() : null

			// leave material modificator only in the first option
			var materialValueIndex = modificationValues.indexOf("material")
			if (itemConfig.index >= 1 && materialValueIndex != -1) {
				modificationValues.splice(materialValueIndex, 1)
				modificationTitles.splice(materialValueIndex, 1)
				if (modificationTooltips) modificationTooltips.splice(materialValueIndex, 1)
			}

			// Materials go first
			if (option && Object.hasOwn(option, "materials") && option.materials.length > 0) {
				for (var i in option.materials) {
					var materialOption = option.materials[i]
	
					var ComponentClass = Vue.extend(MaterialSelector)
					var item = new ComponentClass()
	
					item.$mount() // pass nothing
					item.onChange = this.onChangeMaterial
					item.key = materialOption.id
					item.headerTitle = ""
					item.configure(materialOption.materialId)
					item.rightLabelText = (Number(i) + 1).toString()
					item.onClickRemoveCallback = this.onClickRemoveMaterial;
					item.showRemoveButton = itemConfig.index == 0
					item.$el.className += " editor-configurator-option-material-selector"
					this.$refs.modificationsContainer.appendChild(item.$el)
				}
				this.showMaterialsHeader = true;
			} else {
				this.showMaterialsHeader = false;
			}

			// Show objects (one item)
			if (option && Object.hasOwn(option, "showObjects") && option.showObjects.length > 0) {
				var ShowObjectSelectorClass = Vue.extend(ObjectSelector)
				var showObjectsSelectorItem = new ShowObjectSelectorClass()
				showObjectsSelectorItem.$mount() // pass nothing
				showObjectsSelectorItem.$el.className += " editor-configurator-option-object-selector"
				showObjectsSelectorItem.headerTitle = "Show objects (you can select multiple)"
				showObjectsSelectorItem.multiselect = true
				showObjectsSelectorItem.onSelectMultiple = this.onSelectShowObjects
				showObjectsSelectorItem.configure(this.option.showObjects)
				showObjectsSelectorItem.onClickRemoveCallback = this.onClickRemoveShowObjects

				showObjectsSelectorItem.showRemoveButton = true
				this.$refs.modificationsContainer.appendChild(showObjectsSelectorItem.$el)

				// remove showObjects modification from the list (because it's present already in the option)
				var showObjectsValueIndex = modificationValues.indexOf("showObjects")
				if (showObjectsValueIndex != -1) {
					modificationValues.splice(showObjectsValueIndex, 1)
					modificationTitles.splice(showObjectsValueIndex, 1)
					if (modificationTooltips) modificationTooltips.splice(showObjectsValueIndex, 1)
				}
			}

			// Hide objects (one item)
			if (option && Object.hasOwn(option, "hideObjects") && option.hideObjects.length > 0) {
				var HideObjectsSelectorClass = Vue.extend(ObjectSelector)
				var hideObjectsSelectorItem = new HideObjectsSelectorClass()
	
				hideObjectsSelectorItem.$mount() // pass nothing
				hideObjectsSelectorItem.$el.className += " editor-configurator-option-object-selector"
				hideObjectsSelectorItem.headerTitle = "Hide objects (you can select multiple)"
				hideObjectsSelectorItem.multiselect = true
				hideObjectsSelectorItem.onSelectMultiple = this.onSelectHideObjects
				hideObjectsSelectorItem.configure(this.option.hideObjects)
				hideObjectsSelectorItem.showRemoveButton = true
				hideObjectsSelectorItem.onClickRemoveCallback = this.onClickRemoveHideObjects

				this.$refs.modificationsContainer.appendChild(hideObjectsSelectorItem.$el)

				// remove modification from the list (because it's present already in the option)
				var objectsValueIndex = modificationValues.indexOf("hideObjects")
				if (objectsValueIndex != -1) {
					modificationValues.splice(objectsValueIndex, 1)
					modificationTitles.splice(objectsValueIndex, 1)
					if (modificationTooltips) modificationTooltips.splice(objectsValueIndex, 1)
				}
			}
 
			this.showAddModificationButton = modificationValues.length > 0

			this.$refs.modificationSelector.configure(modificationValues, modificationTitles, modificationTooltips) 
			this.$refs.modificationSelector.selectable = false
			this.$refs.modificationSelector.changeCallback = this.onClickModificationSelection
		},
		onClickRemoveShowObjects() {
			this.option.showObjects = []
			this.onChange(this.option, this.key, {resetEditor: true})
		},
		onClickRemoveHideObjects() {
			this.option.hideObjects = []
			this.onChange(this.option, this.key, {resetEditor: true})
		},
		onSelectHideObjects(objectIdList) {
			this.option.hideObjects = objectIdList
			this.onChange(this.option, this.key)
		},
		onSelectShowObjects(objectIdList) {
			this.option.showObjects = objectIdList
			this.onChange(this.option, this.key)
		},
		onClickRemoveMaterial(selector) {
			for (var i in this.option.materials) {
				var materialModification = this.option.materials[i]
				if (materialModification.id == selector.key) {
					this.option.materials.splice(i, 1);
					break;
				}
			}

			this.onChange(this.option, this.key, {resetEditor: true})
		},
		onClickModificationSelection(value) {
			if (value == "material") {
				this.option.materials.push({})
				this.onChange(this.option, this.key, {resetEditor: true})
			} else if (value == "showObjects") {
				this.option.showObjects.push({})
				this.onChange(this.option, this.key, {resetEditor: true})
			} else if (value == "hideObjects") {
				this.option.hideObjects.push({})
				this.onChange(this.option, this.key, {resetEditor: true})
			}
		},
		onClickAddModification() {
			this.$refs.modificationSelector.visible = !this.$refs.modificationSelector.visible
		},
		onChangeMaterial(value, key) {
			for (var i in this.option.materials) {
				var materialOption = this.option.materials[i]
				if (materialOption.id == key) {
					materialOption.materialId = value
					break;
				}
			}
			this.onChange(this.option, this.key)
		},
		onNameChange(value, key) {
			this.name = value
			this.option[key] = value
			this.onChange(this.option, this.key)
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">

.configurator-option-selector {
	margin-top: 5px;
	position: relative;
}
.editor-configurator-option-container {
	margin-bottom: 5px;
	position: relative;
}
.editor-configurator-option-subcontainer {
	background-color: rgba(0, 0, 0, 0.05);
	padding: 5px;
	border: 1px solid #c1c1c1;
	border-radius: 5px;
	position: relative;
}
.editor-configurator-option-object-outliner {
	border-radius: 3px;
}
.editor-configurator-option-header {
	font-size: 15px;
	color: black;
	text-align: left;
	font-weight: 600;
}
.editor-configurator-option-material-selector {
	margin-bottom: 5px;
}
</style>
