<template>
  <main id="explore-wrapper" class="in-container">
    <div class="website-page">
      <div class="catalog__bg"></div>

      <div class="pricing-header">{{ $t('price.title') }}</div>

      <div class="pricing-active-items-spinner-container">
        <b-spinner v-if="isLoadingProducts" variant="dark"></b-spinner>
      </div>
      <div class="pricing-current-items" ref="currentItemsContainer"></div>
    </div>
  </main>
</template>

<script>

import Vue from 'vue'
import StoreBundleItem from './StoreBundleItem.vue'

export default {
  data: function () {
    return {
      isLoadingProducts: false
    }
  },
  components: {

  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["pricing"];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["pricing"];
  },
  mounted() {
    this.loadProducts()
  },
  methods: {
    createBundleItem() {
      var ComponentClass = Vue.extend(StoreBundleItem)
      var item = new ComponentClass()
      item.loc = this.loc
      item.$mount() // pass nothing
      item.$root = this.$root // I don't know why $root doesn't match this one
      item.$store = this.$store // I don't know why $root doesn't match this one
      item.getCommonHeaders = this.getCommonHeaders
      item.showAutoRenewal = false // don't show it here


      return item
    },
    createPriceCalculatorItem() {
      let item = this.createBundleItem()
      item.title = this.$t('price-calculator.title')
      item.purchaseTitle = this.$t('price-calculator.purchase-button');
      item.showActionButton = false
      return item
    },
    createProductItem(product) {
      let item = this.createBundleItem()
      item.purchaseTitle = this.$t('price-calculator.purchase-button');
      item.configureAsProduct(product)
      item.collapsable = false
      item.editableOptions = false
      item.modelsConfigurable = false

      return item
    },
    loc(key) {
      return this.$t(key)
    },
    configureProducts(productArray) {
      for (let productIndex in productArray) {
        let product = productArray[productIndex]
        if (product.name != "default") continue

        let item = this.createProductItem(product)
        if (product.name == "default") {
          item.showPrivateModels = true
          item.showPublicModels = true
          item.showModelsAmount = false
        }
        this.$refs.currentItemsContainer.appendChild(item.$el)
      }

      let item = this.createPriceCalculatorItem()
      this.$refs.currentItemsContainer.appendChild(item.$el)
    },
    loadProducts() {
      this.$refs.currentItemsContainer.innerHTML = ""
      this.isLoadingProducts = true

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/billing/subscription/products", {}, { headers: headers }).then(function (response) {
        let productArray = response.data
        _this.configureProducts(productArray)
        _this.isLoadingProducts = false
      }).catch(function (error) {
        console.log("error", error);
        _this.isLoadingProducts = false
      });
    }
  }
}
</script>

<style lang="scss">
.pricing-active-items-spinner-container {
  width: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  text-align: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.pricing-current-items {
  display: flex;
  grid-gap: 20px;
  justify-content: center;
}

.pricing-header {
  font-size: 40px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
}

.catalog__bg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 531px;
  background: radial-gradient(circle at 531px top, #f5f8ff 531px, #fff 0) no-repeat, #f5f8ff;
  background-size: 591px 100%;
}
</style>