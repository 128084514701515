<template>
  <div class="map">
    <!--div class="map__header-container">
      <h2 class="map__header">
        {{ $t("map.title") }}
      </h2>
    </div-->

    <div class="map__content">
      <div class="map__container">
        <b-form-input
          id="map-search-input"
          ref="searchInput"
          v-model="searchText"
          class="map__search-input"
          :placeholder="$t('map.input-placeholder')"
          maxlength="64"
        />

        <Map
          :is-map-shown="isMapShown"
          :is-search-in-progress="searchInProgress"
          :models-list="modelsList"
          :map-listener="onMapMove"
          :on-marker-click="onSceneClick"
        />

        <button
          v-if="onShowListClick"
          class="map__button"
          @click="onShowListClick"
        >
          {{ $t("map.show-list") }}
        </button>
      </div>

      <transition name="fast-transition">
        <div class="map__popular-list">
          <div
            v-if="!isModelListShown && searchInProgress"
            class="map__popular-models-spinner-container"
          >
            <b-spinner class="map__popular-models-spinner" variant="dark" />
          </div>
          <SceneGrid
            v-show="isModelListShown && !searchInProgress"
            ref="sceneGrid"
            :no-models-text="noModelsText"
            :showMapIcons="false"
            skip-scenes-without-image="true"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../../const"
import getFilteredModels from "../../utils/getFilteredModels"
import SceneGrid from "../SceneGrid.vue"
import ScenePreview from "../ScenePreview/ScenePreview.vue"
import Map from "./Map.vue"
import { NO_MODELS_MESSAGE } from "./const"

export default {
  components: {
    SceneGrid,
    Map,
  },
  props: {
    modelsUrl: {
      default: API_URL.MAP_PUBLIC,
      type: String,
    },
    onShowListClick: {
      default: null,
      type: Function,
    },
  },
  data: function () {
    return {
      modelsList: [],
      searchText: "",
      searchInProgress: false,
      doNewSearch: false,
      isModelListShown: false,
      isMapShown: false,
      noModelsText: NO_MODELS_MESSAGE.NO_IN_THIS_REGION,
    }
  },
  watch: {
    searchText(newValue) {
      let _this = this
      if (this.searchTimeoutID) {
        clearTimeout(this.searchTimeoutID)
      }
      this.searchTimeoutID = setTimeout(function () {
        _this.search(newValue)
      }, 500)
    },
  },
  mounted() {
    this.isMapShown = false
    this.updateModelsList()
    this.isMapShown = true
  },
  methods: {
    async updateModelsList() {
      
      this.searchInProgress = true
      this.isModelListShown = false
      this.modelsList = []

      const headers = this.getCommonHeaders()
      this.noModelsText = NO_MODELS_MESSAGE.NO_IN_THIS_REGION

      const { data } = await this.axios.get(
        process.env.VUE_APP_API_BASEURL + this.modelsUrl,
        {
          headers: headers,
        },
      )

      const filteredModels = getFilteredModels(data)
      this.$refs.sceneGrid.setOnSelectItem(this.onSceneClick)
      this.modelsList = filteredModels
      this.searchInProgress = false
      this.isModelListShown = true
    },
    onSceneClick({ scene }) {
      this.$root.showFullscreenPopup({
        item: ScenePreview,
        classes: "model-preview__popup",
        props: {
          hash: scene.hash,
          showSeparators: false
        },
      })
    },
    onMapMove(map) {
      const _this = this
      return function () {
        const visibleItems = []
        _this.isModelListShown = false
        if (map && map.getBounds()) {
          for (let i = 0; i < _this.modelsList.length; i++) {
            if (
              map.getBounds().contains({
                lat: _this.modelsList[i].latitude,
                lng: _this.modelsList[i].longitude,
              })
            ) {
              visibleItems.push(_this.modelsList[i])
            }
          }
        }
        _this.$refs.sceneGrid.configure(visibleItems, false)
        _this.isModelListShown = true
      }
    },
    search(text) {
      if (text.length == 0) {
        this.updateModelsList()
        return
      }

      if (text.length <= 2) {
        this.modelsList = []
        this.noModelsText = NO_MODELS_MESSAGE.TYPE_MORE
        this.$refs.sceneGrid.configure([], true)
        return
      }
      if (this.searchInProgress) {
        this.doNewSearch = true
        return
      }
      this.doNewSearch = false
      this.searchInProgress = true

      let _this = this
      let headers = this.getCommonHeaders()
      this.axios
        .get(process.env.VUE_APP_API_BASEURL + API_URL.SEARCH_ALL, {
          params: { text: this.searchText },
          headers: headers,
        })
        .then(function (response) {
          const modelList = getFilteredModels(response.data.models)

          const isPublicOnly = false
          _this.$refs.sceneGrid.configure(modelList, isPublicOnly)

          let visibleModelsAmount = _this.$refs.sceneGrid.scenesVisibleAmount

          if (visibleModelsAmount > 0) {
            _this.showPopularList = visibleModelsAmount > 0
            _this.showModelList = visibleModelsAmount > 0
          } else {
            _this.noModelsText = NO_MODELS_MESSAGE.NOTHING_FOUND
            _this.showModelList = false
            _this.showStatus = true
          }

          _this.modelsList = modelList

          _this.searchInProgress = false

          if (_this.doNewSearch) {
            _this.search(_this.searchText)
          }
        })
        .catch(function (error) {
          console.log("error", error)
          _this.showModelList = false
          _this.searchInProgress = false
          _this.showStatus = true
        })
    },
  },
}
</script>

<style lang="scss">
.map {
  &__content {
    display: flex;
    flex-wrap: wrap;
    height: 70vh;

    @media (min-width: $tablet-width) {
      column-gap: 3%;
    }
  }

  &__header-container {
    text-align: center;
    width: 100%;
    margin-bottom: 35px;
    @media (max-width: $mobile-width-only) {
      margin-bottom: 25px;
    }
  }

  &__header {
    font-size: 36px !important;
    line-height: 44px !important;
    margin-bottom: 0 !important;

    @extend .font-title;

    @media (max-width: $mobile-width-only) {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }

  &__search-input {
    width: 100%;
    margin-bottom: 20px !important;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (min-width: $tablet-width) {
      width: 67%;
    }
  }

  &__map {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  &__popular-list {
    display: none;

    @media (min-width: $tablet-width) {
      display: block;
      min-width: 200px;
      width: 30%;
      padding: 20px;
      border-radius: 20px;
      box-shadow:
        0 16px 24px rgba(0, 0, 0, 0.06),
        0 2px 6px rgba(0, 0, 0, 0.04),
        0 0 1px rgba(0, 0, 0, 0.04);
      background-color: #fff;

      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;

      .scene-grid-container {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
      }
    }

    @media (min-width: $desktop-width) {
      padding: 25px 25px;
    }
  }

  &__popular-models-spinner-container {
    position: relative;
  }

  &__popular-models-spinner {
    position: absolute;
    left: 50%;
    margin-left: -0.75rem;
    margin-top: 50px;
  }

  &__button {
    color: white;
    background-color: $bg-dark;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 25px;
    border-radius: 40px !important;
    border: 0px;
  }

  &__button:hover,
  &__button:focus {
    opacity: 0.9;
  }

  &__button:active {
    opacity: 1;
    background-color: $secondary-dark;
  }
}
</style>
