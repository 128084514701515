<template>
	<div class="materials-editor">
		<div>
			<MaterialOutliner ref="outliner"></MaterialOutliner>
	
			<div id="material-outliner-button-container">
				<button                               @click="onAddMaterial()" id="material-add-btn" class="editor-default-btn">Add</button>
				<button :disabled='!showMaterialEdit' @click="onDuplicateMaterial()" id="material-duplicate-btn" class="editor-default-btn">Duplicate</button>
				<button :disabled='!showMaterialEdit' @click="onRemoveMaterial()" id="material-remove-btn" class="editor-destructive-btn">Remove</button>
			</div>
	
			<div v-html="selectedMaterialName" class="material-selected-header"></div>
		</div>
		<div ref="materialProperties" v-show="showMaterialEdit" class="editor-material-properties-container">

		</div>

		<div class="material-edit-hint" v-show="!showMaterialEdit">Select a material to edit</div>
    </div>
</template>

<script>

import MaterialOutliner from './MaterialOutliner.vue'

import Vue from 'vue'
import { nextTick } from 'vue'

let materialsEditor;

export default {
	data: function() {
		return {
			selectedMaterialName: "",
			showMaterialEdit: false,
			userEditing: true,
			ignorePropertyChange: false,
			propertyEditConfig: [ 
				{ key: "name", "type": "string", "title": "Name"}, 
				{ key: "twoSided", "type": "bool", "title": "Two-sided"},
				{ key: "baseColor", type: "typeBased", "title": "Base Color", typeTitle: "Source", types: [
						{"value": "color", "title": "Color", "options": [
							{"key": "ambient", "type": "color"}
						]},
						{"value": "texture", "title": "Texture", "options": [
							{"key": "baseColor", "type": "texture"}
						]}
					]
				},
				{ key: "diffuseColor", type: "typeBased", title: "Diffuse Color", typeTitle: "Source", types: [
						{"value": "color", "title": "Color", options: [
							{"key": "diffuse", "type": "color"}
						]}
					]
				},
				{ key: "specularColor", type: "typeBased", title: "Specular Color", typeTitle: "Source", types: [
						{value: "color", title: "Color", options: [
							{key: "specular", type: "color"}
						]},
						{"value": "texture", "title": "Texture", "options": [
							{"key": "specularColor", "type": "texture"}
						]}
					]
				},
				{ key: "transparency", type: "typeBased", "title": "Transparency", typeTitle: "Source", types: [
						{ value: "number", title: "Value", options: [
							{ key: "transparency", type: "limitedNumber", "title": "Value", min: 0, max: 1 }
						]},
						{ value: "texture", title: "Texture", options: [
							{ key: "transparency", type: "texture" }
						]}
					]
				},
				{ key: "normalMap", type: "typeBased", "title": "Normal Map", typeTitle: "Source", types: [
						{ "value": "texture", "title": "Texture", options: [
							{ key: "normalMap", type: "texture" }
						]}
					]
				},
				{ key: "metallicRoughness", type: "typeBased", "title": "Metallic-Roughness", typeTitle: "Source", types: [
						{"value": "texture", "title": "Texture", options: [
							{ key: "metallicRoughness", type: "texture" },
							{ key: "reflectable", "type": "limitedNumber", "title": "Specular intensity", "min": 0, "max": 10 }
						]},
						{"value": "number", "title": "Value", options: [
							{ key: "metallicRoughnessRoughnessFactor", type: "limitedNumber", title: "Roughness", min: 0, max: 1 },
							{ key: "reflectCosPower", "type": "limitedNumber", "title": "Metallic", "min": 0, "max": 10 },
							{ key: "reflectable", "type": "limitedNumber", "title": "Specular intensity", "min": 0, "max": 10 }
						]}
					]
				},
				{ key: "envMap", type: "typeBased", title: "Environment Map", typeTitle: "Source", types: [
						{"value": "texture", "title": "Texture", options: [
							{ key: "envMap", type: "texture", showEmbedded: true },
							{ key: "envMapFactor", "type": "limitedNumber", "title": "Environment map factor", "min": 0, "max": 5}
						]}
					]
				}
			],
			propertyEditItemMap: {},
			texturePropTypeMap: {
				"transparency" : "Opacity",
				"baseColor" : "Diffuse",
				"diffuseColor": "diffuse",
				"specularColor": "Specular",
				"normalMap" : "NormalMap",
				"metallicRoughness" : "MetallicRoughness",
				"envMap" : "EnvironmentMapCylindrical"
			},
			textureTypeMap: {
				"baseColor": 1,
				"normalMap": 3,
				"specularColor": 4,
				"transparency": 7,
				"metallicRoughness": 18,
				"envMap": 19
			}
		}
	},
	components: {
		MaterialOutliner
	}, 
	created() {

	},
	mounted() {
		let _this = this;
		this.$refs.outliner.onSelect = function (index) {
			_this.select(index, false/*from engine*/)
		}
	},
	methods: {
		setMaterialForId(newMaterial, ident) {
			this.$refs.outliner.setMaterialForId(newMaterial, ident)
		},
		getMaterialForId(ident) {
			return this.$refs.outliner.getMaterialForId(ident)
		},
		removeChannel(textureType) {
			var material = this.$refs.outliner.getSelectedMaterial()
			if (!material) return ""
				
			if (Object.hasOwn(this.texturePropTypeMap, textureType)) {
				for (var channeIndex in material.textureChannels) {
					var channel = material.textureChannels[channeIndex]
						if (channel.textureType == this.textureTypeMap[textureType]) {
							material.textureChannels.splice(Number(channeIndex), 1);
							break;
						}
				}
			}
			this.emitMaterialChange()
		},
		changeChannelTexture(textureType, texture) {
			var material = this.$refs.outliner.getSelectedMaterial()
			if (!material) return ""
			
			// remove channel if no texture
			if (!texture) {
				this.removeChannel(textureType)
				return;
			}

			var foundChannel = false

			// find existing channel
			var engineTextureTypeString = this.texturePropTypeMap[textureType]
			if (Object.hasOwn(this.texturePropTypeMap, textureType)) {
				for (var channeIndex in material.textureChannels) {
					var channel = material.textureChannels[channeIndex]
					if (channel.type == engineTextureTypeString) {
						channel.textureId = texture.id
						channel.texturePath = texture.relativePath
						foundChannel = true
						break;
					}
				}
			}

			// add channel if not found
			if (!foundChannel) {
				var newChannel = {}
				newChannel.scale = {x: 1, y: 1, z: 1}
				newChannel.texturePath = texture.relativePath
				newChannel.textureId = texture.id
				newChannel.textureType = Number(this.textureTypeMap[textureType])
				material.textureChannels.push(newChannel)
			}
			this.emitMaterialChange()
		},
		onChangePropertyType(key, type) {
			var textureType = key
			if (Object.hasOwn(this.texturePropTypeMap, textureType)) {
				// if not texture selected, remove texture binding
				if (type != "texture") {
					this.removeChannel(textureType)
				}
			}
		},
		// Get current prop type based on current material state
		getPropertyType(key) {
			var material = this.$refs.outliner.getSelectedMaterial()
			if (!material) return ""

			var result = ""

			// define defaults
			if (key == "baseColor" || key == "diffuseColor" || key == "specularColor") {
				result = "color"
			} else if (key == "transparency" || key == "metallicRoughness") {
				result = "number"
			} else if (key == "normalMap" || key == "envMap") {
				result = "texture" // only texture
			}

			// find out if we have a texture for this channel
			for (var channeIndex in material.textureChannels) {
				var channel = material.textureChannels[channeIndex]
				if (Object.hasOwn(this.texturePropTypeMap, key)) {
					if (channel.type == this.texturePropTypeMap[key]) {
						result = "texture"
						break;
					}
				}
			}
			return result;
		},
		getPropertyValue(key, type) {
			var material = this.$refs.outliner.getSelectedMaterial()
			if (!material) return ""

			if (type == "texture") {
				for (var channeIndex in material.textureChannels) {
					var channel = material.textureChannels[channeIndex]
					if (Object.hasOwn(this.texturePropTypeMap, key)) {
						if (channel.type == this.texturePropTypeMap[key]) {
							return this.$editor.getTextureById( channel.textureId )
						}
					}
				}
				return null
			} else if (type == "color") {
				return this.convertColorXYZToHex(material[key])
			} else if (type == "number") {
				if (key == "metallicRoughness") {
					key = "metallicRoughnessRoughnessFactor"
				}
				return material[key]
			}
			
			return material[key]
		},
		configure(materialList) {
			this.$refs.outliner.configure(materialList)
		},
		selectById(id) {
			this.$refs.outliner.selectById(id);
		},
		showPropertiesEditor() {
			var material = this.$refs.outliner.getSelectedMaterial()

			this.ignorePropertyChange = true;
			this.$refs.materialProperties.innerHTML = ""

			this.$editor.addProperties(this.propertyEditConfig, material, this.$refs.materialProperties, this.propertyEditItemMap, this.onChangeProperty, this.getPropertyType, this.getPropertyValue, this.onChangePropertyType)

			var _this = this
			this.$nextTick(() => {
				_this.ignorePropertyChange = false
			});
		},
		select(index) {
			var _this = this;

			this.userEditing = false;

			this.showMaterialEdit = index >= 0;

			if (this.showMaterialEdit) {
				this.selectedMaterialName = this.$refs.outliner.selectedMaterialObject.name;
				this.showPropertiesEditor();
			} else {
				this.selectedMaterialName = ""
			}
			this.$nextTick(() => {
				_this.userEditing = true;
			});
		},
		onDuplicateMaterial() {
			var material = this.$refs.outliner.getSelectedMaterial()

			var materials = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["duplicateMaterial", material.id.toString()]);
			materials = eval("(" + materials + ")");

			this.configure(materials);

			this.$refs.outliner.$el.scrollTo(0, this.$refs.outliner.$el.scrollHeight);

			this.$refs.outliner.selectById(materials[materials.length - 1].id);

			this.$editor.onChangedMaterials(materials)
			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		onAddMaterial() {
			var materials = this.$editor.module.ccall('callFromJS', 'string', ['string'], ["addMaterial"]);
			materials = eval("(" + materials + ")");

			this.configure(materials);

			this.$refs.outliner.$el.scrollTo(0, this.$refs.outliner.$el.scrollHeight);

			this.$refs.outliner.selectById(materials[materials.length - 1].id);

			this.$editor.onChangedMaterials(materials)
			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		onRemoveMaterial() {
			var material = this.$refs.outliner.getSelectedMaterial()

			var materials = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["removeMaterial", material.id.toString()]);
			materials = eval(materials);

			this.configure(materials);

			this.$editor.onChangedMaterials(materials)
			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		updatePropertyItems() {
			var material = this.$refs.outliner.getSelectedMaterial()
			for (var key in this.propertyEditItemMap) {
				var item = this.propertyEditItemMap[key]
				item.value = material[key]
			}
		},
		colorelementToHex(element) {
			return Math.floor(element * 255).toString(16).padStart(2, '0')
		},
		emitMaterialChange() {
			var _this = this;

			var material = this.$refs.outliner.getSelectedMaterial()

			var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string', 'string'], ["editMaterial", material.id.toString(), JSON.stringify(material, null, 2)]);

			var responseObject = eval( '(' + response + ')');
			var materialObjectFromEngine = responseObject.material;
			var errorObject = responseObject.error;

			this.setMaterialForId(materialObjectFromEngine, material.id)

			if (errorObject.length > 0) {
				this.$editor.showMessage(errorObject, true)
				this.$nextTick(() => {
					_this.updatePropertyItems()
				});
			}

			this.selectedMaterialName = materialObjectFromEngine.name

			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		onChangeProperty(value, key) {

			if (Object.hasOwn(value, "type")) {
				if (value.type == "color") {
					value = this.convertHexColorToXYZ(value.value)
				} else if (value.type == "number") {
					value = value.value
				} else if (value.type == "texture") {
					value = value.value

					this.changeChannelTexture(key, value)
					return
				}
			}
			
			if (this.ignorePropertyChange) return;

			var material = this.$refs.outliner.getSelectedMaterial()
			material[key] = value

			this.emitMaterialChange()
		}
	},
	watch: {

	}
}

</script>

<style lang="scss">
.materials-editor {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.editor-material-properties-container {
	padding: 0px 10px;
	width: 100%;
	overflow: auto;
	overflow-x: hidden;
	flex-grow: 1;
}
#material-outliner-button-container {
	width: 100%;
	height: 42px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
	grid-gap: 5px;
	justify-content: center;
	padding: 5px 5px;
	width: 100%;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.04);
}
.material-editor {
	text-align: center;
}
.material-edit-hint {
	text-align: center;
	margin: 15px 10px;
	color: #121212;
	position: absolute;
	top: 280px;
	width: 100%;
}
.material-selected-header {
	width: 100%;
	height: 30px;
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #e1e1e1;
	padding-left: 10px;
	font-weight: 600;
	text-align: left;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.material-outliner {
	width: 100%;
	height: 150px;
	overflow: auto;
	position: relative;
	overflow-x: hidden;
}

</style>
