<template>
	<div class="editor-property-slider-container">
		<div ref="header" class="editor-property-header">{{titleText}}</div>
		<div class="editor-property-slider-wrapper">
			<div ref="sliderControl" class="editor-property-slider-control">
				<div ref="slider" class="editor-property-slider-control-active"  @mousedown="onSliderDown" @touchstart="onSliderDown"></div>
			</div>
			<b-form-input ref="input" class="editor-property-slider-input editor-single-line-input" v-model="value" v-on:blur="onLostFocus" @keypress="isNumber($event)"></b-form-input>
		</div>
	</div>
</template>

<script>


export default {
	props: {
		titleText: { type: String, default: "" },
		propKey: { type: String, default: "" },
		min: { default: 0 },
		max: { default: 1 }
	},
	data: function() {
		return {
			value: "",
			sliderX: 0,
			onChange: null
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		let _this = this
		const resizeObserver = new ResizeObserver(() => {
			_this.updateSlider();
		});

		resizeObserver.observe(this.$el);
	},
	methods: {
		getEventCords (event) {
			if (event.type.match(/^touch/i)) {
				const touch = event.touches[0];
				return { x: touch.clientX, y: touch.clientY };
			}
			if (event.type.match(/^mouse/i)) {
				return { x: event.clientX, y: event.clientY };
			}
			return { x: 0, y: 0 };
		},
		correctValue(value) {
			var correctedVal = parseFloat(value)

			if (correctedVal != correctedVal.toFixed(2)) {
				correctedVal = correctedVal.toFixed(2)
			}
			correctedVal = Number(correctedVal)
			
			if (isNaN(correctedVal)) {
				correctedVal = 0
			}

			if (correctedVal < this.min) {
				correctedVal = this.min;
			}
			if (correctedVal > this.max) {
				correctedVal = this.max;
			}
			
			return correctedVal;
		},
		onLostFocus() {
			var corrected = this.correctValue(this.value)

			if (this.value !== corrected) {
				this.value = corrected
			}
		},
		onSliderDown() {
			var _this = this;

			if (event.button === 2) return;
			event.preventDefault();

			const lastMove = this.sliderX
			const startPosition = this.getEventCords(event);

			const handleDragging = (evnt) => {
				window.requestAnimationFrame(() => {
					
					const endPosition = _this.getEventCords(evnt);
					var newSliderX = lastMove + endPosition.x - startPosition.x
					
					if (newSliderX < 0) newSliderX = 0;
					if (newSliderX > _this.$refs.sliderControl.clientWidth - _this.$refs.slider.clientWidth) newSliderX = _this.$refs.sliderControl.clientWidth - _this.$refs.slider.clientWidth

					_this.sliderX = newSliderX
					
					var percent = newSliderX / (_this.$refs.sliderControl.clientWidth - _this.$refs.slider.clientWidth)
					var value = _this.min + (_this.max - _this.min) * percent
					_this.value = _this.correctValue(value)
				});
			};
			const handleRelase = () => {
				document.removeEventListener('mousemove', handleDragging);
				document.removeEventListener('mouseup', handleRelase);
				document.removeEventListener('touchmove', handleDragging);
				document.removeEventListener('touchup', handleRelase);
			};
			document.addEventListener('mousemove', handleDragging);
			document.addEventListener('mouseup', handleRelase);
			document.addEventListener('touchmove', handleDragging);
			document.addEventListener('touchup', handleRelase);
		},
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		updateSlider() {
			var val = this.correctValue(this.value)

			var percent = (val - this.min) / (this.max - this.min)
			if (this.$refs.sliderControl) {
				var sliderX = (this.$refs.sliderControl.clientWidth - this.$refs.slider.clientWidth) * percent
				this.sliderX = sliderX
			}
		}
	},
	watch: {
		sliderX (val, oldVal) {
			val = Math.min(Math.max(val, 1), this.$refs.sliderControl.clientWidth - this.$refs.slider.clientWidth - 1)
			this.$refs.slider.style.left = val + "px"
		},
		value (val, oldVal) {
			val = Number(val) // in case we get string

			if (val === oldVal) return;

			this.updateSlider()

			if (this.onChange) {
				this.onChange(val, this.propKey);
			}
		}
	}
}

</script>

<style lang="scss">
.editor-property-slider-wrapper {
	position: relative;
}
.editor-property-slider-input {
	width: 60px !important;
	text-align: center;
	position: absolute !important;
	right: 0;
	top: 0;
	height: 30px !important;
}
.editor-property-slider-control {
	margin-right: 65px;
	background-color: rgba(1, 0, 0, 0.05);
	height: 30px;
	border-radius: 5px;
	border: 1px solid #cecece;
}
.editor-property-slider-container {
	position: relative;
	width: 100%;
}
.editor-property-slider-control-active {
	width: 25px;
	height: 28px;
	position: absolute;
	top: 1;
	left: 1;
	border-radius: 4px;
	background-color: white;
	border: 1px solid #cecece;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}

.editor-property-slider-control-active:hover {
	background-color: rgb(245, 245, 245);
}

</style>
