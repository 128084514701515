<template>
	<div class="object-item">
		<div ref="expandButton" v-show="hasChildren" @click="onExpand" class="object-item-expand-button">
			<img ref="expandImg" class="object-item-expand-img" src="/expand-arrow-right.svg"/>
		</div>
		<div class="object-item-type-label unselectable" ref="itemTypeLabel"></div>
		<div class="object-item-container" 
			@click="onPress"
			@mouseover="onHover"
			@mouseout="onOut"
		>
			<div v-show="selected" class="object-item-selected-background"></div>
			<div ref="titleElement" v-html="title" class="object-item-title unselectable"></div>
			<div v-show="highlighted" class="object-item-overlay"></div>

			<div class="object-item-subselection" v-show="subselected"></div>
			<div class="object-item-subhighlight" v-show="subhighlighted"></div>
		</div>
		
		<div class="object-item-children" ref="childItems"></div>
	</div>
</template>

<script>

import SceneMaterials from './SceneMaterials.vue'

export default {
	data: function() {
		return {
			title: "",
			selected: false,
			highlighted: false,
			subselected: false,
			subhighlighted: false,
			expanded: false,
			hasChildren: false,
			onExpandCallback: null,
			childrenItems: [],
			object: null,
			level: -1,
			type: "node",
			parentObject: null,
			meshId: 0,
			onSelect: null,
			onHighlight: null,
			expandable: true
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		configure(object, type) {
			this.title = object.name
			if (process.env.NODE_ENV === 'development') {
				this.title = object.name + " [" + object.id + "]"
			}
			this.object = object
			this.type = type
			this.hasChildren = type == "node" && (object.children.length > 0 || object.meshes.length)
			if (type == "node") {
				if (object.isAnnotation) {
					this.$refs.itemTypeLabel.innerHTML = "A"
					this.$refs.itemTypeLabel.style["background-color"] = "#dd4d4d"
				} else  {
					this.$refs.itemTypeLabel.innerHTML = "N"
					this.$refs.itemTypeLabel.style["background-color"] = "#21a1e1"
				}
			} else if (type == "mesh") {
				this.$refs.itemTypeLabel.innerHTML = "M"
				this.$refs.itemTypeLabel.style["background-color"] = "#fda727"
			}
		},
		highlight(highlight) {
			this.highlighted = highlight;
		},
		select(select) {
			this.selected = select;
			if (select) {
				this.$refs.titleElement.className = "object-item-title-selected"
			} else {
				this.$refs.titleElement.className = "object-item-title"
			}
		},
		onPress() {
			if (this.onSelect) {
				this.onSelect(this)
			}
		},
		onHover() {
			if (this.onHighlight) {
				this.onHighlight(this)
			}
		},
		onOut() {
			if (this.onHighlight) {
				this.onHighlight(null)
			}
		},
		onExpand() {
			this.expanded = !this.expanded;
			if (this.expanded) {
				this.$refs.expandImg.src = "/expand-arrow-down.svg"
			} else {
				this.$refs.expandImg.src = "/expand-arrow-right.svg"
			}
			if (this.onExpandCallback) {
				this.onExpandCallback(this.expanded, this)
			}
		}
	},
	watch: {
		level(value, oldValue) {
			var leftMargin = value * 5
			this.$refs.expandButton.style.left = leftMargin + "px"

			leftMargin += 26 + 3
			this.$refs.itemTypeLabel.style.left = leftMargin + "px"

			leftMargin += 21 + 5
			this.$refs.titleElement.style["margin-left"] = leftMargin + "px"
		},
		expandable(val) {
			if (!val) {
				this.$refs.itemTypeLabel.style["left"] = "5px"
				this.$refs.titleElement.style["margin-left"] = "35px"
			}
		}
	}
}

</script>

<style lang="scss">
.object-item {
	position: relative;
}
.object-item-selected-background {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #cbc7c7;
}
.object-item-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.object-item-container {
	width: 100%;
	height: 30px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	text-align: left;
}
.object-item-container:hover {
	cursor: pointer;
}
.object-item-title {
	color: black;
	line-height: 30px;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 45px;
	margin-right: 20px;
}
.object-item-title-selected {
	color: black;
	line-height: 30px;
	font-weight: 500;
	position: relative;
	margin-left: 45px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 20px;
}
.object-item-expand-button {
	width: 26px;
	height: 26px;
	position: absolute;
	left: 3px;
	top: 2px;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0);
	z-index: 2;
	cursor: pointer;
}
.object-item-expand-button:hover {
	background-color: rgba(0, 0, 0, 0.05);
}
.object-item-expand-img {
	width: 100%;
	height: 100%;
	padding: 2px;
	opacity: 0.7;
}
.object-item-type-label {
	z-index: 1;
	position: absolute;
	left: 30px;
	border-radius: 3px;
	background-color: rgba(210, 210, 210, 1);
	color: white;
	font-weight: 900;
    font-size: 12px;
    line-height: 21px;
    width: 21px;
    height: 21px;
    top: 4px;
    pointer-events: none;
    text-align: center;
}
.object-item-subselection {
	width: 6px;
	height: 6px;
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translate(0px, -50%);
	border-radius: 3px;
	background-color: #cbc7c7;
}
.object-item-subhighlight {
	width: 6px;
	height: 6px;
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translate(0px, -50%);
	border-radius: 3px;
	background-color: rgba(0, 0, 0, 0.1);
}
.object-item-children {
	position: relative;
}
</style>
