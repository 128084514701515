<template>
	<div class="editor-dropdown-menu">
		<transition name="fast-transition">
			<div v-show="visible" ref="editorSelectorMenu" class="editor-selector-menu">
				
			</div>
		</transition>

		<div ref="editorSelectorMenuItemTemplate" class="editor-selector-menu-item" hidden>
			<span class="editor-selector-menu-item-label"></span>
			<img class="editor-selector-menu-item-checkmark" src="~@/assets/checkmark.svg" hidden/>
		</div>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			values: [],
			strings: [],
			visible: false,
			changeCallback: null,
			value: null,
			selectable: true
		}
	},
	components: {

	},
	mounted() {

	},
	methods: {
		open() {
			let _this = this
			this.closeCallback = (evnt) => {
				if (!_this.isElementClosest(evnt.target, _this.$refs.editorSelectorMenu)) {
					_this.visible = false
				}
			};
			document.addEventListener('mousedown', this.closeCallback);
		},
		close() {
			document.removeEventListener('mousedown', this.closeCallback);
		},
		update() {
			this.updateCheckmarks();
		},
		updateCheckmarks() {
			let selectedIndex = this.values.indexOf(this.value)
		
			if (!this.$refs.editorSelectorMenu.hasChildNodes()) return;

			let nodes = this.$refs.editorSelectorMenu.childNodes;

			for (var n = 0; n < nodes.length; ++n) {
				let node = nodes[n]
				var checkmark = node.getElementsByClassName("editor-selector-menu-item-checkmark")[0]
				checkmark.hidden = this.selectable ? !(n == selectedIndex) : true
			}
		},
		setSelected(value) {
			if (this.changeCallback) this.changeCallback(value)
			
			this.updateCheckmarks();
		},
		configure(values, titles, tooltips) {
			var valuesArray = []
			var titlesArray = []

			for (var valueIndex in values) {
				var val = values[valueIndex]
				if (val instanceof Object) {
					valuesArray.push(val.value)
				} else {
					valuesArray.push(val) 
				}
			}
			for (var titleIndex in titles) {
				var title = titles[titleIndex]
				if (title instanceof Object) {
					titlesArray.push(title.title)
				} else {
					titlesArray.push(title) 
				}
			}
			this.values = valuesArray;
			this.strings = titlesArray;
			this.tooltips = tooltips
			this.updateMenuElements();
		},
		isElementClosest(element, wrapper) {
			while (element !== document && element !== null) {
				if (element === wrapper) return true;
				element = element.parentNode;
			}
		
			return false;
		},
		updateMenuElements() {
			var menuItemTemplateElement = this.$refs.editorSelectorMenuItemTemplate;
			if (menuItemTemplateElement == null) return;

			this.$refs.editorSelectorMenu.innerHTML = ""
			let _this = this;

			for (var index in this.strings) {
				let value = this.values[index]
				let tooltip = null;
				if (this.tooltips && this.tooltips instanceof Array) {
					tooltip = this.tooltips[index]
				}
				var element = menuItemTemplateElement.cloneNode( true )
				//if (tooltip) {
				//element
				//	v-b-tooltip.hover.left.html.window="tooltip"
				//}

				var label = element.getElementsByClassName("editor-selector-menu-item-label")[0]
				label.innerHTML = this.strings[index]
				element.hidden = false;
				element.addEventListener('mousedown', function() {
					_this.setSelected(value)
					_this.visible = false
				});
				this.$refs.editorSelectorMenu.appendChild(element)
			}
		}
	},
	watch: {
		visible(val, oldVal) {
			if (val == oldVal) return

			if (val) {
				this.open()
			} else {
				this.close()
			}
		},
		value(val, old) {
			this.updateCheckmarks()
		}
	}
}
</script>


<style lang="scss">

.editor-selector-container-dropdown {
	width: 15px;
	height: 15px;
	position: absolute;
	right: 5;
	top: 50%;
	transform: translate(0, -50%);
}
.editor-selector-menu {
	width: 100%;
	position: absolute;
	min-height: 35px;
    background-color: white;
    top: 1px;
    border-radius: 5px;
    z-index: 20;
    border: 1px solid #c2c2c2;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
    max-height: 200px;
}
.editor-selector-menu-item {
	height: 30px;
	line-height: 30px;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding-left: 10px;
    cursor: pointer;
    position: relative;
    text-align: left;
}
.editor-selector-menu-item:hover {
	background-color: #f0f0f0;
}
.editor-selector-menu-item-checkmark {
	width: 15px;
	height: 15px;
	position: absolute;
	right: 5;
	top: 50%;
	transform: translate(0, -50%);
}
.editor-dropdown-menu {
	position: relative;
}
</style>