<template>
	<div class="editor-property-typebased-container">
		<div ref="header" class="editor-property-typebased-header">{{titleText}}</div>
		<EditorPropertyPicker v-show="showTypePicker" ref="typePicker"></EditorPropertyPicker>
		<div ref="propContainer"></div>
	</div>
</template>

<script>


import EditorPropertyPicker from './EditorPropertyPicker.vue'

export default {
	props: {
		onChange: { type: Function }
	},
	data: function() {
		return {
			value: "",
			type: "",
			showTypePicker: true,
			showColorPicker: false,
			showSlider: false,
			showTextureSelector: false,
			titleText: { type: String, default: "" },
			config: {},
			propKey: { type: String, default: "" },
			getCurrentType: null,
			getPropertyValue: null,
			propertyEditItemMap: {}
		}
	},
	components: {
		EditorPropertyPicker
	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		configure(config) {
			var _this = this;

			this.titleText = config.title
			this.config = config
			this.propKey = config.key

			var type = this.getCurrentType(config.key)
			this.type = type

			this.showTypePicker = this.config.types.length > 1

			this.$refs.typePicker.options = this.config.types
			this.$refs.typePicker.titleText = config.typeTitle
			this.$refs.typePicker.value = type

			this.$nextTick(() => {
				_this.$refs.typePicker.onChange = function(newType, key) {
					_this.type = newType
					if (_this.onChangeType) {
						_this.onChangeType(_this.propKey, newType)
					}
					_this.resetCurrentValues();
				}
      });
			
			
			this.resetCurrentValues();
		},
		resetCurrentValues() {
			var type = this.type

			var selectedTypeConfig;
			for (var typeConfigIndex in this.config.types) {
				var typeConfig = this.config.types[typeConfigIndex]
				if (type == typeConfig.value) {
					selectedTypeConfig = typeConfig;
					break;
				}
			}

			this.$refs.propContainer.innerHTML = ""
			this.$editor.addProperties(selectedTypeConfig.options, this.object, this.$refs.propContainer, this.propertyEditItemMap, this.onChange, null, this.getPropertyValue, null)
		}
	},
	watch: {
		type (val, oldVal) {
			if (val === oldVal) return;

			this.showColorPicker = val == "color"
			this.showSlider = val == "number"
			this.showTextureSelector = val == "texture"
		},
		value (val, oldVal) {
			if (val === oldVal) return;

			this.onChange(val, this.propKey);
		}
	}
}

</script>

<style lang="scss">

.editor-property-typebased-header {
  color: #070707;
  line-height: 20px;
  font-size: 16;
  font-weight: 500;
  padding: 0 0 1 3;
  text-align: left;
}
.editor-property-typebased-wrapper {
	position: relative;
}
.editor-property-typebased-color-picker {
	margin-top: 5px;
}
.editor-property-typebased-texture-selector {
	margin-top: 5px;
	position: relative;
}
.editor-property-typebased-material-selector {
	margin-top: 5px;
	position: relative;
}
.editor-property-typebased-container {
	background-color: rgba(0, 0, 0, 0.05);
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid #c1c1c1;
}
.editor-property-container {
	margin-top: 5px;
}

</style>
