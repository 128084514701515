<template>
  <main>
    <div class="website-page">
      <h1 class="visually-hidden">{{$t('title')}}</h1>
      <Promo></Promo>
      
      <Development></Development>
  
      <Applying></Applying>
      <Invitation></Invitation>
    </div>
  </main>
</template>

<script>

import Promo from "../views/Promo.vue"
import ConfiguratorPromo from "../views/ConfiguratorPromo.vue"
import Applying from "../views/Applying.vue"
import Development from "../views/Development.vue"
import Invitation from "../views/Invitation.vue"

  export default {
    components: {
      Promo,
      Development,
      Applying,
      Invitation
    },
    created() {
      document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["home"];
    },
    updated() {
      document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["home"];
    }
  }
</script>
