<template>
  <main>
    <div class="website-page">
      <div class="promo__bg"></div>
      <h1 class="visually-hidden">{{ $t("education.main-title-charter") }}</h1>
      <div class="in-container">
        <h2 class="education__title highlighted">
          {{ $t("education.main-title-charter") }}
        </h2>
        <div class="museum__text_container">
          <p class="museum__text">{{ $t("education.main-p-first") }}</p>
        </div>
      </div>
      <section class="education__section">
        <div class="education__upper in-container">
          <img
            class="education__img"
            width="391px"
            height="442px"
            src="./../assets/promo_education.png"
            v-bind:alt="$t('education.alt-img.display')"
          />
          <div class="education__upper-features">
            <FeaturesList nameOfFeatures="education"></FeaturesList>
            <b-link :to="{name: 'Register'}" class="button_promo btn" id="application-btn-try">{{$t('btn-try-for-free')}}</b-link>
          </div>
        </div>
        <div class="education__lower in-container">
          <div style="width: 100%; aspect-ratio: 1.78; margin: auto;">
              <iframe src="https://www.youtube.com/embed/QPSfPp4GLPw?si=Jurcoj557Kl4wwQz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="width: 100%; height: 100%;"></iframe>
              <!--img width="391px" height="442px" src="./../assets/sample02.jpg" v-bind:alt="$t('development.alt-img.phones')"-->
          </div>
          <div id="archengine-embed-container-education"> 
            <iframe ref="educationWebSceneFrame" title="ARCHITEQUE 3D" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="0"   style="width: 100%; height: 100%;"></iframe>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import FeaturesList from "./FeaturesList.vue"
export default {
  components: { FeaturesList },
  data: function() {
    return {
      descriptions: ["instant-demo", "scale", "access", "upload", "interact"]
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "education"
    ]
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles[
      "education"
    ]
  },
  mounted() {
    // Update frame src
    var src = this.getArchwebURL(true) + "uiStyle=dark&allowScale=1&allowMoveCameraSideways=1&showCredit=0&allow-fullscreen=1&annotations-toggle=1&scene=07534748f2054a89";
    if (process.env.NODE_ENV === 'development') {
      src += "&api=" + process.env.VUE_APP_API_BASEURL;
      src += "&website-url" + process.env.VUE_APP_WEBSITE_URL;
    }
    this.$refs.educationWebSceneFrame.src = src;
  }
}
</script>

<style lang="scss">
.education__title {
  text-align: center;
  font-size: 36px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 35px !important;

  @extend .font-title;
}

.education__title-section {
  margin-bottom: 20px !important;

  @extend .title-section;
}

#archengine-embed-container-education {
  width: 100%;
  margin: auto;
  aspect-ratio: 1.78;

  @media (max-width: $mobile-width-only) {
    margin-top: 30px;
  }
}

#archengine-embed-container-education iframe {
  background-color: #ededed;
  box-shadow: inset 0px 0px 180px rgba(0, 0, 0, 0.3);
}

.education__upper-features {
  padding-top: 30px;
}

.education__img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 30px;
}

.education__upper {
  display: grid;
  padding: 0 10% 10px;
  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 7%;
    grid-template-columns: 47% 44%;
  }
}

.education__lower {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 30px;
  }
}

.descriptions__item--instant-demo::before {
  background-image: url("./../assets/instant-demo.png");
}

.descriptions__item--scale::before {
  background-image: url("./../assets/scale.png");
}

.descriptions__item--access::before {
  background-image: url("./../assets/access.png");
}

.descriptions__item--upload::before {
  background-image: url("./../assets/upload.png");
}

.descriptions__item--interact::before {
  background-image: url("./../assets/interact.png");
}

.education__subtitle {
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;

  .education__title {
    display: inline;

    @extend .title-chapter;
  }
}
</style>
