<template>
  <ul class="features__list_light">
    <li v-for="number of 5" v-bind:key="number" class="features__item_light">
      {{ $t(`${nameOfFeatures}.features.feature${number}`) }}
    </li>
  </ul>
</template>

<script>
export default {
  props: { nameOfFeatures: String }
};
</script>

<style lang="scss">
.features__list_light {
  padding: 0 !important;
  list-style: none;
}

.features__item_light {
  position: relative;
  padding-left: 34px;
  margin-top: 5px;

  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
}

.features__item_light:not(:last-child) {
  margin-bottom: 15px;
}

.features__item_light::before {
  content: "";
  position: absolute;
  top: 3;
  left: 0;

  display: block;
  width: 22px;
  height: 22px;

  background-image: url("./../assets/icon-checked-white.svg");
  background-repeat: no-repeat;
}
</style>
