<template>
  <div>
    <b-alert fade v-model="dismissSuccessMessageCountDown" class="position-fixed fixed-top text-center"
      style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="success" dismissible>
      {{ successMessage }}
    </b-alert>
    <b-alert fade v-model="dismissErrorMessageCountDown" class="position-fixed fixed-top text-center"
      style="z-index: 999999; top: 90px; margin: 0 auto; max-width:750px;" variant="danger" dismissible>
      {{ errorMessage }}
    </b-alert>

    <div class="collection-header-container">
      <div class="account-card-header">{{ $t('edit-collection.title') }}</div>

      <div class="edit-collection-button-container">
        <b-button ref="editButton" id="edit-collection-button-edit" class="edit-collection-button"
          :disabled="!editsArePresent" v-on:click="onSaveCollection()" variant="primary">{{ $t('profile.save-btn') }}
        </b-button>

        <b-button class="edit-collection-button add-model-to-collection-button"
          :disabled="isSystemCollection || disableEditButton" @click="onEdit">
          <span class="text-white">{{ $t('edit-collection.edit-contents-button') }}</span>
        </b-button>

        <b-button class="edit-collection-button" id="edit-collection-button-delete" :disabled="isSystemCollection"
          @click="onDelete">
          <span class="text-white">{{ $t('edit-collection.delete-button') }}</span>
        </b-button>
      </div>
    </div>

    <div class="account-content-container">
      <div id="my-models-spinner-container" v-if="modelListLoadingInProcess">
        <b-spinner id="profile-models-spinner" variant="dark"></b-spinner>
      </div>

      <b-form-group v-bind:label="$t('edit-collection.name-title')"
        v-bind:invalid-feedback="$t('profile.field-required')">
        <b-form-input class="edit-collection-name-input" :disabled="isSystemCollection" v-model="name"
          maxlength=64></b-form-input>
      </b-form-group>

      <div id="collection-checkbox-public-container">
        <b-form-checkbox v-show="!isSystemCollection" id="collection-checkbox-public"
          v-model="isPublic">Public</b-form-checkbox>
      </div>

      <transition name="fast-transition">
        <SceneGrid v-show="showModelList" ref="sceneGrid"></SceneGrid>
      </transition>
    </div>
  </div>
</template>

<script>

import SceneGrid from './SceneGrid.vue';

export default {
  data: function () {
    return {
      dismissSuccessMessageCountDown: 0,
      dismissErrorMessageCountDown: 0,
      successMessage: null,
      errorMessage: null,
      collectionHash: "",
      modelListLoadingInProcess: false,
      showModelList: false,
      name: "",
      isPublic: false,
      editsArePresent: false,
      configuring: false,
      isSystemCollection: false,
      disableEditButton: false
    }
  },
  components: {
    SceneGrid
  },
  mounted() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["edit-collection"];

    let collectionHash = this.$route.query.hash;
    let path = this.$router.currentRoute.path;
    collectionHash = path.substring(path.lastIndexOf('/') + 1)
    this.collectionHash = collectionHash;

    this.reloadSceneList()

    this.$refs.sceneGrid.noModelsText = "Press <b>Edit Contents</b> to add or remove 3D scenes"
    this.$refs.sceneGrid.onSceneSelected = this.onSceneSelected
    this.$refs.sceneGrid.showPublicLabel = true
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["edit-collection"];
  },
  methods: {
    onDelete() {
      let _this = this;
      this.$root.$emit('showDialog', "Warning", "Delete this collection? This action can't be undone<br><br><i>*This action doesn't delete 3D scenes in this collection<i>", "Cancel", "Delete", function () {
        _this.deleteCollection()
      })
    },
    deleteCollection() {
      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.delete(process.env.VUE_APP_API_BASEURL + "/api/v1/group", { params: { "groupId": this.collection.id }, headers: headers }).then(function (response) {
        _this.successMessage = "Deleted successfully"
        _this.dismissSuccessMessageCountDown = 5;
        _this.$router.push({ name: 'MyCollections' });
      }).catch(function (error) {
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    },
    onSceneSelected(grid, item, isSelected) {
      this.editsArePresent = true
    },
    onSaveCollection() {
      this.collection.isPublic = this.isPublic
      for (let localeIndex in this.collection.locales) {
        let locale = this.collection.locales[localeIndex]
        if (locale.cultureId == 1 /*en*/) {
          locale.name = this.name
        }
      }

      let collection = this.collection;

      // set up scene hashes to be included in the collection
      // only set up if changed (not null in this case)
      collection.modelHashes = this.$refs.sceneGrid.getSelectedSceneHashArray()

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.put(process.env.VUE_APP_API_BASEURL + "/api/v1/group", collection, { headers: headers }).then(function (response) {
        _this.$refs.sceneGrid.selectable = false
        _this.reloadSceneList()
        _this.editsArePresent = false
        _this.disableEditButton = false
        _this.successMessage = "Saved successfully"
        _this.dismissSuccessMessageCountDown = 5;
      }).catch(function (error) {
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    },
    onEdit() {
      let _this = this;
      let headers = this.getCommonHeaders();

      this.showModelList = false;
      this.modelListLoadingInProcess = true;
      this.editsArePresent = true
      this.disableEditButton = true

      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/models/my", { params: {}, headers: headers }).then(function (response) {
        let sceneArray = response.data.value || response.data;
        _this.setSceneArray(sceneArray, true)
        _this.$refs.sceneGrid.setSelectedScenes(_this.collection.models)

        _this.modelListLoadingInProcess = false;
        _this.showModelList = true;
      }).catch(function (error) {
        _this.showModelList = true;
        _this.modelListLoadingInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    },
    setSceneArray(sceneArray, selectable) {
      this.$refs.sceneGrid.configure(sceneArray, false)
      this.$refs.sceneGrid.selectable = selectable
    },
    configure(collection) {
      this.configuring = true;

      let sceneArray = collection.models;
      this.setSceneArray(sceneArray, false)

      let locale = this.getCollectionLocale(collection)
      this.name = locale.name
      this.isPublic = collection.public
      this.isSystemCollection = collection.systemName && collection.systemName.length > 0
      this.collection = collection

      let _this = this
      this.$nextTick(() => {
        _this.configuring = false;
      });
    },
    reloadSceneList() {
      this.showModelList = false;
      this.modelListLoadingInProcess = true;

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/group/content", { params: { hash: this.collectionHash }, headers: headers }).then(function (response) {
        let collection = response.data
        _this.configure(collection)

        _this.modelListLoadingInProcess = false;
        _this.showModelList = true;
      }).catch(function (error) {
        _this.showModelList = true;
        _this.modelListLoadingInProcess = false;
        _this.dismissErrorMessageCountDown = 5;
        _this.errorMessage = error.response ? (error.response.data.cause || error.message) : "Unknown error";
        console.log("error", error);
      });
    }
  },
  watch: {
    name(newValue, oldValue) {
      if (this.configuring) return;

      this.editsArePresent = true
    },
    isPublic(newValue, oldValue) {
      if (this.configuring) return;

      this.editsArePresent = true
    }
  }
}
</script>

<style lang="scss">
.edit-collection-models-header {
  font-weight: 500;
  font-size: 20px;
  position: relative;
}

.edit-collection-models-header-separator {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
}

.edit-collection-button {
  min-width: 90px;
  height: 34px;
  background-color: #5ab5ba !important;
  border-radius: 17px !important;
  color: white !important;
  padding: 4px 15px !important;
  font-weight: 600 !important;
  display: inline-block;
  font-size: 18px !important;
  border: none !important;

  margin-left: 10px !important;

  @media (max-width: $mobile-width-only) {
    margin-left: 0px !important;
  }
}

#collection-checkbox-public-container {
  margin-bottom: 15px;
}

.edit-collection-button-container {
  display: flex;
  flex-direction: row;

  @media (min-width: $tablet-width) {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
  }

  @media (max-width: $mobile-width-only) {
    column-gap: 5px;
  }
}

.collection-header-container {
  position: relative;
}

#edit-collection-button-edit {
  background-color: #5ab5ba !important;
}

#edit-collection-button-delete {
  background-color: #d82d2d !important;
}

.edit-collection-name-input {
  font-family: Helvetica, sans serif;
}
</style>
