<template>
  <main id="profile-wrapper" class="in-container">
    <div class="website-page">
      <div class="promo__bg"></div>
      <div id="profile-container">
        <img id="profile-avatar" onerror="this.onerror=null; this.src='/img/avatar_placeholder.svg'" src="./../assets/avatar_placeholder.svg">
        <div id='profile-info-container'>
          <div id="profile-info-name"></div>
          <a id="profile-info-username"></a>
          <div id="profile-info-about" v-html="aboutRaw" v-linkified></div>
        </div>
      </div>

      <TabSwitch id="profile-tab-switch" ref="tabSwitch"></TabSwitch>

      <div id="profile-models-spinner-container">
        <b-spinner id="profile-models-spinner" v-if="modelListLoadingInProcess" variant="dark"></b-spinner>
      </div>

      <div v-show="showModelList" class="profile-scenes-container">
        <SceneGrid  ref="scenesGrid"></SceneGrid>
      </div>

      <div v-show="showCollectionList" class="profile-collection-container">
        <CollectionGrid ref="collectionGrid"></CollectionGrid>
      </div>

      <div class="collection-contents-container" v-show="showCollection">
        <div ref="collectionContentsHeader" class="collection-contents-header" ></div>
        <SceneGrid ref="collectionContentsGrid"></SceneGrid>
      </div>
    </div>
  </main>
</template>

<script>

import CollectionGrid from './CollectionGrid.vue'
import SceneGrid from './SceneGrid.vue'
import TabSwitch from './TabSwitch.vue'

export default {
  data: function() {
    return {
      modelListLoadingInProcess: false,
      showModelList: false,
      showCollectionList: false,
      showCollection: false,
      aboutRaw: ""
    }
  },
  components: {
    TabSwitch,
    SceneGrid,
    CollectionGrid
  },
  created() {
    document.title = "Architeque"
  },
  mounted() {
    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
    function linkify(inputText) {
      const pattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      let text = inputText.replace(pattern1, '<a href="$1" target="_blank">$1</a>');

      const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      text = text.replace(pattern2, '$1<a href="http://$2" target="_blank">$2</a>');
      return text;
    }

    this.$refs.scenesGrid.noModelsText = "No public models"
    
    this.$refs.tabSwitch.addTab({title: "Collections"})
    this.$refs.tabSwitch.addTab({title: "3D Scenes"})
    this.$refs.tabSwitch.onSelect = this.onSelectTab
    this.$refs.tabSwitch.selectTab(0)

    var profileAboutElement = document.getElementById('profile-info-about');
    var profileNameElement = document.getElementById('profile-info-name'); 
    var profileUserNameElement = document.getElementById('profile-info-username'); 
    var profileAvatarElement = document.getElementById('profile-avatar');
    var profileModelsListElement = document.getElementById('profile-models-list-container');
    var profileWrapper = document.getElementById('profile-wrapper');

    var path = this.$router.currentRoute.path;
    const username = path.substring(path.lastIndexOf('/') + 1)

    let _this = this;
    let headers = this.getCommonHeaders();

    this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/profile/info", { params: {"username": username}, headers: headers}).then(function(response) {
      profileUserNameElement.src = _this.getURLForProfile(response.data.username);

      // update avatar
      _this.setupAvatarImage(profileAvatarElement, response.data)
      profileNameElement.innerHTML = response.data.name;
      profileUserNameElement.innerHTML = "@" + response.data.username;
      _this.aboutRaw = _this.fixDescriptionStringForHTML(response.data.about);

      if (response.data.about.length > 0) {
        profileNameElement.style["top-margin"] = "50px";
      }

      document.title = response.data.name + " - Architeque";
    }).catch(function (error) {
      _this.$router.push({name: 'NotFound'});
      console.log("error", error);
    });

    this.$refs.collectionGrid.setOnSelect(this.onSelectCollection)
    this.reloadCollections(true)
  },
  methods: {
    onSelectCollection(item) {
      this.showCollectionContents(item.collection)
    },
    showCollectionContents(collection) {
      this.showModelList = false;
      this.showCollectionList = false;
      this.showCollection = true

      let locale = this.getCollectionLocale(collection)
      this.$refs.collectionContentsHeader.innerHTML = locale.name
      this.$refs.collectionContentsGrid.configure(collection.models, true)
    },
    reloadScenes() {
      this.showModelList = false;
      this.showCollectionList = false;
      this.showCollection = false

      let _this = this;
      var path = this.$router.currentRoute.path;
      const username = path.substring(path.lastIndexOf('/') + 1)

      _this.modelListLoadingInProcess = true;

      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/profile/publicScenes", { params: {"username": username}, headers: {}}).then(function(response) {
        _this.modelListLoadingInProcess = false;
        _this.showModelList = true;
        _this.$refs.scenesGrid.configure(response.data, true)
      }).catch(function (error) {
        _this.showModelList = true;
        _this.modelListLoadingInProcess = false;
        console.log("error", error);
      });
    },
    reloadCollections(switchToModelsIfNoCollections) {
      this.showModelList = false;
      this.showCollectionList = false;
      this.showCollection = false

      let _this = this;
      var path = this.$router.currentRoute.path;
      const username = path.substring(path.lastIndexOf('/') + 1)

      _this.modelListLoadingInProcess = true;

      this.axios.get(process.env.VUE_APP_API_BASEURL + "/api/v1/profile/publicCollections", { params: {"username": username}, headers: {}}).then(function(response) {
        if (switchToModelsIfNoCollections && response.data.length == 0) {
          _this.$refs.tabSwitch.selectTab(1)
          _this.reloadScenes()
        } else {
          _this.modelListLoadingInProcess = false;
          _this.showCollectionList = true;
          _this.$refs.collectionGrid.configure(response.data, true)
        }
      }).catch(function (error) {
        _this.showModelList = true;
        _this.modelListLoadingInProcess = false;
        console.log("error", error);
      });
    },
    onSelectTab(index) {
      if (index == 0) {
        this.reloadCollections(false)
      } else if (index == 1) {
        this.reloadScenes()
      }
    }
  }
}
</script>

<style lang="scss">
  #profile-models-spinner {
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    margin-top: 30px;
  }
  #model-item-template {
    display: none;
  }
  #profile-models-list-container-empty {
    text-align: center;
    display: block;
    font-size: 18px;
    line-height: 100px;
    font-weight: 400;
  }
  #profile-models-list-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 20px;
    justify-content: center;
  }
  #profile-wrapper {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    max-width: 1000px !important;
  }
  #profile-info-container {
    margin-left: 20px;
    margin-top: 10px;
    @media (max-width: $mobile-width-only) {
      margin-left: 10px;
      margin-top: 0px;
    }
  }
  #profile-avatar {
    width: 120px;
    height: 120px;
    margin-right: 10px;
    background-color: #f0f0f0;
    border-radius: 60px;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
    @media (max-width: $mobile-width-only) {
      width: 80px;
      height: 80px;
    }
  }
  #profile-models-header {
    font-weight: 500;
    margin-top: 30px;
    font-size: 20px;
    margin-left: 20px;
    @media (max-width: $mobile-width-only) {
      font-size: 16px;
    }
  }

  #profile-models-header-separator {
    height: 1px;
    background-color: #e3e3e3;
    margin-left: 15px;
    margin-right: 15px;
  }
  #profile-container {
    display: flex;
    margin-left: 15px;
  }
  #profile-info-about {
    padding-top: 10px;
    text-align: justify;
    line-height: 1.4;
    font-size: 16px;
    @media (max-width: $mobile-width-only) {
      font-size: 10pt;
    }
  }
  #profile-info-name {
    font-size: 16pt;
    font-weight: 500;
    line-height: 1.3;
    color: black !important;
    @media (max-width: $mobile-width-only) {
      font-size: 12pt;
    }
  }
  #profile-info-username {
    font-size: 18px;
    @media (max-width: $mobile-width-only) {
      font-size: 14px;
    }
  }
  #profile-info-description {
    font-size: 10pt;
    color: white !important;
    @media (max-width: $mobile-width-only) {
      font-size: 8pt;
    }
  }
  .model-list-fade-enter-active, .model-list-fade-leave-active {
    transition: opacity .5s;
  }
  .model-list-fade-enter, .model-list-fade-leave-to {
    opacity: 0;
  }

.profile-tab-switch-container {
  border-bottom: 1px solid #bcbcbc;
  margin-bottom: 15px;  
  margin-top: 10px;
  position: relative;
}

.profile-switch-item {
  display: inline-block;

  font-size: 20px;
  font-weight: 500;
  padding: 0px 10px;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $mobile-width-only) {
    font-size: 14px !important;
  }
}

.profile-switch-item-off {
  
}
.profile-switch-item-item-off:hover {
  border-bottom: 4px solid #a8d64c44;
}
.profile-switch-item-item-on {
  border-bottom: 4px solid #a8d64c;
}
#profile-tab-switch {
  margin-top: 25px;
}
.collection-contents-header {
  font-weight: 600;
  font-size: 26px;
  padding-left: 5px;
  margin-bottom: 10px;
}
.profile-collection-container {
  position: relative;
  min-height: 200px;
}
.profile-scenes-container {
  position: relative;
  min-height: 200px;
}
</style>