<template>
	<div class="editor-property-container">
		<button @click="onClick()" class="editor-default-btn editor-property-button">{{titleText}}</button>
    </div>
</template>

<script>

export default {
	props: {
		titleText: { type: String, default: "" }
	},
	data: function() {
		return {
			value: "",
			onClickCallback: null
		}
	},
	components: {

	},
	created() {
		
	},
	mounted() {

	},
	methods: {
		onClick() {
			if (this.onClickCallback) {
				this.onClickCallback();
			}
		}
	},
	watch: {
	}
}

</script>

<style lang="scss">
.editor-property-button {
	width: 100% !important;
	margin: 5px 0px 0px 0px !important;
}

</style>
