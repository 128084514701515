<template>
	<div class="editor-property-container" v-b-tooltip.hover.left.html.window="tooltip">
		<div v-show="headerTitle.length > 0" ref="header" class="editor-property-header">{{headerTitle}}</div>
		<div ref="items">

		</div>
		<EditorPropertyButton class="editor-property-multiple-add-button" :titleText="addButtonTitle" ref="addButton"></EditorPropertyButton>
    </div>
</template>

<script>

import SceneMaterials from './SceneMaterials.vue'
import EditorPropertyButton from './EditorPropertyButton.vue'
import EditorSmallCloseButton from './EditorSmallCloseButton.vue'
import Vue from 'vue'

import { nextTick } from 'vue'

export default {
	props: {
	},
	data: function() {
		return {
			valueArray: [],
			headerTitle: "",
			key: "",
			onChange: null,
			ignorePropertyChange: false,
			entryItems: [],
			idMax: 0,
			tooltip: "",
			addButtonTitle: "Add"
		}
	},
	components: {
		EditorPropertyButton
	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		configure(valueArray, itemConfig) {
			this.itemConfig = itemConfig

			this.ignorePropertyChange = true
			this.$refs.items.innerHTML = ""
			this.$refs.addButton.onClickCallback = this.onClickAdd
			
			if (Object.hasOwn(itemConfig, "addButtonTitle")) {
				this.addButtonTitle = itemConfig.addButtonTitle
			}

			for (var i in valueArray) {
				var entry = valueArray[i]
				this.addEntry(entry)
			}

			let _this = this
			this.$nextTick(() => {
				_this.ignorePropertyChange = false
			});
		},
		addEntry(entry) {
			if (!entry) {
				entry = {}
			}
			this.valueArray.push(entry)

			var propertyConfigValues = []

			var optionIndex = this.entryItems.length

			var config = {}
			Object.assign(config, this.itemConfig)
			config.key = (this.valueArray.length - 1).toString()
			config.multiple = false
			if (this.itemConfig.optionTitleCallback) {
				config.title = this.itemConfig.optionTitleCallback(optionIndex)
			} else {
				config.title = ""
			}
			
			config.tooltip = ""
			config.index = optionIndex
			propertyConfigValues.push(config)

			var items = this.$editor.addProperties(propertyConfigValues, entry, this.$refs.items, null, this.onChangeProperty, null, this.getPropertyValue, null)

			// only one item should be added
			var firstItem 
			if (items && (items instanceof Array) && items.length == 1) {
				firstItem = items[0]
			}
			if (!firstItem) return;

			// add close button
			var ComponentClass = Vue.extend(EditorSmallCloseButton)
			var closeButtonItem = new ComponentClass()
			closeButtonItem.$mount() // pass nothing
			closeButtonItem.entry = entry
			closeButtonItem.$el.className += " editor-multiple-remove-entry-button";
			closeButtonItem.onClick = this.onClickRemove

			firstItem.$el.appendChild(closeButtonItem.$el)
			this.idMax++
			firstItem.itemId = this.idMax
			closeButtonItem.itemId = this.idMax

			this.entryItems.push(firstItem)
			this.emitChange(true)
		},
		onClickRemove(buttonItem) {
			var itemToDelete = null
			for (var i in this.entryItems) {
				var item = this.entryItems[i]
				if (item.itemId == buttonItem.itemId) {
					itemToDelete = item
					break;
				}
			}
			if (!itemToDelete) return // oh no

			var entryIndex = this.entryItems.indexOf(itemToDelete)
			if (entryIndex == -1) return // oh no

			itemToDelete.$el.parentNode.removeChild(itemToDelete.$el);

			this.valueArray.splice(entryIndex, 1);
			this.entryItems.splice(entryIndex, 1)

			this.emitChange(true)
		},
		emitChange(resetEditor) {
			if (this.ignorePropertyChange) return;

			if (this.onChange) this.onChange(this.valueArray, this.key, {resetEditor: resetEditor})
		},
		onClickAdd() {
			this.addEntry({})
		},
		getPropertyValue(key, type) {
			return this.valueArray[Number(key)]
		},
		onChangeProperty(value, key, customOptions) {
			if (this.ignorePropertyChange) return;
			
			this.valueArray[Number(key)] = value

			var resetEditor = customOptions && Object.hasOwn(customOptions, "resetEditor") && customOptions.resetEditor;
			this.emitChange(resetEditor)
		},
	},
	watch: {

	}
}

</script>

<style lang="scss">

.editor-multiple-remove-entry-button {
	position: absolute;
	width: 24px;
	height: 24px;
	right: 10px;
	top: 10px;
}
.editor-property-multiple-add-button {
	margin-bottom: 5px;
}

</style>
