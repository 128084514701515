<template>
  <div id="public-info" class="scene-public-info">
    <div class='editor-property-header'>Preview image (JPEG, 3MB max, format 16:9)</div>
    <div id="editor-side-panel-preview">
      <img id="editor-side-panel-preview-img" onerror="this.onerror=null; this.src='/img/placeholder_16_9.jpg'"
        src="/img/placeholder_16_9.jpg" />
      <div id="editor-side-panel-preview-active-area" @mouseover="hoverPreview()" @mouseleave="leavePreview()"
        @click="onClickPreview()"></div>
      <transition name="fast-transition">
        <div v-show="showUploadPreviewIcon" id="editor-side-panel-preview-img-overlay-container">
          <img id="editor-side-panel-preview-img-overlay" src="../../assets/edit.svg" />
        </div>
      </transition>
    </div>

    <button @click="makeSnapshot()" :disabled="isMakingSnapshot" class="editor-secondary-button" id="make-snapshot-btn">
      <span v-if="!isMakingSnapshot">Make snapshot</span>
      <b-spinner v-if="isMakingSnapshot" variant="dark"></b-spinner>
    </button>

    <div id="editor-scene-subheader-name" class='editor-property-header'>Name</div>
    <b-form-input id="editor-scene-name" class="editor-single-line-input" v-model="name" maxlength=64></b-form-input>

    <div class='editor-property-header'>Description</div>
    <b-form-textarea class="editor-multiline-input" id="editor-scene-description" v-model="description" :rows="6"
      :max-rows="6" maxlength=1024></b-form-textarea>

    <div class='editor-property-header'>Keywords</div>
    <b-form-input id="editor-scene-keywords" class="editor-single-line-input" v-model="keywords"
      maxlength=64></b-form-input>

    <hr>

    <div id="scene-checkbox-real-scale-container" class="scene-checkbox-container">
      <b-form-checkbox id="scene-checkbox-real-scale" v-model="realScale">1:1 Scale</b-form-checkbox>
    </div>
    <div id="scene-checkbox-available-by-link-container" class="scene-checkbox-container">
      <b-form-checkbox id="scene-checkbox-available-by-link" v-model="availableByLink">Available by QR or
        link</b-form-checkbox>
    </div>
    <div id="scene-checkbox-private-container" class="scene-checkbox-container">
      <b-form-checkbox :disabled="!enablePublic" id="scene-checkbox-private"
        v-model="sceneIsPrivate">Private</b-form-checkbox>
    </div>

    <b-tooltip boundary="window" triggers='hover' target="scene-checkbox-real-scale-container" placement="left">
      Turn on if your model can be resized to a real scale in Augment Reality mode
    </b-tooltip>
    <b-tooltip boundary="window" triggers='hover' target="scene-checkbox-available-by-link-container" placement="left">
      If on, the model will be accessible by link or QR code
    </b-tooltip>
    <b-tooltip boundary="window" triggers='hover' target="scene-checkbox-private-container" placement="left">
      Private models don't appear in public lists and searches, but still can be accessible by link or QR code if enabled
      <div v-show="!enablePublic" class="editor-text-tooltip-warning">
        <br>
        Model isn't part of a subscription bundle that allows publishing
      </div>
    </b-tooltip>
    <b-tooltip boundary="window" triggers='hover' target="make-snapshot-btn" placement="left">
      Make snapshot of the scene and use it as a preview picture
    </b-tooltip>
    <b-tooltip boundary="window" triggers='hover' target="editor-scene-keywords" placement="left">
      Include one or more keywords (separated with commas) that best describe your model. Keywords will help users find
      your model more easily
    </b-tooltip>

  </div>
</template>

<script>

let info;

import { nextTick } from 'vue';
import { MODEL_FLAG } from "../../const";

export default {
  data: function () {
    return {
      realScale: false,
      availableByLink: false,
      sceneIsPrivate: false,
      name: null,
      description: null,
      initIsDone: false,
      isMakingSnapshot: false,
      showUploadPreviewIcon: false,
      enablePublic: true,
      keywords: ""
    }
  },
  mounted() {

  },
  methods: {
    hoverPreview() {
      this.showUploadPreviewIcon = true;
    },
    leavePreview() {
      this.showUploadPreviewIcon = false;
    },
    onClickPreview() {
      this.showUploadPreviewIcon = false;

      var input = this.$editor.$refs.fakeFileInput;
      input.type = 'file';
      input.accept = "image/jpeg";

      var _this = this;
      input.onchange = e => {
        let file = e.target.files[0];

        var formData = new FormData();
        formData.append('file', file);

        let fileName = file.name;
        let filePath = "/" + fileName
        let destinationDir = "/snapshot"
        var rmdirResponse = _this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["rmdir", destinationDir]);
        var mkdirResult = _this.$editor.fs.mkdir(destinationDir)

        var params = {}
        params["imagePath"] = filePath
        params["destinationDir"] = destinationDir

        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (evt) {
          let data = new Uint8Array(evt.target.result);

          // Store the file
          let stream = _this.$editor.fs.open(filePath, 'w+');
          _this.$editor.fs.write(stream, data, 0, data.length, 0);
          _this.$editor.fs.close(stream);

          var response
          try {
            response = _this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["preparePreviewImage", JSON.stringify(params)]);
            response = eval("(" + response + ")");
          } catch (exceptionVar) {
            response = { error: "Failed to load image" }
          }

          if (response && Object.hasOwn(response, "error") && response.error.length > 0) {
            _this.$editor.showMessage(response["error"], true)
          } else {
            _this.$editor.onDoneMakingSnapshot();
          }
        }
        reader.onerror = function (evt) {
          _this.$editor.showMessage("Failed to read file", true)
        }
        reader.onprogress = function (data) {
          _this.$editor.setProgress(data.loaded / data.total);
        }
      }

      input.click();
    },
    setSceneInfo(data) {
      let _this = this;

      var locale = null;
      for (let i = 0; i < data.locales.length; i++) {
        var l = data.locales[i];
        if (l.cultureId == 1/*en*/) {
          locale = l;
          break;
        }
      }

      // load preview image
      this.downloadScenePreview()
      this.$editor.modelId = data.id;
      this.keywords = data.keywords;
      this.name = locale.name;
      this.description = locale.description;

      this.realScale = data.flags.includes(MODEL_FLAG.HAS_ONE_TO_ONE_SCALE);
      this.availableByLink = data.flags.includes(MODEL_FLAG.IS_AVAILABLE_BY_QR_AND_LINK);
      this.sceneIsPrivate = data.flags.includes(MODEL_FLAG.IS_PRIVATE);

      if (this.sceneIsPrivate) {
        this.enablePublic = data.enablePublic
      } else {
        this.enablePublic = true
      }

      nextTick(() => {
        _this.initIsDone = true;
      })
    },
    onDoneMakingSnapshot() {
      this.isMakingSnapshot = false;
    },
    makeSnapshot() {
      this.isMakingSnapshot = true;
      this.$editor.makeSnapshot(null);
    },
    downloadScenePreview() {
      let rand = this.randomString()
      let sceneHash = this.$editor.getSceneHash();
      var previewImageElement = document.getElementById('editor-side-panel-preview-img');
      previewImageElement.src = process.env.VUE_APP_API_BASEURL + "/api/v1/files/imageByModelHash?modelHash=" + sceneHash + "&fileName=photo_medium.jpeg" + "&rand=" + rand
    }
  },
  watch: {
    realScale(newValue, oldValue) {
      this.$editor.onChangeIsMade(newValue, oldValue, this, false/*scene*/, true/*info*/)
    },
    availableByLink(newValue, oldValue) {
      this.$editor.onChangeIsMade(newValue, oldValue, this, false/*scene*/, true/*info*/)
      this.$editor.onChangeProperty("availableByLink", newValue)
    },
    sceneIsPrivate(newValue, oldValue) {
      this.$editor.onChangeIsMade(newValue, oldValue, this, false/*scene*/, true/*info*/)
      this.$editor.onChangeProperty("sceneIsPrivate", newValue)
    },
    name(newValue, oldValue) {
      this.$editor.onChangeIsMade(newValue, oldValue, this, false/*scene*/, true/*info*/)
    },
    description(newValue, oldValue) {
      this.$editor.onChangeIsMade(newValue, oldValue, this, false/*scene*/, true/*info*/)
    },
    keywords(newValue, oldValue) {
      this.$editor.onChangeIsMade(newValue, oldValue, this, false/*scene*/, true/*info*/)
    }
  }
}
</script>

<style lang="scss">
.scene-public-info {
  padding: 0px 10px 0px 10px;
  overflow-x: hidden;
  overflow: auto;
  height: 100%;
}

.editor-multiline-input {
  line-height: 18px !important;
  font-size: 15px !important;
  resize: none !important;
  padding-left: 7px !important;
  border-radius: 5px !important;
}

.editor-multiline-input:hover {
  background-color: #fafafa !important;
}

.editor-single-line-input {
  height: 30px !important;
  font-size: 15px !important;
  padding-left: 7px !important;
  border-radius: 5px !important;
  position: relative;
}

.editor-single-line-input:hover {
  background-color: #fafafa !important;
}

#editor-side-panel-preview-img {
  border-radius: 4px;
  cursor: pointer;
}

#editor-side-panel-preview {
  aspect-ratio: 16/9;
  position: relative;
}

.custom-control-label {
  font-weight: 400;
  padding-left: 8px;
  padding-top: 2px;
  cursor: pointer;
}

.custom-control-label::before {
  top: 0.15rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 5px !important;
  background-color: #e1e1e1;
  border: 1px solid #00000021 !important;
}

.custom-control-label:hover:enabled::before {
  background-color: #f0f0f0 !important;
}

.custom-control-input:checked~.custom-control-label:hover:enabled::before {
  background-color: #75b6ba !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border: none !important;
}

.custom-control-label::after {
  top: 0.15rem !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.tooltip {
  border-radius: 5px;
  background-color: black !important;
  color: white;
  padding: 8px 10px;
  margin: 5px;
  max-width: 280px;
  line-height: 20px;
  z-index: 10;
}

#make-snapshot-btn {
  margin-top: 5px !important;
  width: 100%;
}

#editor-scene-subheader-name {
  margin-top: 7px;
}

#editor-side-panel-preview-img-overlay-container {
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.4);
}

#editor-side-panel-preview-img-overlay {
  width: 100%;
  height: 100%;
  padding: 20%;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .3));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .3));
}

#editor-side-panel-preview-active-area {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#editor-scene-description {
  font-family: Helvetica, sans serif;
}

#editor-scene-name {
  font-family: Helvetica, sans serif;
}

.scene-checkbox-container {
  margin-bottom: 5px;
}

.scene-public-info hr {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

</style>