<template>
  <b-row>
    <b-col>
      <div class="store-container">
        <div class="account-header-container">
          <div class="account-card-header">{{ $t('store.title') }}</div>

          <div class="store-country-container">
            <div>{{$t('store.country')}}:</div>
            <NiceSelector class="store-country-selector" v-show="showCountrySelector" ref="countrySelector">
            </NiceSelector>
          </div>
        </div>

        <div class="account-content-container">
          <div ref="emptyLabel" v-show="showEmptyText && !isLoadingSubscriptions"
            class="store-active-subscriptions-empty"></div>
          <div class="account-spinner-container">
            <b-spinner v-if="isLoadingSubscriptions" variant="dark"></b-spinner>
          </div>

          <div class="store-contents">
            <div class="store-bundle-items" ref="currentItemsContainer"></div>

            <div class="store-description">
              <div class="store-description-header">{{ $t('store.description.header') }}</div>
              <div class="store-description-list">
                <div v-html="$t('store.description.text1_1')"></div>
                <div v-html="$t('store.description.text1_2')"></div>
              </div>
              <br>
              <div class="store-description-subheader">{{ $t('store.description.header1') }}</div>
              <div class="store-description-list">
                <div v-html="$t('store.description.text2_1')"></div>
                <div v-html="$t('store.description.text2_2')"></div>
                <div v-html="$t('store.description.text2_3')"></div>
                <div v-html="$t('store.description.text2_4')"></div>
              </div>
              <br>
              <div class="store-description-subheader">{{ $t('store.description.header2') }}</div>
              <div class="store-description-list">
                <div v-html="$t('store.description.text3_1')"></div>
                <div v-html="$t('store.description.text3_2')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import Vue from 'vue'
import StoreBundleItem from './StoreBundleItem.vue'
import NiceSelector from './NiceSelector.vue'

export default {
  data: function () {
    return {
      showEmptyText: false,
      isLoadingSubscriptions: false,
      country: "default",
      showCountrySelector: true,
      items: []
    }
  },
  components: {
    NiceSelector
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["store"];
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["store"];
  },
  mounted() {
    this.setupCalculator()

    let countrySelectorConfig = []
    countrySelectorConfig.push({ value: "default", title: "Default", additionalTitle: "", additionalTitleColor: "" })
    countrySelectorConfig.push({ value: "ru", title: "Russia", additionalTitle: "", additionalTitleColor: "" })

    var userLang = (navigator.language || navigator.userLanguage).substring(0, 2);
    if (userLang == "ru") {
      this.country = "ru"
    }
    this.$root.country = this.country

    this.$refs.countrySelector.configure(countrySelectorConfig, null)
    this.$refs.countrySelector.setSelected(this.country)
    this.$refs.countrySelector.changeCallback = this.onChangeCountry
    this.$refs.countrySelector.setShowOnLeft()
  },
  methods: {
    loc(key) {
      return this.$t(key)
    },
    setCountry(value) {
      this.country = value
      this.$root.country = value

      for (let itemIndex in this.items) {
        let item = this.items[itemIndex]
        item.setEnableAutorenewal(value == "default")
      }
    },
    onChangeCountry(value) {
      this.setCountry(value)
    },
    createPriceCalculatorItem() {
      var ComponentClass = Vue.extend(StoreBundleItem)
      var item = new ComponentClass()
      item.loc = this.loc
      item.$mount() // pass nothing
      item.$root = this.$root // I don't know why $root doesn't match this one
      item.$store = this.$store // I don't know why $root doesn't match this one
      item.getCommonHeaders = this.getCommonHeaders
      item.title = this.$t('price-calculator.title')
      item.purchaseTitle = this.$t('price-calculator.purchase-button');

      return item
    },
    createProductItem(product) {
      var ComponentClass = Vue.extend(StoreBundleItem)
      var item = new ComponentClass()
      item.loc = this.loc
      item.$mount() // pass nothing
      item.$root = this.$root // I don't know why $root doesn't match this one
      item.$store = this.$store // I don't know why $root doesn't match this one
      item.getCommonHeaders = this.getCommonHeaders
      item.purchaseTitle = this.$t('price-calculator.purchase-button');
      item.configureAsProduct(product)
      item.collapsable = false
      item.editableOptions = false

      return item
    },
    setupCalculator() {
      this.items = []

      let item = this.createPriceCalculatorItem()
      this.$refs.currentItemsContainer.appendChild(item.$el)
      this.items.push(item);

      this.setCountry(this.country)
    },
    configureStoreItems(productArray) {
      /*
      for (let productIndex in productArray) {
        let product = productArray[productIndex]
        if (product.name == "default") continue

        let item = this.createProductItem(product)
        this.$refs.currentItemsContainer.appendChild(item.$el)

        this.items.push(item);
      }
      */
    },
    /*
    configureAsStore() {
      this.$refs.currentItemsContainer.innerHTML = ""
      this.isLoadingSubscriptions = true
      this.showEmptyText = false

      let _this = this;
      let headers = this.getCommonHeaders();
      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/billing/subscription/products", {}, { headers: headers }).then(function (response) {
        let productArray = response.data
        _this.configureStoreItems(productArray)
        _this.isLoadingSubscriptions = false
      }).catch(function (error) {
        console.log("error", error);
        _this.isLoadingSubscriptions = false
      });
    }*/
  }
}  
</script>

<style>
.store-current-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
  justify-content: center;
}

/* .store-price-calculator {
  width: 300px;
  min-height: 200px;
} */

.store-active-subscriptions-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.account-card-subheader {
  font-size: 20px;
  padding-left: 0px;
  padding-bottom: 2px;
}

.store-country-selector {}

.store-country-container {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0px, -50%);
  display: flex;
  gap: 10px;
  align-items: center;
}

.store-contents {
  display: flex;
  gap: 20px;
}

.store-description-header {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}

.store-description-subheader {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.store-description-list {
  line-height: 30px;
}
</style>