<template>
	<div class="editor-selector-container">
		<button @click="toggleMenu()" ref="editorSelectorContainer" class="editor-selector-button">
			<span class="editor-selector-current-value-label" ref="currentValueLabel"></span>
			<img class="editor-selector-container-dropdown" src="~@/assets/dropdown.svg" />
		</button>

		<EditorDropDownMenu ref="dropDownMenu"></EditorDropDownMenu>
	</div>
</template>

<script>

import EditorDropDownMenu from './EditorDropDownMenu.vue'

export default {
	prop: ['value'],
	props: {
		values: {
			default() {
				return []
			},
			type: Array
		},
		titles: {
			default() {
				return []
			},
			type: Array
		},
		tooltips: {
			default() {
				return []
			},
			type: Array
		},
		value: {
			default: null,
			type: null
		},
		changeCallback: {
			default: null,
			type: Function
		}
	},
	data: function () {
		return {
			isOptionsVisible: false
		}
	},
	components: {
		EditorDropDownMenu
	},
	mounted() {
		let _this = this
		this.$nextTick(() => {
			if (_this.values.length > 0 && _this.titles.length > 0) {
				_this.configure(_this.values, _this.titles, _this.tooltips)
			}
			
			if (_this.value != null) _this.setSelected(_this.value)
			_this.$refs.dropDownMenu.changeCallback = _this.onChangeSelection
		});
	},
	methods: {
		onChangeSelection(val) {
			this.value = val
		},
		updateSelectedValueLabel() {
			for (var index in this.$refs.dropDownMenu.values) {
				if (this.$refs.dropDownMenu.values[index] === this.value) {
					this.$refs.currentValueLabel.innerHTML = this.$refs.dropDownMenu.strings[index]
					break
				}
			}
		},
		setSelected(value) {
			this.value = value;
			this.updateSelectedValueLabel();
		},
		configure(values, titles, tooltips) {
			this.$refs.dropDownMenu.configure(values, titles, tooltips)
		},
		isElementClosest(element, wrapper) {
			while (element !== document && element !== null) {
				if (element === wrapper) return true;
				element = element.parentNode;
			}

			return false;
		},
		toggleMenu() {
			this.$refs.dropDownMenu.visible = !this.$refs.dropDownMenu.visible
		}
	},
	watch: {
		value(val, old) {
			this.$refs.dropDownMenu.value = val
			this.updateSelectedValueLabel()
			if (this.changeCallback) {
				this.changeCallback(val)
			}
			this.$emit('input', val)
		}
	}
}
</script>


<style lang="scss">
.editor-selector-container {
	position: relative;
}

.editor-selector-button {
	background-color: #ffffff;
	border-radius: 5px !important;
	position: relative;
	border: 1px solid #b8b8b8;
	border-radius: 3px;
	height: 35px;
	width: 100%;
	position: relative;
	padding: 0px;
}

.editor-selector-button:hover {
	background-color: #f5f5f5;
}

.editor-selector-current-value-label {
	width: 100%;
	display: inline-block;
	text-align: left;
	padding-left: 10px;
}
</style>