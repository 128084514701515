<template>
	<div class="nice-selector-container">
		<button @click="toggleMenu()" ref="niceSelectorContainer" class="nice-selector-button">
			<span class="nice-selector-current-value-label" ref="currentValueLabel"></span>
			<img class="nice-selector-container-dropdown" src="~@/assets/dropdown.svg" />
		</button>
		
		<NiceSelectorDropDownMenu ref="dropDownMenu"></NiceSelectorDropDownMenu>
	</div>
</template>

<script>

import NiceSelectorDropDownMenu from './NiceSelectorDropDownMenu.vue'

export default {
	data: function() {
		return {
			isOptionsVisible: false,
			changeCallback: null,
			value: null
		}
	},
	components: {
		NiceSelectorDropDownMenu
	},
	mounted() {
		this.$refs.dropDownMenu.changeCallback = this.onChangeSelection
	},
	methods: {
		setShowOnLeft() {
			this.$refs.dropDownMenu.setShowOnLeft()
		},
		onChangeSelection(val) {
			this.value = val
		},
		updateSelectedValueLabel() {
			let config = this.$refs.dropDownMenu.config
			for (var index in config) {
				let entry = config[index]
				if (entry.value === this.value) {
					this.$refs.currentValueLabel.innerHTML = entry.title
					break
				}
			}
		},
		setSelected(value) {
			this.value = value;
			this.updateSelectedValueLabel();
		},
		configure(config) {
			this.$refs.dropDownMenu.configure(config)
		},
		isElementClosest(element, wrapper) {
			while (element !== document && element !== null) {
				if (element === wrapper) return true;
				element = element.parentNode;
			}
		
			return false;
		},
		toggleMenu () {
			this.$refs.dropDownMenu.visible = !this.$refs.dropDownMenu.visible
		}
	},
	watch: {
		value(val, old) {
			this.$refs.dropDownMenu.value = val
			this.updateSelectedValueLabel()
			if (this.changeCallback) {
				this.changeCallback(val)
			}
		}
	}
}
</script>


<style lang="scss">

.nice-selector-container {
	position: relative;
	height: 40px;
    align-self: center;
}
.nice-selector-button {
	background-color: #efefef;
    border-radius: 13px !important;
    border: 0px;
    height: 100%;
    width: 100%;
    position: relative;
	padding: 0px 24px 0 10px;
	border: 1px solid #e0e0e0;
}
.nice-selector-button:hover {
	background-color: #f5f5f5;
}
.nice-selector-current-value-label {
	width: 100%;
    display: inline-block;
    text-align: left;
	font-weight: 500;
	font-size: 16px;
}

</style>