<template>
  <div class="in-container invitation">
    <div class="invitation__title-container">
      <h2 class="invitation__title highlighted">
        {{ $t("invitation.title") }}
      </h2>
    </div>

    <router-link
      :to="{
        name: 'Explore',
        params: {
          isMapShown: true,
        },
      }"
      class="invitation__show-map"
    >
      <div class="invitation__show-map-text">{{ $t("map.show-map") }}</div>
    </router-link>

    <div v-if="!isDesktop" class="invitation__btn-container">
      <a
        id="footer-btn-ios"
        href="https://apps.apple.com/app/id1447699048"
        class="footer__btn--ios"
        ><img src="./../assets/AppStore.svg"
      /></a>
      <a
        id="footer-btn-android"
        href="https://play.google.com/store/apps/details?id=com.architeque.android.app"
        class="footer__btn--android"
        ><img src="./../assets/GooglePlay.png"
      /></a>
    </div>
    <b-link
      v-if="isDesktop"
      id="invitation-btn-try"
      :to="{
        name: 'Explore',
      }"
      class="button_promo btn"
      >{{ $t("btn-start-searching") }}</b-link
    >
  </div>
</template>

<script>
export default {
  name: "Promo",
  data: function () {
    return {
      isDesktop: false,
    }
  },
  mounted() {
    // Show button depending on context
    var buttoniOS = document.getElementById("footer-btn-ios")
    var buttonAndroid = document.getElementById("footer-btn-android")
    var os = this.getMobileOperatingSystem()

    if (os == "iOS") {
      buttoniOS.hidden = false
    } else if (os == "Android") {
      buttonAndroid.hidden = false
    } else {
      buttoniOS.hidden = false
      buttonAndroid.hidden = false
    }

    this.isDesktop = this.getMobileOperatingSystem() == "unknown"
  },
}
</script>

<style lang="scss">
.invitation {
  padding-top: 140px;
  padding-bottom: 140px;

  text-align: center;
}

.invitation__title-container {
  @extend .title-chapter;
}

.invitation__title {
  display: inline;

  @extend .title-chapter;
}

.invitation__btn-container {
  justify-content: center;
}

.footer__btn--ios {
  height: 60px;
  display: inline-flex;
  margin: 10px;
}

.footer__btn--ios img {
  max-width: none !important;
}

.footer__btn--android {
  height: 60px;
  display: inline-flex;
  margin: 10px;
}

.footer__btn--android img {
  max-width: none !important;
}
#invitation-btn-try {
  margin: auto;
}

.invitation {
  &__show-map {
    width: 100%;
    max-width: 700px;
    position: relative;
    height: 100px;
    background-color: gray !important;
    margin: 0 auto 30px;
    background-image: url("../assets/show_map_preview.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    display: flex !important;
    border-radius: 3px;

    &:hover,
    &:focus {
      text-decoration: none !important;
    }

    &:hover .invitation__show-map-text,
    &:focus .invitation__show-map-text {
      transform: scale(1.1);
    }
    @media (min-width: $tablet-width) {
      margin-top: 30px;
    }
  }

  &__show-map-text {
    margin: auto;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    color: white;
    transition-duration: 300ms;

    @extend .font-main;

    @media (min-width: $tablet-width) {
      font-size: 38px !important;
      line-height: 58px !important;
    }
  }
}
</style>
