<template>
	<div ref="outliner" class="texture-outliner">
		<div id="texture-outliner-background" @click="deselect"></div>
	</div>
</template>

<script>

import TextureItem from './TextureItem.vue'
import SceneAnnotations from './SceneAnnotations.vue'
import Vue from 'vue'

export default {
	data: function() {
		return {
			textureItemList: [],
			textureList: [],
			selectedTextureObject: -1,
			selectedIndex: -1,
			onSelect: null,
			onHighlight: null,
			showEmbedded: false
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		configure(textureList) {
			let _this = this

			var itemList = this.textureItemList
			for (var itemIndex in itemList) {
				var el = itemList[itemIndex]
				if (el == null) continue;
				el.$el.parentNode.removeChild(el.$el);
			}
			
			this.textureItemList = [];
			this.textureList = [];

			var onSelect = function(index) {
				_this.select(index)
			}
			var onHighlight = function(index) {
				_this.highlight(index)
			}

			for (var index in textureList) {
				var texture = textureList[index];

				var ComponentClass = Vue.extend(TextureItem)
				var item = new ComponentClass()

				item.$mount() // pass nothing
				item.configure(texture, index, onSelect, onHighlight)
				this.$refs.outliner.appendChild(item.$el)

				this.textureItemList.push(item)

				if (texture && texture.isEmbedded && !this.showEmbedded) {
					item.show = false
					continue;
				}
			}
			this.textureList = textureList;
		},
		deselect() {
			this.select(-1, false);
		},
		select(index) {
			var newSelectedName;
			var newSelectedTextureObject;

			for (var i in this.textureItemList) {
				var item = this.textureItemList[i]

				item.select(i == index)

				if (i == index) {
					newSelectedTextureObject = this.textureList[i]
				}
			}
			this.selectedIndex = index;
			this.selectedTextureObject = newSelectedTextureObject

			if (this.onSelect) this.onSelect(index)
		},
		highlight(index) {
			for (var i in this.textureItemList) {
				var item = this.textureItemList[i]

				item.highlight(i == index)
			}
			if (this.onHighlight) this.onHighlight(index)
		},
		updateItems() {
			var textureObject = this.textureList[this.selectedIndex];
			for (var key in this.propertyEditItemMap) {
				var item = this.propertyEditItemMap[key]
				item.value = textureObject[key]
			}
		},
		getSelectedTexture() {
			return this.textureList[this.selectedIndex]
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">

.texture-outliner {
	width: 100%;
	height: 100%;
	overflow: auto;
	overflow-x: hidden;
	position: relative;
	background-color: white;
}
</style>
