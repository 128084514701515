<template>
  <b-row>
    <b-col>
      <div class="account-card-header">{{ $t('checkoutStatus.title') }}</div>

      <div class="account-content-container">
        <b-overlay :show="isCheckingPaymentStatus" rounded="sm">

          <b-alert :show="!isCheckingPaymentStatus" :variant="getAlertVariant()" class="text-dark">
            <span v-if="status == 'completed'">{{ $t('checkoutStatus.completed') }}</span>
            <span v-if="status == 'error'"><b>{{ $t('checkoutStatus.error') }}:</b> {{ errorMessage }}</span>

            <router-link :to="{ name: 'MySubscriptions' }" class="float-right text-muted"><small>{{
              $t('checkoutStatus.link') }}</small></router-link>
          </b-alert>
        </b-overlay>
        <div v-show="showWhatsNext" class="checkout-status-whats-next-container">
          
          <div class="checkout-status-whats-next-desc-container">
            <div ref="subscriptionContainer" class="checkout-status-whats-next-subscription"></div>
            <div class="checkout-status-whats-next-description">
              <div class="checkout-status-whats-next-header">{{ $t('checkoutStatus.success-what-next-header') }}</div>
              <div v-html="$t('checkoutStatus.success-what-next1')"></div>
              <br>
              <div v-html="$t('checkoutStatus.success-what-next2')"></div>
              <br>
              <div v-html="$t('checkoutStatus.success-what-next3')"></div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>

export default {
  data: function () {
    return {
      isCheckingPaymentStatus: true,
      status: 'undefined',
      errorMessage: "",
      showWhatsNext: false
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["checkout-status"];
    this.checkStatus();
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["checkout-status"];
  },
  methods: {
    getAlertVariant() {
      if (this.status == "completed") return "success";
      if (this.status == "error") return "danger";
      else return "warning";
    },
    loc(key) {
      return this.$t(key)
    },
    showSubscriptionOnboarding(sub) {
      let item = this.createSubscriptionItem(sub)
      this.$refs.subscriptionContainer.appendChild(item.$el)
    },
    checkStatus() {
      let headers = this.getCommonHeaders();
      let _this = this;
      let params = {}

      if (this.$route.query.session_id && this.$route.query.session_id.length > 0) {
        params["sessionID"] = this.$route.query.session_id 
        params["type"] = "stripe" 
      } else if (this.$route.query.account && this.$route.query.account.length) {
        params["sessionID"] = this.$route.query.account 
        params["type"] = "unitpay"
      }
      this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/billing/subscription/checkSessionStatus", params,
        { headers: headers }).then(function (response) {
          _this.status = response.data.status;
          _this.isCheckingPaymentStatus = false;
          _this.showWhatsNext = _this.status == "completed"
          _this.showSubscriptionOnboarding(response.data.subscription)
        }).catch(function (error) {
          _this.isCheckingPaymentStatus = false;
          _this.status = 'error';
          if (error.response && error.response.data && error.response.data.cause) {
            _this.errorMessage = error.response.data.cause
          }
        });
    }
  }
}  
</script>

<style>
.checkout-status-whats-next-header {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}
.checkout-status-whats-next-desc-container {
  display: flex;
  gap: 20px;
}
.checkout-status-whats-next-description {
  max-width: 400px;
}

</style>