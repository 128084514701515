import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLoggedIn: false,
    isAdmin: false,
    user: null,
    onLogin: null
  },
  mutations: {
    login(state, params) {
      state.isLoggedIn = true
      state.token = params.token
      state.email = params.email
    },
    logout(state) {
      state.isLoggedIn = false
      state.isAdmin = false
      state.token = ""
      state.email = ""
    }
  },
  plugins: [vuexLocal.plugin]
})
export default store
