<template>
  <b-container class="mw-500">
    <div class="promo__bg"></div>
    <b-jumbotron id="change-password-container">
      <form>
        <div class="login_title">{{ $t("change-password.title") }}</div>
        <b-form-input
          class="mt-2 login-input"
          type="password"
          v-bind:placeholder="$t('register.password')"
          v-model="password"
          autocomplete="new-password"
        ></b-form-input>
        <b-form-input
          class="mt-2 login-input"
          type="password"
          v-bind:placeholder="$t('register.retype-password')"
          v-model="passwordConfirm"
          autocomplete="Re-type -password"
        ></b-form-input>
        <b-alert :show="alert.state" :variant="alert.variant" class="mt-2">
          {{ alert.message }}
        </b-alert>
        <b-button
          v-if="!goToLoginState"
          class="login-button"
          block
          variant="primary mt-3"
          :disabled="changePasswordInProcess"
          @click="changePassword()"
        >
          <span id="action-btn-span" class="text-white" v-if="!changePasswordInProcess">{{ $t("change-password.btn-title") }}</span>
          <b-spinner v-if="changePasswordInProcess" variant="light"></b-spinner>
        </b-button>
        <b-button
          v-if="goToLoginState"
          class="login-button"
          block
          variant="primary mt-3"
          @click="$router.push({ name: 'Login' })"
        >
          <span class="text-white" v-if="!changePasswordInProcess">{{ $t("change-password.back-to-login-title") }}</span>
        </b-button>
      </form>
    </b-jumbotron>
  </b-container>
</template>

<script>
import { KeyCodes } from "../const"
export default {
  data() {
    return {
      password: "",
      passwordConfirm: "",
      resetPasswordToken: "",
      alert: {
        state: false,
        message: "",
        variant: "danger",
      },
      changePasswordInProcess: false,
      goToLoginState: false
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["changePassword"]
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["changePassword"]
  },
  mounted() {
    document.getElementById('change-password-container').addEventListener("keydown", this.onChangePasswordEnterClick)
  },
  methods: {
    changePassword() {
      if (this.password.length == 0) {
        this.alert.state = true
        this.alert.variant = "danger"
        this.alert.message = "Password is blank"
        return;
      }
      if (this.passwordConfirm.length == 0) {
        this.alert.state = true
        this.alert.variant = "danger"
        this.alert.message = "Password confirm is blank"
        return;
      }
      this.alert.state = false
      this.alert.message = ""
      this.changePasswordInProcess = true
      
      let _this = this
      this.axios
        .post(
          process.env.VUE_APP_API_BASEURL + "/api/v1/user/security/confirm-reset-pass", { 
            token: this.$route.query.token,
            passwordFirst: this.password, 
            passwordSecond: this.passwordConfirm
          }
        )
        .then(function(response) {
          _this.alert.message = _this.$root.$i18n.messages[_this.$root.$i18n.locale]["change-password"]["success"] 
          _this.alert.variant = "success"
          _this.alert.state = true
          _this.changePasswordInProcess = false
          _this.goToLoginState = true
        })
        .catch(function(error) {
          _this.alert.state = true
          _this.alert.variant = "danger"
          _this.alert.message = error.response && error.response.data && error.response.data.cause && "Unknown error"
          _this.changePasswordInProcess = false
        })
    },
    onChangePasswordEnterClick(evt) {
      if (evt.keyCode === KeyCodes.ENTER) {
        this.changePassword();
      }
    }
  }
}
</script>

<style lang="scss">
.mw-500 {
  @extend .font-main;
}
.login_title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
.login-button {
  font-size: 1.2rem !important;
  height: 40px;
}
#change-password-container {
  margin-top: 50px;
  @media (max-width: $mobile-width-only) {
    margin-top: 0px !important;
  }
}
.login-input {
  margin-bottom: 15px !important;
  height: calc(2em + 0.75rem + 2px) !important;
  border: 1px solid #9ea4ab !important;
}
</style>
