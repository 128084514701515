<template>
    <div class="scene-map">
        <div class='editor-property-header'>Location</div>
        <b-spinner v-if="reviewRequestInProcess" class="search-with-map__models-spinner" variant="dark"></b-spinner>
        <div v-show="!reviewRequestInProcess">
            <div v-show="showChangeLocationButton" class="editor-scene-public-info-location-container">
                <button ref="setLocationButton" @click="onClickSetLocation()" class="icon-label-bordered-button"
                    id="set-location-button">
                    <span ref="currentLocation">Change Location</span>
                    <img ref="currentLocationIcon" src="/img/location_inactive.svg" />
                </button>

                <button v-show="showRemoveLocationButton" id="editor-location-remove-button"
                    class="editor-location-remove-button" @click="onClickRemoveLocation()">
                    <img src="./../../assets/remove-location.svg" />
                </button>
            </div>

            <button v-show="showPublishOnMapButton" ref="publishOnMapButton" @click="onClickPublishOnMap()"
                class="icon-label-bordered-button" id="publish-on-map-button">
                <span ref="publishOnMapButtonLabel">Publish On Map</span>
                <img src="/img/globe.svg" />
            </button>
            <button v-show="showStopReviewButton" ref="publishOnMapButton" @click="onClickStopReview()"
                class="icon-label-bordered-button" id="stop-review-button">
                <span ref="showStopReviewButtonLabel">Remove From Review</span>
                <img src="./../../assets/remove-location.svg" />
            </button>
            <div>
                <div v-show="showLocationPublishHint && !showLocationReviewStatus" class="location-hint">
                    <b>Publishing on Map</b> makes your model visible for all users on the Architeque World Map. <a target="_blank" href="https://ar-chiteque.gitbook.io/docs/publishing-model-on-architeque-map">Read More</a>
                </div>

                <div v-show="showSetLocationHint && !showLocationPublishHint && !showLocationReviewStatus" class="location-hint">
                    Setting up geolocation for the model will add a link to this model on map. Note that it doesn't publish the model on Architeque World Map <a target="_blank" href="https://ar-chiteque.gitbook.io/docs/setting-geolocation-for-a-model">Read More</a>
                </div>

                <div v-show="showLocationReviewStatus" class="location-review-status" ref="locationModerationStatus">
                </div>
            </div>
        </div>

        <b-tooltip boundary="window" triggers='hover' target="set-location-button" placement="left">
            Set model location on map (if applicable)
        </b-tooltip>
        <b-tooltip boundary="window" triggers='hover' target="editor-location-remove-button" placement="left">
            Remove location
        </b-tooltip>
    </div>
</template>

<script>

import { API_URL, LOCATION_MODERATION_STATUS } from "../../const";
import MapSelectLocation from "./../Map/MapSelectLocation.vue";

export default {
    data: function () {
        return {
            latitude: null,
            longitude: null,
            initIsDone: false,
            showChangeLocationButton: true,
            showRemoveLocationButton: false,
            showPublishOnMapButton: false,
            sceneHash: "",
            showStopReviewButton: false,
            reviewRequestInProcess: false,
            showLocationReviewStatus: false,
            showLocationPublishHint: false,
            showSetLocationHint: true
        }
    },
    mounted() {
        this.initIsDone = true
    },
    methods: {
        setCoordinates(lat, lng) {
            this.$editor.onChangeIsMade(0, 1, this, false/*scene*/, true/*info*/)

            this.latitude = lat
            this.longitude = lng

            this.$editor.sceneData.latitude = lat
            this.$editor.sceneData.longitude = lng

            this.setSceneInfo(this.$editor.sceneData)
        },
        setSceneInfo(data) {
            this.latitude = data.latitude
            this.longitude = data.longitude
            this.sceneHash = data.hash

            let moderationStatusText = ""
            if (data.locationModeration == LOCATION_MODERATION_STATUS.PENDING) {
                moderationStatusText = "Location review is currently in process. This usually takes from one to several days. To change the location, you should first remove your model from review and start again"
                this.showLocationReviewStatus = true
                this.showStopReviewButton = true
                this.showPublishOnMapButton = false
                this.showChangeLocationButton = false
            } else if (data.locationModeration == LOCATION_MODERATION_STATUS.FAILED) {
                moderationStatusText = "Review Is Failed: <br><br><i>" + data.locationModerationComment + "</i>"
                this.showLocationReviewStatus = Number(data.latitude) != 0 && Number(data.latitude) != 0
                this.showStopReviewButton = false
                this.showPublishOnMapButton = false
                this.showChangeLocationButton = true
            } else if (data.locationModeration == LOCATION_MODERATION_STATUS.ACCEPTED) {
                moderationStatusText = "Published on map"
                this.showLocationReviewStatus = Number(data.latitude) != 0 && Number(data.latitude) != 0
                this.showStopReviewButton = false
                this.showPublishOnMapButton = false
                this.showChangeLocationButton = true
            } else {
                this.showLocationReviewStatus = false
                this.showStopReviewButton = false
                this.showPublishOnMapButton = Number(data.latitude) != 0 && Number(data.latitude) != 0
                this.showChangeLocationButton = true
            }
            this.showLocationPublishHint = this.showPublishOnMapButton

            if (data.locationModeration == LOCATION_MODERATION_STATUS.FAILED) {
                this.$refs.locationModerationStatus.style['text-align'] = "left"
            } else {
                this.$refs.locationModerationStatus.style['text-align'] = "center"
            }
            this.$refs.locationModerationStatus.innerHTML = moderationStatusText

            this.updateCurrentLocationLabel(data.latitude, data.longitude)
        },
        onClickPublishOnMap() {
            let _this = this;
            this.$root.$emit('showDialog', "Warning", "Publishing model will start review process. Read our guide on publishing on map carefully before doing this", "Cancel", "Send To Review", function () {
                _this.onPublish()
            })
        },
        onClickStopReview() {
            let _this = this;
            this.$root.$emit('showDialog', "Warning", "Are you sure you want to stop location review?", "Cancel", "Stop Review", function () {
                _this.stopLocationReview()
            })
        },
        onPublish() {
            this.reviewRequestInProcess = true;

            let _this = this;
            const headers = this.getCommonHeaders()

            this.axios.get(process.env.VUE_APP_API_BASEURL + API_URL.START_LOCATION_REVIEW,
                {
                    headers: headers,
                    params: {
                        "latitude": Number(this.latitude),
                        "longitude": Number(this.longitude),
                        "modelHash": this.sceneHash
                    }
                }).then(function (response) {
                    _this.setSceneInfo(response.data)
                    _this.reviewRequestInProcess = false;
                }).catch(function (error) {
                    console.log("error", error);
                    _this.reviewRequestInProcess = false;
                });
        },
        stopLocationReview() {
            this.reviewRequestInProcess = true;

            let _this = this;
            const headers = this.getCommonHeaders()

            this.axios.get(process.env.VUE_APP_API_BASEURL + API_URL.STOP_LOCATION_REVIEW,
                {
                    headers: headers,
                    params: {
                        "modelHash": this.sceneHash
                    }
                }).then(function (response) {
                    _this.setSceneInfo(response.data)
                    _this.reviewRequestInProcess = false;
                }).catch(function (error) {
                    console.log("error", error);
                    _this.reviewRequestInProcess = false;
                });
        },
        onClickRemoveLocation() {
            this.setCoordinates(0, 0)
        },
        onClickSetLocation() {
            const _this = this
            let modelsList = []
            let center = { lat: 48.859676, lng: 2.349896 }

            if (this.latitude != 0 && this.longitude != 0) {
                center = { lat: this.latitude, lng: this.longitude }
                modelsList = [this.$editor.sceneData]
            }
            this.$root.showFullscreenPopup({
                item: MapSelectLocation,
                title: "Select the model location",
                classes: "model-preview__popup",
                showSeparators: false,
                applyCallback: this.onApplyLocation,
                props: {
                    center,
                    modelsList
                },
            })
        },
        updateCurrentLocationLabel(lat, lng) {
            if (Number(lat) == 0 && Number(lng) == 0) {
                this.$refs.currentLocation.innerHTML = "Set location"
                this.$refs.setLocationButton.className = "icon-label-bordered-button-inactive"
                this.$refs.currentLocationIcon.src = "/img/location_inactive.svg"
                this.showRemoveLocationButton = false
                this.$refs.setLocationButton.style["width"] = "100%"
            } else {
                this.$refs.currentLocation.innerHTML = "Change Location"
                this.$refs.setLocationButton.className = "icon-label-bordered-button"
                this.$refs.currentLocationIcon.src = "/img/location.svg"
                this.showRemoveLocationButton = true
                this.$refs.setLocationButton.style["width"] = "85%"
            }
        },
        onApplyLocation(map) {
            this.setCoordinates(map.latitude, map.longitude)
        }
    }
}
</script>

<style lang="scss">
.editor-scene-public-info-location-container {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.editor-scene-public-info-location {
    padding: 5px 10px;
    background-color: #0000000f;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    font-weight: 500;
    color: #525252;
}

.editor-location-remove-button {
    width: 40px;
    height: 40px;
    border: none;
    background-color: #0000001c;
    border-radius: 20px !important;
    padding: 7px;
}

.editor-location-remove-button:hover {
    background-color: #00000011;
}

.scene-map {
    padding: 0px 10px 0px 10px;
    overflow-x: hidden;
    overflow: auto;
    height: 100%;
}

#set-location-button {
    width: 100%;
}

#publish-on-map-button {
    width: 100%;
    margin-top: 10px !important;
}

#stop-review-button {
    width: 100%;
    margin-top: 10px !important;
}

.location-review-status {
    width: 100%;
    line-height: 1.2;

    background-color: #0000000f;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    color: #484848;
    font-weight: 500;
    text-align: center;
}
.location-hint {
    width: 100%;
    line-height: 1.3;
    padding: 10px;
    border-radius: 10px;
    margin-top: 3px;
    color: #070707;
    font-weight: 400 !important;
}
</style>