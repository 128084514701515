<template>
  <ul class="features__list">
    <li v-for="number of 5" v-bind:key="number" class="features__item">
      {{ $t(`${nameOfFeatures}.features.feature${number}`) }}
    </li>
  </ul>
</template>

<script>
export default {
  props: { nameOfFeatures: String }
};
</script>

<style lang="scss">
.features__list {
  padding: 0 !important;
  list-style: none;
}

.features__item {
  position: relative;
  padding-left: 34px;
  margin-top: 5px;
}

.features__item:not(:last-child) {
  margin-bottom: 15px;
}

.features__item::before {
  content: "";
  position: absolute;
  top: 3;
  left: 0;

  display: block;
  width: 22px;
  height: 22px;

  background-image: url("./../assets/icon-checked.svg");
  background-repeat: no-repeat;
}
</style>
