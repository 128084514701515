<template>
  <b-row>
    <b-col>
      <div class="account-content-container">
        <b-overlay :show="!invoices" rounded="sm">
          <div class="account-card-header">{{ $t('billing.billing-history') }}</div>

          <b-table responsive striped hover :fields=fields :items="invoices">
            <template #table-caption(status)="row"><span
                :class="statusClass(row.item.status)">{{ row.item.status }}</span></template>
            <template #cell(subscription)="row"><span v-html="row.item.subscription"></span></template>
            <template #cell(actions)="row"><span v-html="row.item.actions"></span></template>
          </b-table>
        </b-overlay>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

let locales = { "en": 1, "ru": 2 }

export default {
  computed: {
    statusClass() {
      return function (status) {
        if (status == this.$i18n.t('billing.failed')) return 'text-danger';
        if (status == this.$i18n.t('billing.success')) return 'text-success';
        if (status == this.$i18n.t('billing.pending')) return 'text-warning';
      }
    }
  },
  data() {
    return {
      invoices: null,
      fields: [
        { date: { label: this.$i18n.t('billing.date') } },
        { amount: { label: this.$i18n.t('billing.amount') } },
        { subscription: { label: this.$i18n.t('billing.subscription') } },
        { status: { label: this.$i18n.t('billing.status') } },
        { actions: { label: this.$i18n.t('billing.actions') } },
      ]

    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["billing"];
    let _this = this;
    this.axios.post(process.env.VUE_APP_API_BASEURL + "/api/v1/billing/subscription/invoices", {}, {
      headers: {
        "Authorization": "Bearer " + this.$store.state.token
      }
    }).then(function (response) {
      _this.invoices = [];
      response.data.forEach(function (invoice) {
        let productLocale = _.find(invoice.subscription.product.locales, { cultureId: locales[_this.$i18n.locale] })
        let status = _this.$i18n.t('billing.pending');
        if (invoice.status == 2) status = _this.$i18n.t('billing.success');
        if (invoice.status == 4) status = _this.$i18n.t('billing.failed');
        let description = invoice.subscription.product.description
        description = description.replaceAll(',', "<br>")
        let actions = ""
        if (invoice.invoicePDF.length > 0) {
          actions += "<a href=" + invoice.invoicePDF + ">" + _this.$i18n.t('billing.download-pdf') + "</a><br>"
        }
        if (invoice.invoiceURL.length > 0) {
          actions += "<a href=" + invoice.invoiceURL + ">" + _this.$i18n.t('billing.open-invoice') + "</a>"
        }
        _this.invoices.push({
          date: moment(invoice.updateTime).format("MM.DD.YYYY HH:mm"),
          amount: invoice.subscription.product.price + "$",
          subscription: description,
          status: status,
          actions: actions
        });
      })
      //_this.invoices = response.data;
    }).catch(function (error) {
      console.log("error", error);
    });
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["billing"];
  }
}
</script>
