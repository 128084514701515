<template>
	<div ref="outliner" class="material-outliner">
		<div class="material-outliner-background" @click="deselect"></div>
	</div>
</template>

<script>

import MaterialItem from './MaterialItem.vue'
import SceneMaterials from './SceneMaterials.vue'
import Vue from 'vue'

export default {
	data: function() {
		return {
			materialItemList: [],
			materialList: [],
			selectedMaterialObject: null,
			selectedIndex: -1,
			onSelect: null,
			onHighlight: null,
			multiple: false
		}
	},
	components: {

	},
	created() {

	},
	mounted() {

	},
	methods: {
		configure(materialList) {
			// remove items
			var itemList = this.materialItemList
			for (var itemIndex in itemList) {
				var el = itemList[itemIndex]
				if (el == null) continue;
				el.$el.parentNode.removeChild(el.$el);
			}
			
			this.materialItemList = [];
			this.materialList = [];

			for (var index in materialList) {
				var ComponentClass = Vue.extend(MaterialItem)
				var item = new ComponentClass()

				item.$mount() // pass nothing
				item.configure(this, materialList[index], index)
				this.$refs.outliner.appendChild(item.$el)

				this.materialItemList.push(item)
			}
			this.materialList = materialList;
		},
		getMaterialForId(ident) {
			for (var index in this.materialList) {
				var material = this.materialList[index]
				if (material.id == ident) {
					return material
				}
			}

			return null
		},
		setMaterialForId(newMaterial, ident) {
			for (var index in this.materialList) {
				var material = this.materialList[index]
				if (material.id == ident) {
					Object.assign(this.materialList[index], newMaterial)
					break;
				}
			}

			for (var i in this.materialItemList) {
				var item = this.materialItemList[i];
				if (item.material.id == ident) {
					item.configure(this, newMaterial, i)
					break;
				}
			}
		},
		deselect() {
			this.select(-1, false);
		},
		selectById(id) {
			var index = -1;

			for (var i in this.materialItemList) {
				var item = this.materialItemList[i];
				var m = this.materialList[i];

				if (m.id == id) {
					index = i
					break;
				}
			}

			this.select(index)
		},
		select(index) {
			var newSelectedName;
			var newSelectedMaterialObject;

			for (var i in this.materialItemList) {
				var item = this.materialItemList[i]

				item.select(i == index)

				if (i == index) {
					newSelectedMaterialObject = this.materialList[i]
				}
			}
			this.selectedIndex = index;
			this.selectedMaterialObject = newSelectedMaterialObject

			if (this.onSelect) this.onSelect(index)
		},
		highlight(index) {
			for (var i in this.materialItemList) {
				var item = this.materialItemList[i]

				item.highlight(i == index)
			}
			if (this.onHighlight) this.onHighlight(index)
		},
		updateItems() {
			var materialObject = this.materialList[this.selectedIndex];
			for (var key in this.propertyEditItemMap) {
				var item = this.propertyEditItemMap[key]
				item.value = materialObject[key]
			}
		},
		getSelectedMaterial() {
			return this.materialList[this.selectedIndex]
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">

.material-outliner {
	width: 100%;
	height: 100%;
	overflow: auto;
	overflow-x: hidden;
	position: relative;
	background-color: white;
}
.material-outliner-background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
</style>
