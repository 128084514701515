<template>
	<div class="material-selector">
		<div v-show="headerTitle.length > 0" ref="header" class="editor-property-header">{{headerTitle}}</div>
		<div class="material-selector-container" 
			ref="materialSelectorContainer"
			@click="onPress"
		>
			<img ref="thumbnailImg" class="material-item-thumnail" onerror="this.onerror=null;"/>
			<div ref="titleElement" v-html="title" class="material-item-title unselectable"></div>
			<div class="material-item-right-elements-container">
				<div ref="rightLabel" v-show="rightLabelText.length > 0" class="material-item-right-label">{{ rightLabelText }}</div>
				<div v-show="showRemoveButton" class="material-item-remove-button" @click="onClickRemove">
					<svg viewBox="0 0 20 20" class="editor-close-button-icon"><title>Close Icon</title>
						<path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
					</svg>
				</div>
			</div>
			
		</div>

		<transition name="fast-transition">
			<div v-show="isListVisible" ref="editorMaterialSelectorMenu" class="editor-material-selector-menu">
				<MaterialOutliner ref="outliner"></MaterialOutliner>
			</div>
		</transition>
	</div>
</template>

<script>

import SceneMaterials from './SceneMaterials.vue'
import MaterialOutliner from './MaterialOutliner.vue'

import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			headerTitle: "",
			title: "",
			isListVisible: false,
			materialList: [],
			materialId: -1,
			onChange: null,
			key: "",
			showEmbedded: false,
			multiple: false,
			rightLabelText: "",
			onClickRemoveCallback: null,
			showRemoveButton: false
		}
	},
	components: {
		MaterialOutliner
	},
	created() {

	},
	mounted() {
		let _this = this;
	},
	methods: {
		configure(materialId) {
			var material = null
			if (materialId) {
				material = this.$editor.getMaterialById(materialId)
			}
			if (material) {
				this.materialId = material.id
				this.title = material.name

				if (process.env.NODE_ENV === 'development') {
					var debugTitle = "[" + material.id + "->" + material.substitutionMaterialId + "] " + material.name
					this.title = debugTitle
				}

				var params = {}
				params["materialId"] = Number(material.id)
				params["isThumbnail"] = 1;
				var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["getMaterialPreviewData", JSON.stringify(params)]);
				response = eval("(" + response + ")")
				this.$refs.thumbnailImg.src = "data:image/png;base64," + response["data"];
				this.$refs.thumbnailImg.style.opacity = 1;
			} else {
				this.title = "Click to select material"
				this.$refs.thumbnailImg.src = "/img/scene_menu_materials.svg"
				this.$refs.thumbnailImg.style.opacity = 0.5;
				this.materialId = -1
			}
		},
		onClickRemove(event) {
			event.preventDefault();
			event.stopPropagation();

			if (this.onClickRemoveCallback) this.onClickRemoveCallback(this)
		},
		select(select) {

		},
		close () {
			this.isListVisible = false;
			this.$refs.outliner.onSelect = null
			document.removeEventListener('mousedown', this.closeCallback);
		},
		isElementClosest(element, wrapper) {
			while (element !== document && element !== null) {
				if (element === wrapper) return true;
				element = element.parentNode;
			}
		
			return false;
		},
		onPress() {
			let _this = this

			this.isListVisible = !this.isListVisible

			if (this.isListVisible) {
				var materialList = []
				materialList.push(null)
				materialList = materialList.concat(this.$editor.getMaterialList());
				this.materialList = materialList
	
				this.$refs.outliner.multiple = this.multiple
				this.$refs.outliner.configure(materialList)
	
				// select current material
				var selectedIndex = 0
				for (var materialIndex in materialList) {
					var material = materialList[materialIndex]
					if (material && material.id == this.materialId) {
						selectedIndex = Number(materialIndex)
						break
					}
				}
				this.$refs.outliner.onSelect = null
				this.$refs.outliner.select(selectedIndex)
				this.$nextTick(() => {
					_this.$refs.outliner.$el.scrollTo(0, selectedIndex * 50);
				});
				
				var menuTop = 50;
				if (this.headerTitle.length > 0) {
					menuTop += 20
				}
				this.$refs.editorMaterialSelectorMenu.style["top"] = menuTop + "px"

				this.$refs.outliner.onSelect = function (index) {
					_this.close()
					_this.onSelect(index)
				}
				this.closeCallback = (evnt) => {
					if (
						!this.isElementClosest(evnt.target, this.$refs.editorMaterialSelectorMenu) &&
						!this.isElementClosest(evnt.target, this.$refs.materialSelectorContainer)
					) {
						_this.close();
					}
				};
				document.addEventListener('mousedown', this.closeCallback);
			} else {
				this.close()
			}
		},
		onSelect(index) {
			var material = this.materialList[index]
			var materialId = -1
			if (material) {
				materialId = material.id
			}
			this.onChange(materialId, this.key)
			this.configure(materialId)
			this.close()
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.material-selector-container {
	width: 100%;
	height: 40px;
	position: relative;
	text-align: left;
	display: flex;
	cursor: pointer;
	border-radius: 3px;

	background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 1px solid #0000001f;
}
.material-selector-container:hover {
	background-color: rgba(0, 0, 0, 0.04);
	border: 1px solid #0000002f;
}
.editor-material-selector-menu {
	position: absolute;
	top: 70px;
	left: 0px;
	width: 100%;
	min-height: 35px;
    background-color: white;
    border-radius: 5px;
    z-index: 3;
    border: 1px solid #c2c2c2;
    overflow: hidden;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
    max-height: 220px;
}
.material-item-right-label {
	background-color: black;
	color: white;
	text-align: center;
	
    min-width: 20px;
    line-height: 20px;
    padding: 0px 5px;
    border-radius: 50%;
    
    font-weight: 600;
    font-size: 12px;
}
.material-item-right-elements-container {
	transform: translate(0, -50%);
	right: 5px;
    top: 50%;
    position: absolute;
    height: 20px;
    display: flex;
}
.material-item-remove-button {
	border-radius: 10px;
    width: 20px;
    height: 20px;
    padding: 3px;
    background-color: #cdcdcd;
    margin-left: 4px;
}
.material-item-remove-button:hover {
	background-color: #ededed;
}
.material-selector {
	position: relative;
}
</style>
