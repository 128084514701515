import { render, staticRenderFns } from "./FilePreview.vue?vue&type=template&id=43a22509"
import script from "./FilePreview.vue?vue&type=script&setup=true&lang=js"
export * from "./FilePreview.vue?vue&type=script&setup=true&lang=js"
import style0 from "./FilePreview.vue?vue&type=style&index=0&id=43a22509&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports