<template>
  <div class="scene-grid-wrapper">
    <div v-show="showGrid" ref="container" class="scene-grid-container"></div>

    <div
      v-if="scenesVisibleAmount == 0"
      ref="emptyContainer"
      class="scene-list-container-empty"
      v-html="$t(noModelsText || 'scene-grid.no-models-yet')"
    >

    </div>
  </div>
</template>

<script>
import Vue from "vue"
import SceneItem from "./SceneItem.vue"

export default {
  props: {
    noModelsText: {
      default: "scene-grid.no-models",
      type: String,
    },
    selectable: {
      default: false,
      type: Boolean,
    },
    showPublicLabel: {
      default: false,
      type: Boolean,
    },
    showMapIcons: {
      default: true,
      type: Boolean
    },
    maxSelectedModels: {
      default: -1,
      type: Number,
    },
    onSceneSelected: {
      default: null,
      type: Function,
    },
    sceneArray: {
      default: null,
      type: Array,
    },
    isPublicOnly: {
      default: false,
      type: Boolean,
    },
    selectedSceneArray: {
      default: null,
      type: Array,
    },
  },
  data: function () {
    return {
      itemList: [],
      selectedItems: null,
      onSelectItem: null,
      skipScenesWithoutImage: false,
      scenesVisibleAmount: 0,
      showGrid: true,
    }
  },
  watch: {
    selectable(newValue) {
      for (let itemIndex in this.itemList) {
        let item = this.itemList[itemIndex]
        item.selectable = newValue
      }
    },
    showPublicLabel(newValue) {
      for (let itemIndex in this.itemList) {
        let item = this.itemList[itemIndex]
        item.showPublicLabel = newValue
      }
    },
  },
  mounted() {
    if (this.sceneArray) {
      this.configure(this.sceneArray, this.isPublicOnly)
    }
    if (this.selectedSceneArray) {
      this.setSelectedScenes(this.selectedSceneArray)
    }
  },
  methods: {
    getSelectedSceneHashArray() {
      let result = []
      let selectedItems = this.selectedItems
      if (selectedItems) {
        for (let itemIndex in selectedItems) {
          let item = selectedItems[itemIndex]
          let scene = item.scene
          result.push(scene.hash)
        }
      }
      return result
    },
    setOnSelectItem(func) {
      this.onSelectItem = func

      for (let itemIndex in this.itemList) {
        let item = this.itemList[itemIndex]
        item.onSelectItem = func
      }
    },
    createSceneItem(scene) {
      var ComponentClass = Vue.extend(SceneItem)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.configure(scene)
      item.onSelected = this.onSelected
      item.showPublicLabel = this.showPublicLabel
      item.showMapIcons = this.showMapIcons
      
      if (this.onSelectItem) {
        item.onSelectItem = this.onSelectItem
      }
      return item
    },
    configure(sceneArray, isPublicOnly) {
      this.itemList = []
      this.$refs.container.innerHTML = ""

      this.scenesVisibleAmount = 0

      if (sceneArray != null && sceneArray.length > 0) {
        for (var index in sceneArray) {
          let scene = sceneArray[index]

          if (isPublicOnly && scene.flags.includes(2 /*private*/)) {
            continue
          }
          let item = this.createSceneItem(scene)

          // skip imageless if need
          if (this.skipScenesWithoutImage && !item.hasImage) {
            continue
          }
          item.selectable = this.selectable
          this.$refs.container.appendChild(item.$el)

          this.itemList.push(item)
          ++this.scenesVisibleAmount
        }
      }

      this.showGrid = this.scenesVisibleAmount > 0
    },
    onSelected(item, isSelected) {
      let index = this.selectedItems.indexOf(item)
      if (index >= 0) {
        this.selectedItems.splice(index, 1)
      }
      if (isSelected) {
        let allowSelection = true
        if (this.maxSelectedModels >= 0) {
          allowSelection =
            this.selectedItems.length + 1 <= this.maxSelectedModels
        }
        if (allowSelection) {
          this.selectedItems.push(item)
        } else {
          item.selected = false
        }
      }
      if (this.onSceneSelected) {
        this.onSceneSelected(this, item, isSelected)
      }
    },
    setSelectedScenes(sceneArray) {
      let sceneHashIndexMap = {}
      let sceneHashArray = []
      for (let sceneIndex in sceneArray) {
        let scene = sceneArray[sceneIndex]

        sceneHashIndexMap[scene.hash] = sceneIndex
        sceneHashArray.push(scene.hash)
      }

      // Mark selected and collect selected items
      this.selectedItems = []
      for (let itemIndex in this.itemList) {
        let item = this.itemList[itemIndex]
        let isSelected = Object.hasOwn(sceneHashIndexMap, item.scene.hash)
        item.selected = isSelected

        if (isSelected) {
          this.selectedItems.push(item)
        }
      }

      // Sort by hash and reverse
      let itemContainer = this.$refs.container
      let selectedItemsSorted = this.selectedItems
      selectedItemsSorted.sort(function (a, b) {
        let indexA = sceneHashArray.indexOf(a.scene.hash)
        let indexB = sceneHashArray.indexOf(b.scene.hash)
        return indexA - indexB
      })
      this.selectedItems = selectedItemsSorted

      let selectedItemsSortedReversed = selectedItemsSorted.slice().reverse()

      for (let itemIndex in selectedItemsSortedReversed) {
        let item = selectedItemsSortedReversed[itemIndex]
        this.$refs.container.removeChild(item.$el)
        if (itemContainer.childNodes.length > 0) {
          itemContainer.insertBefore(item.$el, itemContainer.firstChild)
        } else {
          itemContainer.appendChild(item.$el)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.scene-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  justify-content: center;
}

.scene-list-container-empty {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
