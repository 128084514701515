<template>
	<div>
		<div class="texture-selector-container" 
			ref="textureSelectorContainer"
			@click="onPress"
		>
			<img ref="thumbnailImg" class="texture-item-thumnail" onerror="this.onerror=null;"/>
			<div ref="titleElement" v-html="title" class="texture-item-title unselectable"></div>
		</div>

		<transition name="fast-transition">
			<div v-show="isListVisible" ref="editorTextureSelectorMenu" class="editor-texture-selector-menu">
				<TextureOutliner ref="outliner"></TextureOutliner>
			</div>
		</transition>
	</div>
</template>

<script>

import SceneTextures from './SceneTextures.vue'
import TextureOutliner from './TextureOutliner.vue'

import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			title: "",
			isListVisible: false,
			textureList: [],
			textureId: -1,
			onChange: null,
			key: "",
			showEmbedded: false
		}
	},
	components: {
		TextureOutliner
	},
	created() {

	},
	mounted() {
		let _this = this;
	},
	methods: {
		configure(texture) {
			if (texture) {
				this.textureId = texture.id
				this.title = texture.name

				var params = {}
				params["textureId"] = Number(texture.id)
				params["isThumbnail"] = 1;
				var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["getTextureData", JSON.stringify(params)]);
				response = eval("(" + response + ")")
				this.$refs.thumbnailImg.src = "data:image/png;base64," + response["data"];
				this.$refs.thumbnailImg.style.opacity = 1;
			} else {
				this.title = "Click to select texture"
				this.$refs.thumbnailImg.src = "/img/scene_menu_textures.svg"
				this.$refs.thumbnailImg.style.opacity = 0.5;
				this.textureId = -1
			}
		},
		select(select) {

		},
		close () {
			this.isListVisible = false;
			this.$refs.outliner.onSelect = null
			document.removeEventListener('mousedown', this.closeCallback);
		},
		isElementClosest(element, wrapper) {
			while (element !== document && element !== null) {
				if (element === wrapper) return true;
				element = element.parentNode;
			}
		
			return false;
		},
		onPress() {
			let _this = this

			this.isListVisible = !this.isListVisible

			if (this.isListVisible) {
				var textureList = []
				textureList.push(null)
				textureList = textureList.concat(this.$editor.getTextureList());
				this.textureList = textureList
	
				this.$refs.outliner.showEmbedded = this.showEmbedded
				this.$refs.outliner.configure(textureList)
	
				// select current texture
				var selectedIndex = 0
				for (var textureIndex in textureList) {
					var texture = textureList[textureIndex]
					if (texture && texture.id == this.textureId) {
						selectedIndex = Number(textureIndex)
						break
					}
				}
				this.$refs.outliner.onSelect = null
				this.$refs.outliner.select(selectedIndex)
				this.$nextTick(() => {
					_this.$refs.outliner.$el.scrollTo(0, selectedIndex * 50);
				});
				

				this.$refs.outliner.onSelect = function (index) {
					_this.close()
					_this.onSelect(index)
				}
				this.closeCallback = (evnt) => {
					if (
						!this.isElementClosest(evnt.target, this.$refs.editorTextureSelectorMenu) &&
						!this.isElementClosest(evnt.target, this.$refs.textureSelectorContainer)
					) {
						_this.close();
					}
				};
				document.addEventListener('mousedown', this.closeCallback);
			} else {
				
				this.close()
			}
		},
		onSelect(index) {
			var texture = this.textureList[index]
			this.onChange({type: "texture", value: texture}, this.key)
			this.configure(texture)
			this.close()
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.texture-item-selected-background {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #59b5bb;
}
.texture-item-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.texture-selector-container {
	width: 100%;
	height: 50px;
	position: relative;
	text-align: left;
	display: flex;
	cursor: pointer;
	border-radius: 3px;
}
.texture-selector-container:hover {
	background-color: rgba(0, 0, 0, 0.04)
}
.texture-item-title {
	padding-left: 10px;
	color: black;
	line-height: 50px;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.texture-item-title-selected {
	padding-left: 10px;
	color: white;
	line-height: 50px;
	font-weight: 500;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.texture-item-thumnail {
	width: 46px;
	height: 46px;
	margin-top: 2px;
	background-color: rgb(220, 220, 220);
	border-radius: 2px;
	margin-left: 2px;
	z-index: 1;
}
.editor-texture-selector-menu {
	position: absolute;
	top: 50px;
	left: 0px;
	width: 100%;
	min-height: 35px;
    background-color: white;
    border-radius: 5px;
    z-index: 2;
    border: 1px solid #c2c2c2;
    overflow: hidden;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
    max-height: 220px;
}
</style>
