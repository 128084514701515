<template>
  <div v-if="!isMapVisible" class="search-with-map">
    <b-form-input
      ref="searchInput"
      v-model="searchText"
      class="search-with-map__search-input"
      :placeholder="$t('search-with-map.search-input-placeholder')"
      maxlength="64"
    ></b-form-input>

    <div class="search-with-map__show-map" @click="onShowMapClick">
      <div class="search-with-map__show-map-text">{{ $t("map.show-map") }}</div>
    </div>

    <div class="search-with-map__models-spinner-container">
      <b-spinner
        v-if="searchInProgress"
        class="search-with-map__models-spinner"
        variant="dark"
      ></b-spinner>
    </div>

    <div v-show="showUserList" class="search-with-map__title">Users</div>
    <transition name="fast-transition">
      <div
        v-show="showUserList"
        class="search-with-map__user-list-container"
      ></div>
    </transition>

    <transition name="fast-transition">
      <div
        v-show="showStatus"
        class="search-with-map__model-list-container-empty"
      >
        {{ modelEmptyText }}
      </div>
    </transition>

    <div v-show="showModelSearchHeader" class="search-with-map__title">
      3D Models
    </div>
    <div
      v-show="showPopularModelsHeader"
      class="search-with-map__popular-header"
    >
      {{ $t("search-with-map.popular-models-header") }}
    </div>

    <div
      v-if="popularLoadInProgress"
      class="search-with-map__popular-models-spinner-container"
    >
      <b-spinner
        class="search-with-map__popular-models-spinner"
        variant="dark"
      ></b-spinner>
    </div>
    <transition name="fast-transition">
      <SceneGrid
        v-show="showModelList"
        ref="sceneGrid"
        class="search-with-map__popular-models-list-container"
      />
    </transition>

    <a class="search-with-map__user-item-container" hidden="true">
      <img
        class="search-with-map__user-item-avatar"
        onerror="this.onerror=null; this.src='/img/avatar_placeholder.svg'"
        src="~@/assets/avatar_placeholder.svg"
      />
      <div class="search-with-map__user-item-info-container">
        <div class="search-with-map__user-item-name"></div>
        <div class="search-with-map__user-item-username"></div>
      </div>
    </a>
  </div>
  <MapWithList v-else :on-show-list-click="onShowListClick" />
</template>

<script>
import { API_URL } from "../../const"
import MapWithList from "../Map/MapWithList.vue"
import SceneGrid from "../SceneGrid.vue"

export default {
  components: {
    SceneGrid,
    MapWithList,
  },
  props: {
    isMapShown: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      searchInProgress: false,
      showModelList: false,
      showModelSearchHeader: false,
      showPopularModelsHeader: false,
      showUserList: false,
      showStatus: false,
      searchText: "",
      doNewSearch: false,
      showPopularList: false,
      popularLoadInProgress: false,
      searchTimeoutID: null,
      isMapVisible: this.isMapShown,
      modelEmptyText: "",
    }
  },
  watch: {
    searchText(newValue, oldValue) {
      const _this = this
      if (this.searchTimeoutID) {
        clearTimeout(this.searchTimeoutID)
      }
      this.searchTimeoutID = setTimeout(function () {
        _this.search(newValue)
      }, 500)
    },
  },
  created() {
    document.title = "Architeque"
  },
  mounted() {
    if (!this.isMapVisible) {
      this.updateList()
    }
  },
  methods: {
    updateList() {
      this.popularLoadInProgress = true
      this.showModelList = false
      this.showModelSearchHeader = false
      this.showPopularModelsHeader = false
      this.showUserList = false

      const _this = this
      const headers = this.getCommonHeaders()
      this.axios
        .get(process.env.VUE_APP_API_BASEURL + API_URL.POPULAR, {
          params: {},
          headers: headers,
        })
        .then(function (response) {
          _this.popularLoadInProgress = false

          let sceneArray = response.data
          const isPublicOnly = true
          _this.$refs.sceneGrid.configure(sceneArray, isPublicOnly)

          _this.showPopularModelsHeader =
          _this.$refs.sceneGrid.scenesVisibleAmount > 0
          _this.showModelList = _this.$refs.sceneGrid.scenesVisibleAmount > 0
          _this.$refs.sceneGrid.skipScenesWithoutImage = true
        })
        .catch(function (error) {
          console.log("error", error)

          _this.showPopularList = false
          _this.popularLoadInProgress = false
        })
    },
    search(text) {
      const exploreSearchUsersListElement = document.querySelector(
        ".search-with-map__user-list-container",
      )

      this.modelEmptyText = ""

      if (text.length == 0) {
        this.showStatus = false
        this.updateList()
        this.modelEmptyText = "Type something to search 3D models on Architeque"
        return
      }

      if (text.length < 2) {
        this.showStatus = true
        this.modelEmptyText = "Type more to search"
        this.showPopularList = false
        this.showUserList = false
        this.showModelList = false
        this.showModelSearchHeader = false
        this.showPopularModelsHeader = false
        return
      }
      if (this.searchInProgress) {
        this.doNewSearch = true
        return
      }

      this.showModelSearchHeader = false
      this.showPopularList = false
      this.doNewSearch = false
      this.searchInProgress = true
      this.showStatus = false
      this.showUserList = false
      this.showPopularModelsHeader = false
      this.showModelList = false

      exploreSearchUsersListElement.innerHTML = ""

      let _this = this
      let headers = this.getCommonHeaders()
      this.axios
        .get(process.env.VUE_APP_API_BASEURL + API_URL.SEARCH_ALL, {
          params: { text: this.searchText },
          headers: headers,
        })
        .then(function (response) {
          const modelList = response.data.models
          let userList = response.data.users

          const isPublicOnly = true
          _this.$refs.sceneGrid.configure(modelList, isPublicOnly)

          let visibleModelsAmount = _this.$refs.sceneGrid.scenesVisibleAmount

          if (visibleModelsAmount > 0 || userList.length > 0) {
            const userItemTemplateElement = document.querySelector(
              ".search-with-map__user-item-container",
            )

            // show only a few first matches
            userList = userList.slice(0, 5)

            for (const user of userList) {
              const userItem = userItemTemplateElement.cloneNode(true)

              userItem.hidden = false
              userItem.style["display"] = ""
              userItem.id = ""

              const imgElement = userItem.querySelector(
                ".search-with-map__user-item-avatar",
              )
              const nameElement = userItem.querySelector(
                ".search-with-map__user-item-name",
              )
              const usernameElement = userItem.querySelector(
                ".search-with-map__user-item-username",
              )

              _this.setupAvatarImage(imgElement, user)

              nameElement.innerHTML = user.name
              usernameElement.innerHTML = "@" + user.username

              userItem.href = _this.getURLForProfile(user.username)

              exploreSearchUsersListElement.appendChild(userItem)
            }

            _this.showPopularList = visibleModelsAmount > 0
            _this.showModelSearchHeader = visibleModelsAmount > 0
            _this.showModelList = visibleModelsAmount > 0
            _this.showPopularModelsHeader = false

            _this.showUserList = userList.length > 0
            _this.showStatus = false
          } else {
            _this.showModelList = false
            _this.showUserList = false
            _this.searchInProgress = false
            _this.showStatus = true
            _this.modelEmptyText =
              "Nothing found for your request. Type something else"
          }

          _this.searchInProgress = false

          if (_this.doNewSearch) {
            _this.search(_this.searchText)
          }
        })
        .catch(function (error) {
          console.log("error", error)
          _this.showModelList = false
          _this.searchInProgress = false
          _this.showStatus = true
        })
    },
    onShowMapClick() {
      this.isMapVisible = true
    },
    onShowListClick() {
      this.isMapVisible = false
      this.updateList()
    },
  },
}
</script>

<style lang="scss">
.search-with-map {
  &__wrapper {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    max-width: 1200px !important;

    @media (max-width: $mobile-width-only) {
      padding-top: 60px !important;
    }
  }

  &__container {
    display: flex;
    margin-left: 15px;
  }

  &__model-item-statistics {
    font-weight: 500;
    right: 5;
    text-align: right;
    position: absolute;
    line-height: 35px;
    color: black;

    &::before {
      content: "";

      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-right: 3px;
      background-size: contain;
      vertical-align: middle;

      background-image: url("~@/assets/views.svg");
    }
  }

  &__popular-models-list-container {
    margin-top: 20px;
  }

  &__title {
    font-weight: 500;
    border-bottom: 1px solid #d8d8d8;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-left: 5px;
  }

  &__user-list-container {
    margin-top: 20px;
  }

  &__models-list-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 20px;
    justify-content: center;
  }

  &__model-item-img {
    background-color: #fafafa;
    width: 300px;
    height: 170px;
    border-radius: 10px;
    box-shadow:
      0 16px 24px rgba(0, 0, 0, 0.03),
      0 2px 6px rgba(0, 0, 0, 0.1),
      0 0 1px rgba(0, 0, 0, 0.05);
    object-fit: cover;
    overflow: hidden;
  }

  &__model-item-name {
    width: 100%;
    padding-right: 45px;
    padding-left: 5px;
    line-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
  }

  &__model-item-info-container {
    height: 30px;
    width: 100%;
  }

  &__model-item-template {
    display: none;
  }

  &__model-item-container {
    width: 300px;
    height: 200px;
    margin: auto;
    position: relative;
  }

  &__user-item-name {
    font-size: 16pt;
    line-height: 1;
    color: black !important;

    @media (max-width: $mobile-width-only) {
      font-size: 14pt;
    }
  }
  &__user-item-username {
    font-size: 14pt;
    color: black !important;
    text-decoration: none;

    @media (max-width: $mobile-width-only) {
      font-size: 9pt;
    }
  }
  &__user-item-avatar {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 50%;
  }
  &__user-item-info-container {
    margin-top: 14px;
  }
  &__user-item-container {
    display: flex;
    margin-bottom: 15px;
  }
  &__popular-header {
    font-weight: 500;
    border-bottom: 1px solid #d8d8d8;
    font-size: 22px;
    margin-top: 30px;
  }
  &__header {
    text-align: center;
    font-size: 36px !important;
    line-height: 44px !important;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: inline;

    @extend .font-title;

    @media (max-width: $mobile-width-only) {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  }
  &__header-container {
    text-align: center;
    margin-bottom: 35px;

    @media (max-width: $mobile-width-only) {
      margin-bottom: 25px;
    }
  }
  &__model-list-container-empty {
    text-align: center;
    min-height: 100px;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
  }
  &__search-input {
    height: 45px !important;
    font-size: 15px !important;
    padding-left: 7px !important;
    border-radius: 5px !important;
    padding-left: 15px !important;
    font-size: 18px !important;

    &:hover {
      background-color: #fafafa !important;
    }
  }
  &__models-spinner {
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    margin-top: 50px;
  }
  &__popular-models-spinner-container {
    text-align: center;
    padding-top: 50px;
  }

  &__show-map {
    width: 100%;
    cursor: pointer;
    position: relative;
    height: 100px;
    background-color: gray;
    margin-top: 15px;
    background-image: url("~@/assets/show_map_preview.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    display: flex;
    border-radius: 5px;

    &:hover .search-with-map__show-map-text,
    &:focus .search-with-map__show-map-text {
      transform: scale(1.1);
    }
    @media (min-width: $tablet-width) {
      margin-top: 30px;
    }
  }

  &__show-map-text {
    margin: auto;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    color: white;
    transition-duration: 300ms;

    @extend .font-main;

    @media (min-width: $tablet-width) {
      font-size: 38px !important;
      line-height: 58px !important;
    }
  }
}

.profile-models-header {
  font-weight: 500;
  margin-top: 30px;
  font-size: 20px;
  margin-left: 20px;
  @media (max-width: $mobile-width-only) {
    font-size: 16px;
  }
}
.profile-models-header-separator {
  height: 1px;
  background-color: #e3e3e3;
  margin-left: 15px;
  margin-right: 15px;
}
</style>
