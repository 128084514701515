<template>
  <dl class="descriptions__list in-container">
    <div
      v-for="description of listOfDescriptions"
      v-bind:key="description"
      v-bind:class="`descriptions__item descriptions__item--${description}`"
    >
      <dt class="descriptions__term">
        {{ $t(`${nameOfDescroptions}.descriptions.term.${description}`) }}
      </dt>
      <dd class="descriptions__description">
        {{
          $t(`${nameOfDescroptions}.descriptions.description.${description}`)
        }}
      </dd>
    </div>
  </dl>
</template>

<script>
export default {
  props: {
    nameOfDescroptions: String,
    listOfDescriptions: Array
  }
};
</script>

<style lang="scss">
.descriptions__list {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0 !important;
  @media (min-width: $tablet-width) {
    flex-direction: row;
    row-gap: 35px;
    column-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
  }
}

.descriptions__item {
  border-radius: 8px;
  position: relative;
  padding-top: 140px;
  text-align: center;
  width: 100%;
  max-width: 221px;
  @media (min-width: $tablet-width) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: $mobile-width-only) {
    padding-top: 110px;
  }
}

.descriptions__item:not(:last-child) {
  margin-bottom: 50px;

  @media (min-width: $tablet-width) {
    margin-bottom: 0;
  }
  @media (max-width: $mobile-width-only) {
    margin-bottom: 30px;
  }
}

.descriptions__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-size: 130px 130px;
  background-position: center;

  @media (max-width: $mobile-width-only) {
    background-size: 100px 100px;
    height: 100px;
  }
}

.descriptions__term {
  @extend .title-section;
}

.descriptions__description {
  margin: 0 !important;

  font-size: 12px;
  line-height: 1.2;
  color: black;

  @extend .font-main;

  @media (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: $mobile-width-only) {
    font-size: 14px;
  }
}
</style>
