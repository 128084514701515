export const Languages = {
  en: "Eng",
  ru: "Рус",
}

export const LinkApp = {
  MAC: "/Architeque.pkg",
  WIN: "/Architeque.msi",
}

export const KeyCodes = {
  ENTER: 13,
  SHIFT: 16,
  ESCAPE: 27,
}

export const MODEL_FLAG = {
  IS_FREE: 1,
  IS_PRIVATE: 2,
  IS_AVAILABLE_BY_QR_AND_LINK: 3, // by QR or link
  IS_AVAILABLE_IOS: 4,
  IS_AVAILABLE_ANDROID: 5,
  IS_SCAN: 6,
  HAS_ONE_TO_ONE_SCALE: 7,
  HAS_CONFIGURATOR: 8,
  DO_NOT_USE_IT: 9, // don't use this flag, it's used on clients locally
  HAS_ANNOTATIONS: 10,
}

export const LOCATION_MODERATION_STATUS = {
  DEFAULT: 0,
  PENDING: 1,
  FAILED: 2,
  ACCEPTED: 3
}

export const API_URL = {
  POPULAR: "/api/v1/models/popular",
  MAP_PUBLIC: "/api/v1/models/map",
  START_LOCATION_REVIEW: "/api/v1/models/startLocationReview",
  STOP_LOCATION_REVIEW: "/api/v1/models/stopLocationReview",
  MODELS_HASH: "/api/v1/models/hash",
  SEARCH_ALL: "/api/v1/search/all",
}
