<template>
	<div class="texture-item-container" 
		@click="onPress"
		@mouseover="onHover"
		@mouseout="onOut"
		v-show="show"
	>
		<div v-show="selected" class="texture-item-selected-background"></div>
		<img ref="thumbnailImg" class="texture-item-thumnail" />
		<div ref="titleElement" v-html="title" class="texture-item-title unselectable"></div>
		<div v-show="highlighted" class="texture-item-overlay"></div>
    </div>
</template>

<script>

import SceneTextures from './SceneTextures.vue'

export default {
	data: function() {
		return {
			title: "",
			selected: false,
			highlighted: false,
			textureEditor: null,
			index: 0,
			onSelect: null,
			onHighlight: null,
			show: true
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		configure(texture, index, onSelect, onHighlight) {
			this.onSelect = onSelect;
			this.onHighlight = onHighlight;

			this.index = index;

			if (texture) {
				this.title = texture.name

				var params = {}
				params["textureId"] = Number(texture.id)
				params["isThumbnail"] = 1;
				var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["getTextureData", JSON.stringify(params)]);
				response = eval("(" + response + ")")
				this.$refs.thumbnailImg.src = "data:image/png;base64," + response["data"];
			} else {
				this.title = "No Texture" // not localized
			}
		},
		highlight(highlight) {
			this.highlighted = highlight;
		},
		select(select) {
			this.selected = select;
			if (select) {
				this.$refs.titleElement.className = "texture-item-title-selected"
			} else {
				this.$refs.titleElement.className = "texture-item-title"
			}
		},
		onPress() {
			if (this.onSelect) {
				this.onSelect(this.index)
			}
		},
		onHover() {
			if (this.onHighlight) {
				this.onHighlight(this.index)
			}
		},
		onOut() {
			if (this.onHighlight) {
				this.onHighlight(-1)
			}
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.texture-item-selected-background {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #59b5bb;
}
.texture-item-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.texture-item-container {
	width: 100%;
	height: 50px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	text-align: left;
	display: flex;
}
.texture-item-container:hover {
	cursor: pointer;
}
.texture-item-title {
	padding-left: 10px;
	color: black;
	line-height: 50px;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.texture-item-title-selected {
	padding-left: 10px;
	color: white;
	line-height: 50px;
	font-weight: 500;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.texture-item-thumnail {
	width: 46px;
	height: 46px;
	margin-top: 2px;
	background-color: rgb(220, 220, 220);
	border-radius: 2px;
	margin-left: 2px;
	z-index: 1;
}
</style>
