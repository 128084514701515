<template>
  <main>
    <div class="website-page mw-500">
      <div class="promo__bg"></div>
      <b-jumbotron id="reset-password-container">
        <form onSubmit="return false;" v-if="!isPasswordSent">
          <div class="login_title">{{ $t("reset-password.title") }}</div>
          <b-form-input v-model="email" v-bind:placeholder="$t('reset-password.email')"
            class="login-input"></b-form-input>
          <b-alert :show="alert.state" :variant="alert.variant" class="mt-2">
            {{ alert.message }}
          </b-alert>
          <b-button block class="text-white login-button" variant="primary mt-3" :disabled="resetPasswordInProcess"
            @click="sendPassword()">
            <span class="text-white" v-if="!resetPasswordInProcess">{{ $t("reset-password.reset-password") }}</span>
            <b-spinner v-if="resetPasswordInProcess" variant="light"></b-spinner>
          </b-button>
          <div class="mt-4 text-center">
            {{ $t("reset-password.remember-password") }}
            <router-link class="font-weight-bold" :to="{ name: 'Login' }">{{
          $t("reset-password.login-btn")
        }}</router-link>
          </div>
        </form>
        <div v-else class="text-center" vertical-align="center">
          <img id="success-big-icon" src="../assets/success-big.svg" />
          <p class="status-text">{{ $t("reset-password.instruction-sent") }}</p>
          <b-button class="text-white login-button" block variant="primary mt-3"
            @click="$router.push({ name: 'Login' })">Ok</b-button>
        </div>
      </b-jumbotron>
    </div>
  </main>
</template>

<script>
import { KeyCodes } from "../const"
export default {
  data: function () {
    return {
      isPasswordSent: false,
      email: "",
      alert: {
        state: false,
        message: "",
        variant: "danger"
      },
      resetPasswordInProcess: false
    }
  },
  created() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["reset-password"]
  },
  mounted() {
    document.getElementById('reset-password-container').addEventListener("keydown", this.onResetPasswordEnterClick)
  },
  updated() {
    document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["reset-password"]
  },
  methods: {
    sendPassword: function () {
      this.resetPasswordInProcess = true
      let _this = this
      this.axios
        .put(
          process.env.VUE_APP_API_BASEURL + "/api/v1/user/security/password/reset",
          null,
          { params: { email: this.email, urlPreferred: process.env.VUE_APP_WEBSITE_URL + "/change-password" } }
        )
        .then(function () {
          _this.resetPasswordInProcess = false
          _this.isPasswordSent = true
        })
        .catch(function (error) {
          _this.resetPasswordInProcess = false
          _this.alert.state = true
          _this.alert.variant = "danger"
          _this.alert.message = error.response && error.response.data && error.response.data.cause && "Unknown error"
        })
    },
    onResetPasswordEnterClick(evt) {
      if (evt.keyCode === KeyCodes.ENTER) {
        this.sendPassword()
      }
    }
  }
}
</script>

<style>
#success-big-icon {
  width: 100px;
  margin-bottom: 20px;
}

.login_title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.promo__bg {
  position: absolute;
  display: block;
  top: 0;
  right: 0;

  z-index: -1;
  width: 100vw;
  height: 100vh;

  background-image: url("./../assets/Bg-main.png");

  background-repeat: no-repeat;
  background-position: top right;

  @media (max-width: $mobile-width-only) {
    display: none;
  }
}

.login-button {
  font-size: 1.2rem !important;
}

#reset-password-container {
  margin-top: 50px;

  @media (max-width: $mobile-width-only) {
    margin-top: 0px !important;
  }
}

.login-input {
  margin-bottom: 15px !important;
  height: calc(2em + 0.75rem + 2px) !important;
  border: 1px solid #9ea4ab !important;
}

.status-text {
  font-size: 20px;
}
</style>
