<template>
  <div v-if="!isMapShown" class="map__popular-models-spinner-container">
    <b-spinner class="map__popular-models-spinner" variant="dark" />
  </div>
  <div class="map__contents" v-else>
    <div v-show="showPointerOverlay" class="map__overlay">
      <img src="./../../assets/location-selection-marker.svg" class="map-location-selection-marker" width="100%" height="100%" />
    </div>
    <div id="map" class="map__map"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer"
import {
DEFAULT_COORDINATES,
DEFAULT_ZOOM,
MAP_KEY,
MARKER_IMG_NAME,
MARKER_SVG_ICON,
} from "./const"
import getNewCoordinates from "./utils/getNewCoordinates"

export default {
  props: {
    showPointerOverlay: {
      default: false,
      type: Boolean
    },
    centerChangeListener: {
      default: null,
      type: Function
    },
    isMapShown: {
      default: false,
      type: Boolean,
    },
    mapInitListener: {
      default: null,
      type: Function,
    },
    mapListener: {
      default: null,
      type: Function,
    },
    modelsList: {
      default: null,
      type: Array,
    },
    onMarkerClick: {
      default: null,
      type: Function,
    },
    center: {
      default() {
        return DEFAULT_COORDINATES
      },
      type: Object,
    },
    isSearchInProgress: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      map: null,
      markers: [],
      clusterer: null,
    }
  },
  watch: {
    isMapShown(newVal) {
      if (newVal) {
        this.initMap()
      }
    },
    modelsList(newVal) {
      if (this.clusterer) {
        this.clusterer.clearMarkers()
      }
      this.setMapOnAll(this.markers, null)
      if (!this.isSearchInProgress && newVal.length > 0) {
        this.addMarkers(newVal)
      }
    },
  },
  mounted() {
    if (this.isMapShown) {
      this.initMap()
    }
  },
  methods: {
    async initMap() {
      const loader = new Loader({
        apiKey: MAP_KEY,
      })

      const { Map } = await loader.importLibrary("maps")

      const map = await new Map(document.getElementById("map"), {
        center: this.center,
        zoom: DEFAULT_ZOOM,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: true,
        rotateControl: false,
        disableDefaultUi: true,
        mapTypeControl: false,
        mapId: "DEMO_MAP_ID",
      })

      let _this = this
      if (this.centerChangeListener) {
        map.addListener("center_changed", () => {
          if (_this.centerChangeListener) {
            _this.centerChangeListener(map)
          }
        })
      }
      if (this.mapListener) {
        map.addListener("idle", this.mapListener(map))
      }
      if (!this.isSearchInProgress && this.modelsList.length > 0) {
        this.addMarkers(this.modelsList)
      }
      if (this.mapInitListener) {
        this.mapInitListener(map)
      }
      this.map = map
    },
    createMarker(imageUrl) {
      const imgContainer = document.createElement("div")
      imgContainer.setAttribute("class", "map__marker-container")

      const img = document.createElement("img")
      img.setAttribute("src", imageUrl)
      img.setAttribute("class", "map__marker-img")

      imgContainer.appendChild(img)
      return imgContainer
    },
    async addMarkers(list) {
      const loader = new Loader({
        apiKey: MAP_KEY,
      })
      const { AdvancedMarkerElement, Marker } =
        await loader.importLibrary("marker")
      const { Size } = await loader.importLibrary("core")

      const renderMarker = function ({ count, position }) {
        // create svg url
        const svgString = window.btoa(MARKER_SVG_ICON)
        // create marker using svg icon
        return new Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svgString}`,
            scaledSize: new Size(50, 50),
          },
          label: {
            text: String(count),
            color: "rgba(255,255,255,0.9)",
            fontSize: "16px",
            fontWeight: "500"
          },
          zIndex: count,
        })
      }

      let ids = {}
      let markers = []
      for (const itemIndex in list) {
        const item = list[itemIndex]
        if (ids[item.id]) {
          continue
        }
        ids[item.id] = true
        const imageURL = this.getModelImageURL(item, MARKER_IMG_NAME)
        if (imageURL && imageURL.length > 0) {
          const marker = new AdvancedMarkerElement({
            position: getNewCoordinates(item.latitude, item.longitude),
            content: this.createMarker(
              imageURL
            ),
          })
          if (this.onMarkerClick) {
            marker.addListener("click", () => this.onMarkerClick({ scene: item }))
          } else {
            marker.addListener("click", () => this.onMarkerClickDefault({ scene: item }))
          }
          markers.push(marker)
        }
      }

      this.setMapOnAll(markers, this.map)

      this.markers = markers

      this.clusterer = new MarkerClusterer({
        map: this.map,
        markers,
        renderer: { render: renderMarker },
      })

      if (this.mapListener && this.map) {
        this.mapListener(this.map)()
      }
    },
    setMapOnAll(markers, map) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map)
      }
    },
    onMarkerClickDefault({ scene }) {
      window.open(this.generateSceneLink(scene.hash, false), '_blank');
    }
  },
}
</script>

<style lang="scss">
.map {
  &__marker-container {
    width: 60px;
    height: 60px;
    background-image: url("./../../assets/map-marker-pointer.svg");
    background-repeat: no-repeat;
    position: relative;
    background-size: 60px 60px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
  }

  &__spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 2;
    pointer-events: none;
  }

  &__contents {
    height: 100%;
    position: relative;
  }
  &__marker-img {
    object-fit: cover !important;
    height: 42px !important;
    width: 42px !important;
    border-radius: 50%;
    position: absolute;
    left: 9px;
    top: 3px;
  }
}
.map-location-selection-marker {
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 40px));
  position: absolute;
}

</style>
