<template>
  <div ref="container" class="tab-switch-tab" @click="onClick()">
    <div ref="title" class="tab-switch-title"></div>
    <!--div v-show="selected" class="tab-switch-tab-selection" ></div-->
  </div>
</template>

<script>

export default {
  data: function() {
    return {
      onSelect: null,
      index: 0,
      selected: false
    }
  },
  mounted() {
    
  },
  updated() {

  },
  methods: {
    setTitle(title) {
      this.$refs.title.innerHTML = title
    },
    configure(info) {
      this.setTitle(info.title)
    },
    onClick() { 
      if (this.onSelect) {
        this.onSelect(this)
      }
    },
    select(value) {
      this.selected = value
      if (value) {
        this.$refs.container.className = "tab-switch-tab-selected"
      } else {
        this.$refs.container.className = "tab-switch-tab"
      }
    }
  }
}
</script>

<style lang="scss">
.tab-switch-title {
  margin: 0px 10px;
}
.tab-switch-tab {
  display: inline-block;

  font-size: 20px;
  line-height: 40px;
  font-weight: 500;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $mobile-width-only) {
    font-size: 14px !important;
  }
  position: relative;
}
.tab-switch-tab:hover {
  border-bottom: 4px solid #a8d64c44;
}
.tab-switch-tab-selected {
  @extend .tab-switch-tab;
  border-bottom: 4px solid #a8d64c;
}
.tab-switch-tab-selected:hover {
  border-bottom: 4px solid #a8d64c;
}

</style>
