<template>
	<div>
		<div ref="header" v-show="titleText ? titleText.length > 0 : false" class="editor-property-header">{{titleText}}</div>
		<div class="color-picker-container">
			<div ref="colorSquare" class="picked-color-container" @click="onClickColor"></div>
			<div ref="colorLabel" class="color-picker-label" ></div>
		</div>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			color: '#FFFFFF',
			onChange: null,
			titleText: ""
		}
	},
	components: {
	
	},
	mounted() {
		this.updateContainerColor();
	},
	methods: {
		setColor(color) {
			if (!(color === this.color)) {
				if (this.onChange) this.onChange(color)
				this.color = color
				this.updateContainerColor();
			}
		},
		onClickColor() {
			this.$editor.onColorPick(this)
		},
		updateContainerColor() {
			this.$refs.colorSquare.style["background-color"] = this.color
			this.$refs.colorLabel.innerHTML = this.color;
		}
	},
	watch: {

	}
}
</script>


<style lang="scss">

.picked-color-container {
    width: 30px;
    height: 30px;
    position: absolute;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #d5d5d5;
}
.picked-color-label {
	display: flex;
}
.color-picker-container {
    border-radius: 3px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    position: relative;
}
.color-picker-label {
	background-color: #ffffff;
	margin-left: 35px !important;
	line-height: 30px;
	height: 30px !important;
	padding-left: 7px !important;
	color: #616161;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #d5d5d5;
}

.one-colorpanel {
	top: 140px !important;
    right: 440px !important;;
    z-index: 1000 !important;;
    position: fixed !important;;
}
</style>