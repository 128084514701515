<template>
	<div class="object-selector">
		<div v-show="headerTitle.length > 0" ref="header" class="editor-property-header">{{headerTitle}}</div>
		<div class="object-selector-container" 
			ref="objectSelectorContainer"
			@click="onPress"
		>
			<div ref="titleElement" v-html="title" class="object-selector-title unselectable"></div>
			<div class="object-item-right-elements-container">
				<div ref="rightLabel" v-show="rightLabelText.length > 0" class="object-item-right-label">{{ rightLabelText }}</div>
				<div v-show="showRemoveButton" class="object-item-remove-button" @click="onClickRemove">
					<svg viewBox="0 0 20 20" class="editor-close-button-icon"><title>Close Icon</title>
						<path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
					</svg>
				</div>
			</div>
			
		</div>

		<transition name="fast-transition">
			<div v-show="isOutlinerVisible" ref="selectorMenu" class="editor-object-selector-menu">
				<ObjectOutliner ref="outliner"></ObjectOutliner>
			</div>
		</transition>
	</div>
</template>

<script>

import ObjectOutliner from './ObjectOutliner.vue'

import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			headerTitle: "",
			title: "",
			isOutlinerVisible: false,
			onSelectMultiple: null,
			key: "",
			rightLabelText: "",
			onClickRemoveCallback: null,
			showRemoveButton: false
		}
	},
	components: {
		ObjectOutliner
	},
	created() {

	},
	mounted() {

	},
	methods: {
		configure(selectedoObjectList) {
			this.updateTitle(selectedoObjectList)

			this.$refs.outliner.multiselect = true
			this.$refs.outliner.configure(selectedoObjectList)
			this.$refs.outliner.onSelectMultiple = this.onSelect
		},
		updateTitle(objectList) {
			var objectLength = objectList.length

			// remove invalid ids from counter
			for (var i in objectList) {
				var obj = objectList[i]
				if (obj.meshId == -1 && obj.nodeId == -1) {
					objectLength -= 1
				}
			}

			this.title = objectLength.toString() + " objects selected"
		},
		onClickRemove(event) {
			event.preventDefault();
			event.stopPropagation();

			if (this.onClickRemoveCallback) this.onClickRemoveCallback(this)
		},
		close () {
			this.isOutlinerVisible = false;
			this.$refs.outliner.onSelect = null
			document.removeEventListener('mousedown', this.closeCallback);
		},
		isElementClosest(element, wrapper) {
			while (element !== document && element !== null) {
				if (element === wrapper) return true;
				element = element.parentNode;
			}
		
			return false;
		},
		onPress() {
			let _this = this

			this.isOutlinerVisible = !this.isOutlinerVisible

			if (this.isOutlinerVisible) {
				var menuTop = 45;
				if (this.headerTitle.length > 0) {
					menuTop += 20
				}
				this.$refs.selectorMenu.style["top"] = menuTop + "px"

				this.$refs.outliner.onSelect = function (index) {
					_this.close()
					_this.onSelect(index)
				}
				this.closeCallback = (evnt) => {
					if (
						!this.isElementClosest(evnt.target, this.$refs.selectorMenu) &&
						!this.isElementClosest(evnt.target, this.$refs.objectSelectorContainer)
					) {
						_this.close();
					}
				};
				document.addEventListener('mousedown', this.closeCallback);
			} else {
				this.close()
			}
		},
		onSelect(objectList) {
			this.onSelectMultiple(objectList)
			this.updateTitle(objectList)
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.object-selector-container {
	width: 100%;
	position: relative;
	text-align: left;
	display: flex;
	cursor: pointer;
	border-radius: 3px;

	background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 1px solid #0000001f;
}
.object-selector-container:hover {
	background-color: rgba(0, 0, 0, 0.04);
	border: 1px solid #0000002f;
}
.editor-object-selector-menu {
	position: absolute;
	top: 70px;
	left: 0px;
	width: 100%;
	min-height: 300px;
	max-height: 300px;
	height: 300px;
    background-color: white;
    border-radius: 5px;
    z-index: 3;
    border: 1px solid #c2c2c2;
    overflow: hidden;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}
.object-item-right-label {
	background-color: black;
	color: white;
	text-align: center;
	
    min-width: 20px;
    line-height: 20px;
    padding: 0px 5px;
    border-radius: 50%;
    
    font-weight: 600;
    font-size: 12px;
}
.object-item-right-elements-container {
	transform: translate(0, -50%);
	right: 5px;
    top: 50%;
    position: absolute;
    height: 20px;
    display: flex;
}
.object-item-remove-button {
	border-radius: 10px;
    width: 20px;
    height: 20px;
    padding: 3px;
    background-color: #cdcdcd;
    margin-left: 4px;
}
.object-item-remove-button:hover {
	background-color: #ededed;
}
.object-selector {
	position: relative;
}
.object-selector-title {
	color: black;
	line-height: 30px;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-left: 10px;
}
</style>
