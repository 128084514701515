<template>
  <transition name="fast-transition">
    <div v-show="show" id="drop-container">
      <div @click="onclickDropContainer()" id="drop-overlay"></div>
      <div id="drop-window">
        <div id="drop-description-top">
          Open new model file
        </div>

        <div id="open-file-window-drop-zone-container">
          <DropZone ref="dropZone" @files-dropped="addFiles" #default="{ dropZoneActive }">
            
            <div class="selected-file-list-container">
              <div class="selected-file-list" v-if="files.length">
                <FilePreview v-for="(file, index) in files" :key="file.name" :index="index" :filesLength="files.length" :file="file" />
              </div>
              <div v-else id="open-file-img-container">
                <img id="open-file-img" src="./../../assets/file.svg" />
              </div>
            </div>

            <div class="drop-files-description-bottom">
              <div v-if="!dropZoneActive">Drag & drop your files here or <button @click="onBrowseFile()" id="open-file-browse-btn">Browse</button></div>
              <div v-else>Drop your files here</div>
              <div id="open-file-desctiption-label">We support <b>fbx, obj, glb</b>, and more. You can also upload a <b>zip</b> file containing model file and textures</div>
            </div>

          </DropZone>
        </div>

        <!--button @click="onOpenFile()" class="default-nice-button" id="drop-window-open-file-btn">
          <span id="share-copy-link-btn-label" class="text-white">Open File</span>
        </button-->

        <div v-if="files.length" id="drop-window-open-btn-container">
          <button @click="onOpen()" class="default-nice-button" id="drop-window-open-btn">
            <div id="open-button-pulse-animation-background"></div>
            <span id="drop-window-open-btn-label">OPEN</span>
            
          </button>
        </div>

        <div id="drop-window-cancel-btn-container">
          <button @click="onCancel()" class="default-unimportant-button" id="drop-window-cancel-btn">
            <span id="drop-window-cancel-btn-label">CANCEL</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { ref } from 'vue'
import DropZone from '../DropZone.vue'
import { KeyCodes } from "../../const"
import FilePreview from './FilePreview.vue'

export default {
  components: {
    DropZone,
    FilePreview
  }, 
  data: function() {
    return {
      show: false,
      files: []
    }
  },
  created() {

  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  methods: {
    addFiles(newFiles) {
      this.files = this.files.concat(newFiles)
    },
    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId)
    },
    removeFile(file) {
      const index = this.files.indexOf(file)
    
      if (index > -1) this.files.splice(index, 1)
    },
    onKeyDown(evt) {
      if (!this.show) return;

      if (evt.keyCode == KeyCodes.ESCAPE) {
        this.close();
      }
    },
    onOpen() {
      this.$editor.addFiles(this.files, false)
    },
    onBrowseFile() {
      this.$editor.statusProgressIsAvailable = false;

      var input = document.createElement('input');
      input.type = 'file';
      input.multiple = "multiple"

      var _this = this;
      input.onchange = e => { 
        for (let i = 0; i < e.target.files.length; ++i) {
          this.files = this.files.concat(e.target.files[i])
        }
      }
      
      input.click();
    },
    onclickDropContainer() {
      this.close();
    },
    onCancel() {
      this.close();
    },
    close() {
      this.show = false;
      this.files = [];
      this.fileNameArray = [];
    }
  }
}
</script>

<style lang="scss">
#drop-description-top {
  padding: 15px;
  font-weight: 500;
  font-size: 24px;
}
#drop-container {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
}
#drop-overlay {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #00000085;
  position: absolute;
}

#drop-window {
/*
  width: 580px;
  padding-bottom: 20px;
  position: absolute;
  
  background-color: #e9e9e9;
  
  
  text-align: center;
  */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  position: absolute;
  background-color: #f7f7f7;
  text-align: center;
}
#drop-window-open-file-btn {
  width: 300px;
}
#open-file-window-drop-zone-container {
  left: 100;
  right: 100;
  bottom: 80;
  top: 70;
  background-color: white;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: black;
}
.default-unimportant-button {
  font-weight: 600 !important;
  color: #5ab5ba !important;
  background-color: rgba(0, 0, 0, 0) !important;
  min-width: 100px !important;
  border-radius: 7px !important;
  padding: 5px !important;
  cursor: pointer !important;
  text-align: center !important;
  border: 2px solid #5ab5ba !important;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.default-unimportant-button:hover {
  background-color: #5ab5ba12 !important;
}
.default-unimportant-button span {
  color: #5AB5B9 !important;
  font-weight: 800;
}
#drop-window-open-btn {
  border-radius: 7px !important;
  height: 40px !important;
}
#drop-window-open-btn-container {
  position: absolute;
  right: 100px;
  bottom: 20px;
}
#drop-window-open-btn-label {
  font-weight: 700;
  z-index: 11;
  position: relative;
}
#drop-window-cancel-btn-container {
  text-align: left;
  position: absolute;
  left: 100px;
  bottom: 20px;
}
#open-file-browse-btn {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: #5ab5ba;
  font-weight: 700;
  padding: 0;
}
#open-file-window-drop-zone {
  color: black;
  font-weight: 500;
}
#open-file-img-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 70%;
  bottom: 0;
  top: 0px;
}
#open-file-img {
  width: auto;
  height: 100%;
}
#open-file-desctiption-label {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}
.selected-file-list {
  border: 1px solid #5da4a7;
  width: 90%;
  border-radius: 5px;
  background-color: #59b5bb2b;
  max-height: 220px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.drop-files-description-bottom {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
}
.selected-file-list-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 70%;
  bottom: 130px;
  top: 0px;
}
#open-button-pulse-animation-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #59b5bb;
  z-index: 10;

  border-radius: 7px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #59b5bbdd;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #59b5bb00;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #59b5bb00;
  }
}
</style>