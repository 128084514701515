var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"in-container invitation"},[_c('div',{staticClass:"invitation__title-container"},[_c('h2',{staticClass:"invitation__title highlighted"},[_vm._v(" "+_vm._s(_vm.$t("invitation.title"))+" ")])]),_c('router-link',{staticClass:"invitation__show-map",attrs:{"to":{
      name: 'Explore',
      params: {
        isMapShown: true,
      },
    }}},[_c('div',{staticClass:"invitation__show-map-text"},[_vm._v(_vm._s(_vm.$t("map.show-map")))])]),(!_vm.isDesktop)?_c('div',{staticClass:"invitation__btn-container"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.isDesktop)?_c('b-link',{staticClass:"button_promo btn",attrs:{"id":"invitation-btn-try","to":{
      name: 'Explore',
    }}},[_vm._v(_vm._s(_vm.$t("btn-start-searching")))]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footer__btn--ios",attrs:{"id":"footer-btn-ios","href":"https://apps.apple.com/app/id1447699048"}},[_c('img',{attrs:{"src":require("./../assets/AppStore.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footer__btn--android",attrs:{"id":"footer-btn-android","href":"https://play.google.com/store/apps/details?id=com.architeque.android.app"}},[_c('img',{attrs:{"src":require("./../assets/GooglePlay.png")}})])
}]

export { render, staticRenderFns }