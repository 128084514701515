<template>
  <transition name="fast-transition">
    <div id="share-container" v-show="show">
      <div @click="onclickContainer()" id="share-overlay"></div>
      <div id="share-window">
        <div id="share-qr-header">
          Use QR code to open model on any device
        </div>
        <div id="qr-img-container">
          <transition name="fast-transition">
            <img v-show="!qrIsGenerating" id="share-qr-img"/>
          </transition>
          <b-spinner id="share-qr-spinner" v-show="qrIsGenerating" variant="dark"></b-spinner>
        </div>
        <button v-if="imageCopyingAvailable" @click="onCopyQR()" class="default-nice-button" id="share-copy-qr-btn">
          <span id="share-copy-qr-btn-label" class="text-white">Copy QR</span>
        </button>
        <div id="share-link-header">
          Or use the link
        </div>
        <div id="share-link-label">

        </div>

        <button @click="onCopyLink()" class="default-nice-button" id="share-copy-link-btn">
          <span id="share-copy-link-btn-label" class="text-white">Copy Link</span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  data: function() {
    return {
      hash: "",
      qrPath: "",
      qrIsGenerating: true,
      copyLinkButtonText: "Copy Link",
      copyQRButtonText: "Copy QR",
      link: "",
      qrData: null,
      show: false,
      imageCopyingAvailable: true
    }
  },
  created() {

  },
  mounted() {
    this.imageCopyingAvailable = navigator.clipboard != null
  },
  methods: {
    showWindow(hash, qrPath) {
      this.hash = hash;
      this.qrPath = qrPath;
      this.show = true;
    },
    onCopyQR() {
      let _this = this;

      try {
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': new Blob([new Uint8Array(this.qrData)], { type: "image/png" })
          })
        ]);
        let copyQRButtonLabelElement = document.getElementById("share-copy-qr-btn-label");
        copyQRButtonLabelElement.innerHTML = "Copied!";

        setTimeout(function() {
          copyQRButtonLabelElement.innerHTML = _this.copyQRButtonText;
        }, 500);
      } catch (error) {
          console.error(error);
      }
    },
    onCopyLink() {
      this.copyTextToClipboard(this.link);

      let copyLinkButtonLabelElement = document.getElementById("share-copy-link-btn-label");
      copyLinkButtonLabelElement.innerHTML = "Copied!";

      let _this = this;
      setTimeout(function() {
        copyLinkButtonLabelElement.innerHTML = _this.copyLinkButtonText;
      }, 500);
    },
    updateQRImage() {
      this.qrIsGenerating = false;

      let qrImageElement = document.getElementById("share-qr-img");

      var data = this.$archengine.FS.readFile(this.qrPath, { encoding: 'binary' });
      var dataBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(data)));
      qrImageElement.src = "data:image/png;base64," + dataBase64;

      let linkLabelElement = document.getElementById("share-link-label");

      
      let link = this.generateSceneLink(this.hash, true);
      //link = eval(link);
      linkLabelElement.innerHTML = link;

      this.link = link;
      this.qrData = data;
    },
    onclickContainer() {
      this.show = false;
    }
  }
}
</script>

<style lang="scss">
#share-container {
  width: 100%;
  height: 100%;
  z-index: 10;
}
#share-overlay {
  width: 100%;
  height: 100%;
  background-color: #00000085;
  position: fixed;
  top: 0px;
}

#share-window {
  width: 380px;
  padding-bottom: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #e9e9e9;
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
  text-align: center;
}
#share-qr-img {
  width: 100%;
  aspect-ratio: 1;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
#qr-img-container {
  margin: 10px 20px 0 20px;
  aspect-ratio: 1;
}
#share-qr-spinner {
  margin-top: 140px;
}
#share-qr-header {
  font-weight: 500;
  font-size: 17px;
  margin: 20px 10px 0 10px;
}
#share-link-label {
  margin-bottom: 15px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #fefefe;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-size: 14px;
}
#share-link-label:hover {
  background-color: #fafafa;
}
#share-copy-link-btn {
  width: 90%;
}
#share-copy-qr-btn {
  width: 90%;
  margin-top: 10px !important;
}
#share-link-header {
  font-weight: 500;
  font-size: 18px;
  margin: 15px 25px 10px 25px;
}
</style>