<template>
	<div class="material-item-container" 
		@click="onPress"
		@mouseover="onHover"
		@mouseout="onOut"
	>
		<div v-show="selected" class="material-item-selected-background"></div>
		<img ref="thumbnailImg" class="material-item-thumnail" />
		<div ref="titleElement" v-html="title" class="material-item-title unselectable"></div>
		<div v-show="highlighted" class="material-item-overlay"></div>
    </div>
</template>

<script>

import SceneMaterials from './SceneMaterials.vue'
import { nextTick } from 'vue'

export default {
	data: function() {
		return {
			title: "",
			selected: false,
			highlighted: false,
			materialEditor: null,
			material: null,
			index: 0,
			change: -1
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		setInterval(this.updateInfo, 500);
	},
	methods: {
		updateInfo() {
			var material = null;
			if (this.material && this.$editor && this.$editor.module) {
				material = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["getMaterial", this.material.id.toString()]);
				material = eval('(' + material + ')')
			}

			if (material && this.change != material.change) {
				this.title = material.name
				if (process.env.NODE_ENV === 'development') {
					var debugTitle = "[" + material.id + "->" + material.substitutionMaterialId + "] " + material.name
					this.title = debugTitle
				}
				var params = {}
				params["materialId"] = Number(material.id)
				params["isThumbnail"] = 1;
				
				var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["getMaterialPreviewData", JSON.stringify(params)]);
				response = eval("(" + response + ")")
				
				if (Object.hasOwn(response, "data")) {
					this.change = material.change;
					this.$refs.thumbnailImg.src = "data:image/png;base64," + response["data"];
				} else if (Object.hasOwn(response, "error")) {
					//this.$editor.showMessage(response.error, true)
				}
			}
		},
		configure(materialEditor, material, index) {
			this.materialEditor = materialEditor;
			this.index = index;

			if (material) {
				if (!this.material) {
					this.material = {}
				}
				Object.assign(this.material, material)
			}

			if (material) {
				this.updateInfo()
			} else {
				this.title = "No Material" // not localized
				this.$refs.thumbnailImg.src = "/img/scene_menu_materials.svg"
				this.$refs.thumbnailImg.style.opacity = 0.5;
			}
		},
		highlight(highlight) {
			this.highlighted = highlight;
		},
		select(select) {
			this.selected = select;
			if (select) {
				this.$refs.titleElement.className = "material-item-title-selected"
			} else {
				this.$refs.titleElement.className = "material-item-title"
			}
		},
		onPress() {
			this.materialEditor.select(this.index, false/*from engine*/)
		},
		onHover() {
			this.materialEditor.highlight(this.index)
		},
		onOut() {
			this.materialEditor.highlight(-1)
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.material-item-selected-background {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #59b5bb;
}
.material-item-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.05);
}
.material-item-container {
	width: 100%;
	height: 40px;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	text-align: left;
	display: flex;
}
.material-item-container:hover {
	cursor: pointer;
}
.material-item-title {
	padding-left: 5px;
	color: black;
	line-height: 40px;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.material-item-title-selected {
	padding-left: 5px;
	color: white;
	line-height: 40px;
	font-weight: 500;
	position: relative;
}
.material-item-thumnail {
	aspect-ratio: 1;
    height: 100%;
    border-radius: 2px;
    z-index: 1;
    padding: 4px;
}
</style>
