<template>
  <v-app>
    <div class="page">
      <Header></Header>
      <router-view :key="$route.fullPath"></router-view>
      <Footer></Footer>

      <Dialog ref="dialog"></Dialog>
      <EmbedScene ref="embed"></EmbedScene>
      <FullscreenPopup ref="fullscreenPopup"></FullscreenPopup>
      <Share ref="share"></Share>
    </div>
  </v-app>
</template>

<script>
import Dialog from "../views/Dialog.vue"
import EmbedScene from "../views/Editor/EmbedScene.vue"
import Share from "../views/Editor/Share.vue"
import Footer from "../views/Footer.vue"
import FullscreenPopup from "../views/FullscreenPopup.vue"
import Header from "../views/Header.vue"

export default {
  components: {
    Header,
    Footer,
    Dialog,
    Share,
    EmbedScene,
    FullscreenPopup,
  },
  data: function () {
    return {}
  },
  mounted() {
    this.$root.$on(
      "showDialog",
      (title, message, cancelText, proceedText, proceedCallback) =>
        this.showDialog(
          title,
          message,
          cancelText,
          proceedText,
          proceedCallback,
        ),
    )
    this.$root.$on(
      "showDialogWithInput",
      (title, message, cancelText, proceedText, proceedCallback) =>
        this.showDialogWithInput(
          title,
          message,
          cancelText,
          proceedText,
          proceedCallback,
        ),
    )
    this.$root.$on("showShareWindow", (hash, qrPath) =>
      this.showShareWindow(hash, qrPath),
    )
    this.$root.$on("updateQRImage", () => this.updateQRImage())
    this.$root.$on("showEmbedSceneWindow", (hash, title) =>
      this.showEmbedSceneWindow(hash, title),
    )
    this.$root.$on("showFullscreenPopup", (item) =>
      this.showFullscreenPopup(item),
    )
    this.$root.showFullscreenPopup = this.showFullscreenPopup
    this.$root.setFullscreenPopupTitle = this.setFullscreenPopupTitle
    this.$root.closeFullscreenPopup = this.closeFullscreenPopup
  },
  methods: {
    closeFullscreenPopup() {
      this.$refs.fullscreenPopup.visible = false
    },
    setFullscreenPopupTitle(title) {
      this.$refs.fullscreenPopup.title = title
    },
    showFullscreenPopup(props) {
      this.$refs.fullscreenPopup.configure(props)
      this.$refs.fullscreenPopup.visible = true
    },
    showDialog(title, message, cancelText, proceedText, proceedCallback) {
      this.$refs.dialog.showDialog(
        title,
        message,
        cancelText,
        proceedText,
        proceedCallback,
      )
    },
    showDialogWithInput(title, message, cancelText, proceedText, proceedCallback) {
      this.$refs.dialog.showDialogWithInput(
        title,
        message,
        cancelText,
        proceedText,
        proceedCallback,
      )
    },
    showEmbedSceneWindow(sceneHash, title) {
      this.$refs.embed.showWindow(sceneHash, title)
    },
    showShareWindow(hash, qrPath) {
      this.$refs.share.showWindow(hash, qrPath)
    },
    updateQRImage() {
      this.$refs.share.updateQRImage()
    },
  },
}
</script>

<style>
.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
