<template>
	<div class="editor-named-objects-property-container">
		<div v-show="headerTitle.length > 0" ref="header" class="editor-property-header">{{headerTitle}}</div>

		<div class="editor-named-objects-subcontainer">
			<EditorPropertyString ref="nameInput"></EditorPropertyString>
			<ObjectOutliner class="editor-objects-property-outliner" ref="outliner"></ObjectOutliner>
		</div>
		
    </div>
</template>

<script>

import ObjectOutliner from './ObjectOutliner.vue'
import EditorPropertyString from './EditorPropertyString.vue'

export default {
	props: {
	},
	data: function() {
		return {
			value: "",
			headerTitle: { type: String, default: "" },
			propKey: { type: String, default: "" },
			onChange: null
		}
	},
	components: {
		ObjectOutliner,
		EditorPropertyString
	},
	created() {

	},
	mounted() {
		
	},
	methods: {
		
		configure(value) {
			this.$refs.outliner.configure(this.$editor.$refs.objects.$refs.outliner.rootObject)

			var name = ""	
			//var materialId = -1
			if (value) {
				name = value.name;
				//materialId = value.materialId
			}
			this.name = name
			//this.materialId = materialId

			this.$refs.nameInput.propKey = "name"
			this.$refs.nameInput.value = name
			this.$refs.nameInput.headerText = "Display name"

			let _this = this
			this.$nextTick(() => {
				//_this.$refs.selector.onChange = _this.onChangeMaterial
				_this.$refs.nameInput.onChange = _this.onNameChange
			});
		},
		onNameChange(value, key) {
			this.name = value
			this.onChange({materialId: this.materialId, name: value}, this.key)
		}
	},
	watch: {
		value (val, oldVal) {
			if (val === oldVal) return;

			if (this.onChange) this.onChange(val, this.propKey);
		}
	}
}

</script>

<style lang="scss">

.editor-objects-property-outliner {
	margin-top: 10px;
    background-color: white;
    border-radius: 3px;
}
.editor-named-objects-subcontainer {
	background-color: rgba(0, 0, 0, 0.05);
	padding: 5px;
	border: 1px solid rgba(0, 0, 0, 0.03);
	border-radius: 5px;
}
.editor-named-objects-property-container {
	position: relative;
}

</style>
