<template>
  <main>
    <div class="website-page in-container">
      <div class="promo__bg"></div>

      <h1 class="visually-hidden">{{ $t("museum.main-title-charter") }}</h1>
      <div class="museum__title-wrapper">
        <h2 class="museum__title highlighted">
          {{ $t("museum.title-charter") }}
        </h2>
      </div>

      <section class="museum__section">
        <div class="museum__upper">
          <div id="archengine-embed-container-museum">
            <iframe
              ref="museumWebSceneFrame"
              title="ARCHITEQUE 3D"
              allowfullscreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              frameborder="0"
            ></iframe>
          </div>
          <div class="museum__upper-features">
            <div class="museum__title-wrapper">
              <h3 class="museum__title-section">
                {{ $t("museum.title-section") }}
              </h3>
            </div>

            <FeaturesListLight
              name-of-features="museum"
              class="museum__features"
            />
            <b-link
              id="application-btn-try"
              :to="{ name: 'Register' }"
              class="button_configurator_promo btn"
              >{{ $t("btn-try-for-free") }}</b-link
            >
          </div>
        </div>
        <!--div class="museum__title-wrapper">
          <h2 class="museum__title highlighted">
            {{ $t("museum.title-chapter2") }}
          </h2>
        </div-->

        <div class="museum__lower">
          <!--div style="width: 100%; aspect-ratio: 1.78; margin: auto">
            <iframe
              src="https://www.youtube.com/embed/4Mbko1K5NII"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              class="embed-video"
            ></iframe>
          </div-->
        </div>
      </section>
      <section>
        <DesctiptionsList
          name-of-descroptions="museum"
          :list-of-descriptions="descriptions"
        />
      </section>
      <div class="museum__subtitle">
        <h2 class="museum__title highlighted">
          {{ $t("museum.save-for-future") }}
        </h2>
      </div>
      <SearchWithMap :is-map-shown="true" />
    </div>
  </main>
</template>

<script>
import DesctiptionsList from "./DesctiptionsList.vue"
import FeaturesListLight from "./FeaturesListLight.vue"
import SearchWithMap from "./SearchWithMap/SearchWithMap.vue"

export default {
  components: {
    FeaturesListLight,
    DesctiptionsList,
    SearchWithMap,
  },
  data: function () {
    return {
      descriptions: ["scale", "features", "history", "education", "media"],
      modelsList: [],
      isLoading: true,
    }
  },
  created() {
    document.title =
      this.$root.$i18n.messages[this.$root.$i18n.locale].titles["museum"]
  },
  updated() {
    document.title =
      this.$root.$i18n.messages[this.$root.$i18n.locale].titles["museum"]
  },
  mounted() {
    // Update frame src
    var src =
      this.getArchwebURL(true) +
      "transparent=1&uiStyle=light&allowScale=0&allowMoveCameraSideways=0&showCredit=0&allow-fullscreen=0&annotations-toggle=0&annotations-visible=1&camera-direction=0.8,-0.1,-0.5&vignette=1&background-color=ffffff&show-logo=0&show-info=0"
    if (process.env.NODE_ENV === "development") {
      src += "&api=" + process.env.VUE_APP_API_BASEURL
      src += "&website-url" + process.env.VUE_APP_WEBSITE_URL
      src += "&allowCDN=0"
    }
    src += "&scene=38f6bdf1b177eecbc11f"

    this.$refs.museumWebSceneFrame.src = src
  },
}
</script>

<style lang="scss">
.museum__lower_subheader {
  text-align: center;
}

#archengine-embed-container-museum {
  width: 100%;
  position: relative;

  /*margin: auto;
  aspect-ratio: 1.78;*/

  @media (max-width: $mobile-width-only) {
    margin-top: 30px;
    height: 300px;
  }
}

#archengine-embed-container-museum iframe {
  @media (max-width: $mobile-width-only) {
    width: 170%;
    height: 170%;
    position: absolute;
    left: -30%;
    top: -35%;
    max-width: 170%;
  }
  @media (min-width: $tablet-width) {
    width: 150%;
    height: 150%;
    position: absolute;
    left: -25%;
    top: -35%;
    max-width: 150%;
  }
  /*background-color: #ededed;
  box-shadow: inset 0px 0px 180px rgba(0, 0, 0, 0.3);*/
}

.museum__title-wrapper {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 10px !important;
  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }
}

.museum__title {
  font-size: 36px !important;
  line-height: 44px !important;

  display: inline;

  @extend .font-title;
}

.museum__title2 {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: 28px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;
}

.museum__text {
  @extend .support-text;
  max-width: 800px;
  display: inline-block;
  width: 100%;
}

.museum__text_container {
  text-align: center;
}

.museum__title-section {
  margin-bottom: 20px !important;
  color: white;
  text-align: left;

  @media (max-width: $mobile-width-only) {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }

  @extend .title-section;
}

.museum__img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 50px;
}

.museum_before_img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 0px;
  margin: auto;
  display: flex;
  margin-bottom: 40px;
}

.museum__upper {
  display: grid;

  background-color: #59b5bb;
  border-radius: 20px;
  padding: 30px;

  @media (min-width: $tablet-width) {
    grid-gap: 7%;
    grid-template-columns: 40% 50%;
  }
}

.museum__lower_header {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 20px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.museum__lower {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }
  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.museum__upper-features {
  @media (min-width: $tablet-width) {
    padding-top: 30px;
  }
}

.museum__features {
  padding-top: 0px;
  color: white;
  margin-bottom: 20px !important;
}

.descriptions__item--media::before {
  background-image: url("./../assets/media.png");
}

.descriptions__item--features::before {
  background-image: url("./../assets/features.png");
}

.descriptions__item--history::before {
  background-image: url("./../assets/history.png");
}

.descriptions__item--education::before {
  background-image: url("./../assets/education.png");
}

.museum__subtitle {
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;

  .museum__title {
    display: inline;

    @extend .font-title;
  }
}
#application-btn-try {
  margin: 20 0 0 0;
  width: 100%;
}
.museum__map-container {
  text-align: center;

  .museum__title {
    display: inline;

    @extend .font-title;
  }
  .map__map {
    height: 400px;
    margin-top: 40px;
  }
}
</style>
