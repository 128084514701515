<template>
    <div class="map-select-location">
        <div class="map-select-location__content">
            <div class="map-select-location__container">
                <div class="map-select-location-input-container">
                    <div>
                        <div>Latitude</div>
                        <b-form-input ref="latitudeInput" v-model="latitude" class="map-select-location__search-input"
                            maxlength="32" />
                    </div>
                    <div>
                        <div>Longitude</div>
                        <b-form-input ref="longitudeInput" v-model="longitude" class="map-select-location__search-input"
                            maxlength="32" />
                    </div>

                    <div>
                        <div>Search By Address</div>
                        <b-form-input :placeholder="$t('Address')" id="map-select-location-search-input"
                            ref="searchPlaceInput" v-model="searchText" class="map-select-location__search-input"
                            maxlength="32" />
                    </div>
                    <div>
                        <div>Search Status:</div>
                        <div class="map-select-location-search-status" ref="searchResultsStatus">Empty input</div>
                    </div>
                </div>

                <Map ref="map" :showPointerOverlay="true" :is-map-shown="true" :is-search-in-progress="false" :modelsList="modelsList"
                    :mapInitListener="onMapInit" :map-listener="onMapMove" :centerChangeListener="onChangeCenter" />
            </div>
        </div>
    </div>
</template>

<script>

import { nextTick } from 'vue';
import Map from "./Map.vue";


/*
export default class SomeComponent extends Vue {
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      placeId: '',
      geocoder: null
    }
  }
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      if(this.google) {
        this.geocoder = new this.google.maps.Geocoder();
      }
    });
  },
  methods: {
    geocode() {
      this.geocoder.geocode({placeId: this.placeId}, (results, status) => {
        console.log(results,status);
      })
    }
  }
}
*/

export default {
    components: {
        Map,
    },
    props: {
        center: {
            default() {
                return DEFAULT_COORDINATES
            },
            type: Object,
        },
        modelsList: {
            default: null,
            type: Array,
        },
    },
    data: function () {
        return {
            searchText: "",
            searchInProgress: false,
            latitude: 0,
            longitude: 0,
            ignoreLocationChange: false
        }
    },
    watch: {
        searchText(newValue) {
            if (newValue.length == 0) {
                this.$refs.searchResultsStatus.innerHTML = "Empty input"
                return
            }
            this.$refs.searchResultsStatus.innerHTML = "Searching..."

            const _this = this
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: newValue }, (results, status) => {
                if (status == "OK") {
                    console.log(results, status);
                    let result = results[0]
                    let location = results[0].geometry.location
                    _this.latitude = location.lat()
                    _this.longitude = location.lng()
                    _this.$refs.searchResultsStatus.innerHTML = "Found"
                } else {
                    _this.$refs.searchResultsStatus.innerHTML = "Nothing found"
                }
            })
        },
        latitude(newValue) {
            if (this.ignoreLocationChange) return

            const _this = this
            this.ignoreLocationChange = true
            const coord = { lat: Number(newValue), lng: Number(this.longitude) }
            this.$refs.map.map.panTo(coord);

            nextTick(() => {
                _this.ignoreLocationChange = false
            })
        },
        longitude(newValue) {
            if (this.ignoreLocationChange) return

            const _this = this
            this.ignoreLocationChange = true
            const coord = { lat: Number(this.latitude), lng: Number(newValue) }
            this.$refs.map.map.panTo(coord);

            nextTick(() => {
                _this.ignoreLocationChange = false
            })
        },
    },
    mounted() {
        this.$refs.map.center = this.center
    },
    methods: {
        updateCurrentLocation(map) {
            let c = map.getCenter()

            if (!this.ignoreLocationChange) {
                this.ignoreLocationChange = true
                this.latitude = c.lat()
                this.longitude = c.lng()

                const _this = this
                nextTick(() => {
                    _this.ignoreLocationChange = false
                })
            }
        },
        onMapInit(map) {
            this.updateCurrentLocation(map)
        },
        onChangeCenter(map) {
            this.updateCurrentLocation(map)
        },
        onMapMove(map) {
            const _this = this
            return function () {

            }
        }
    },
}
</script>

<style lang="scss">
.map-select-location {
    &__content {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    &__map {
        width: 100%;
        height: 90%;
        flex-grow: 1;
    }

    &__button {
        color: white;
        background-color: $bg-dark;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        padding: 10px 25px;
        border-radius: 40px !important;
        border: 0px;
    }

    &__button:hover,
    &__button:focus {
        opacity: 0.9;
    }

    &__button:active {
        opacity: 1;
        background-color: $secondary-dark;
    }

    &__search-input {
        width: 100%;
        margin-bottom: 20px !important;
    }
}

.map-select-location-input-container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.map-select-location-search-status {
    line-height: 40px;
    background-color: #00000017;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    color: #898989;
    text-align: center;
}
</style>
