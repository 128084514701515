<template>
  <div ref="button" class="round-button" @mouseover="onOver" @mouseleave="onOut" @mousedown="onDown" @mouseup="onUp" @click="onClick">
    <div ref="background" class="round-button-background">

    </div>
    <div v-show="showHighlight" class="round-button-fill round-button-highlight">

    </div>
    <div v-show="showDown" class="round-button-fill round-button-down">

    </div>
    <span v-show="showTitle && !showSpinner" ref="title">
      {{ title }}
    </span>
    <b-spinner v-if="showSpinner" variant="light"></b-spinner>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  props: {
    titleDefault: { type: String, default: "" },
  },
  data: function () {
    return {
      backgroundColor: "",
      title: "",
      showHighlight: false,
      showDown: false,
      showTitle: true,
      showSpinner: false,
      clickCallback: null
    }
  },
  components: {

  },
  created() {

  },
  updated() {

  },
  mounted() {
    if (this.titleDefault.length > 0) {
      this.title = this.titleDefault
    }
    this.backgroundColor = "rgb(188, 68, 68)"
  },
  methods: {
    onClick() {
      if (this.clickCallback) {
        this.clickCallback()
      }
    },
    onDown() {
      this.showDown = true
    },
    onUp() {
      this.showDown = false
    },
    onOver() {
      this.showHighlight = true
    },
    onOut() {
      this.showHighlight = false
      this.showDown = false
    },
    setSecondary(value) {
      
    }
  },
  watch: {
    backgroundColor(newValue) {
      this.$refs.button.style["background-color"] = newValue
      //this.$refs.button.style["box-shadow"] = "0 16px 24px rgba(188, 68, 68, 0.2), 0 2px 6px rgba(188, 68, 68, 0.12), 0 0 1px rgba(0, 0, 0, 0.04)"
    },
    title(newValue) {
      this.$refs.title.innerHTML = newValue
    }
  }
}  
</script>

<style lang="scss">
.round-button {
  cursor: pointer;

  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: $white !important;

  transition: 0.15s ease-in-out;
  position: relative;

  display: -webkit-inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px !important;
  border-radius: 40px !important;

  @extend .font-link-btn-1;
  @media (min-width: $tablet-width) {}

  @media (max-width: $mobile-width-only) {
    width: 100%;
  }
}

.round-button-fill {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.round-button-highlight {
  background-color: rgba(255, 255, 255, 0.1);
}

.round-button-down {
  background-color: rgba(255, 255, 255, 0.1);
}</style>