<template>
	<div class="editor-small-close-button">
		<button @click="_onClick" type="button" class="editor-close-button">
			<svg viewBox="0 0 24 24" class="editor-close-button-icon"><title></title>
				<path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path>
			</svg>
		</button>
    </div>
</template>


<script>

export default {
	data: function() {
		return {
			onClick: null
		}
	},
	components: {

	},
	created() {

	},
	mounted() {

	},
	methods: {
		_onClick() {
			if (this.onClick) this.onClick(this)
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">
.editor-close-button-icon {
	width: 12px;
	height: 12px;
}

.editor-close-button {
	width: 24px;
	height: 24px;
	border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    transform: translate(50%, -50%);
    background-color: rgba(0, 0, 0, 0.4);
    fill: #fff;
    outline: none;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.editor-close-button:hover {
	background-color: rgba(0, 0, 0, 0.3);
}
</style>