<template>
  <div ref="container" class="tab-switch-container">
    
  </div>
</template>

<script>

import Tab from './Tab.vue'
import Vue from 'vue'

export default {
  data: function() {
    return {
      items: [],
      onSelect: null
    }
  },
  mounted() {
    
  },
  updated() {

  },
  methods: {
    createTabItem(info, onSelect) {
      var ComponentClass = Vue.extend(Tab)
      var item = new ComponentClass()

      item.$mount() // pass nothing
      item.configure(info)
      item.onSelect = this.onSelect

      return item
    },
    onSelectTab(item) {
      if (this.onSelect) {
        this.onSelect(item.index)
      }
      this.selectTab(item.index)
    },
    addTab(info) {
      let item = this.createTabItem(info)
      item.index = this.items.length
      item.onSelect = this.onSelectTab

      this.$refs.container.appendChild(item.$el)
      this.items.push(item)
    },
    selectTab(index) {  
      for (let itemIndex in this.items) {
        let item = this.items[itemIndex]
        item.select(Number(itemIndex) == index)
      }
    },
    setTabTitle(index, title) {
      this.items[index].setTitle(title)
    }
  }
}
</script>

<style lang="scss">

.tab-switch-container {
  border-bottom: 1px solid #bcbcbc;
  margin-bottom: 15px;  
  margin-top: 10px;
  position: relative;
}

</style>
