<template>
	<div class="editor-property-container">
		<div ref="header" class="editor-property-header">{{titleText}}</div>
		<EditorSelector ref="selector"></EditorSelector>
    </div>
</template>

<script>

import EditorSelector from './EditorSelector.vue';

export default {
	props: {
		propKey: { type: String, default: "" }
	},
	data: function() {
		return {
			value: "",
			titleText: { type: String, default: "" },
			options: [],
			onChange: null
		}
	},
	components: {
		EditorSelector
	},
	created() {
		
	},
	mounted() {
		var _this = this;

		this.$refs.selector.changeCallback = function(value) { 
			_this.value = value; 
		}
	},
	methods: {

	},
	watch: {
		options(val, oldValue) {
			this.$refs.selector.configure(val, val) 
		},
		value (val, oldVal) {
			if (val === oldVal) return;

			this.$refs.selector.setSelected(val)
			if (this.onChange) {
				this.onChange(val, this.propKey);
			}
		}
	}
}

</script>

<style lang="scss">


</style>
