import { Languages } from "../const"

export const getLang = () => {
  let lang = ""

  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0]
  } else if (navigator.userLanguage) {
    lang = navigator.userLanguage
  } else {
    lang = navigator.language
  }
  lang = lang[0] + lang[1]
  if (!Object.prototype.hasOwnProperty.call(Languages, lang)) {
    lang = "en"
  }

  return lang
}
