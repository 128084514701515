<template>
  <div class="editor-side-menu-item-container">
    <button @click="_onClick()" ref="button" class="editor-side-menu-item">
      <div class="editor-side-menu-item-img-wrapper-on" v-show="on">
        <img class="editor-side-menu-item-img" ref="imgOn"/>
      </div>
      <div class="editor-side-menu-item-img-wrapper-off" v-show="!on">
        <img class="editor-side-menu-item-img" ref="imgOff"/>
      </div>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    iconName: null,
    index: null,
    enabledDefault: null,
    togglable: {type: Boolean, default: false},
    keepToggled: {type: Boolean, default: true}
  },
  data: function() {
    return {
      on: false,
      enabled: true,
      onClick: null
    }
  },
  created() {

  },
  mounted() {
    this.enabled = this.enabledDefault

    this.updateIcon();
    this.$refs.button.disabled = !this.enabled;
  },
  methods: {
    _onClick() {
      if (!this.enabled) return;


      if (this.togglable) {
        this.on = !this.on;
      } else {
        if (this.keepToggled) {
          this.on = true;
        }
      }
      
      if (this.onClick) this.onClick(this)
    },
    updateIcon() {
      let iconPath = "/img/" + this.iconName;
      this.$refs.imgOn.src = iconPath
      this.$refs.imgOff.src = iconPath
    },
    onSceneOpened() {
      
    }
  },
  watch: {
    iconName(newValue, oldValue) {
      this.updateIcon();
    },
    enabled(newValue, oldValue) {
      this.$refs.button.disabled = !newValue;
    }
  }
}
</script>

<style lang="scss">
.editor-side-menu-item {
  width: 100%;
  aspect-ratio: 1;

  margin-bottom: 5px;
  cursor: pointer;
  padding: 5%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 3px !important;
}
.editor-side-menu-item:disabled {
  opacity: 0.4;
  cursor: default;
}
.editor-side-menu-item-img {

}
.editor-side-menu-item-container {
  padding: 2px;
}
.editor-side-menu-item-img-wrapper-on {
  background-color: black;
  padding: 7px;
  border-radius: 6px;
  /*box-shadow: 0 16px 24px rgba(90, 181, 186, 0.2), 0 2px 6px rgba(90, 181, 186, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);*/
}
.editor-side-menu-item-img-wrapper-off {
  padding: 7px;
  border-radius: 3px;
  /*background-color: rgba(0, 0, 0, 0.2);*/
}
.editor-side-menu-item:enabled:hover {
  background-color: rgba(0, 0, 0, 0.1);
} 

</style>