<template>
  <div class="editor-side-menu">
    
    <SideMenuItem ref="itemInfo" id="item-scene-info" :index=0 :enabledDefault=true iconName="scene_menu_desc_selected.svg"></SideMenuItem>
    <SideMenuItem ref="itemProps" id="item-scene-props" :index=1 :enabledDefault=false iconName="scene_menu_options_selected.svg"></SideMenuItem>
    <SideMenuItem ref="itemAnnotations" id="item-scene-annotations" :index=2 :enabledDefault=false iconName="scene_menu_annotations_selected.svg"></SideMenuItem>
    <SideMenuItem ref="itemHierarchy" id="item-scene-hierarchy" :index=3 :enabledDefault=false iconName="scene_menu_hierarchy_selected.svg"></SideMenuItem>
    <SideMenuItem ref="itemMaterials" id="item-scene-materials" :index=4 :enabledDefault=false iconName="scene_menu_materials_selected.svg"></SideMenuItem>
    <SideMenuItem ref="itemTextures" id="item-scene-textures" :index=5 :enabledDefault=false iconName="scene_menu_textures.svg"></SideMenuItem>
    <SideMenuItem ref="itemAnimations" id="item-scene-animations" :index=6 :enabledDefault=false iconName="scene_menu_animations_selected.svg"></SideMenuItem>
    <SideMenuItem ref="itemConfigurator" id="item-scene-configurator" :index=7 :enabledDefault=false iconName="scene_menu_configurator.svg"></SideMenuItem>
    <SideMenuItem ref="itemMap" id="item-scene-map" :index=8 :enabledDefault=false iconName="scene_menu_map.svg"></SideMenuItem>

    <b-tooltip triggers='hover' target="item-scene-info" placement="left">
      <b>Public information</b><br><br>Information that may be visible in searches, lists and your profile. Remember that any new 3D scene is private by default!
    </b-tooltip>
    
    <b-tooltip triggers='hover' target="item-scene-props" placement="left">
      <b>Scene properties</b><br><br>Change internal 3D scene options like background, initial camera position and more
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-hierarchy" placement="left">
      <b>Scene hierarchy</b><br><br>Useful for selecting hidden or unreachable meshes in the scene
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-materials" placement="left">
      <b>Materials</b><br><br>Materials management
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-annotations" placement="left">
      <b>Annotations</b><br><br>You can enhance your 3D scene experience with little bits of information placed on the model
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-textures" placement="left">
      <b>Textures</b><br><br>Add and remove textures
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-animations" placement="left">
      Animations (Coming soon!)
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-configurator" placement="left">
      <div v-show="showDisabledConfiguratorToolip">
        <div class="editor-text-tooltip-warning">{{ configuratorDisabledTooltip }}</div>
        <br>
      </div>
      
      <b>Configurator</b> <br><br>Here you can make your scene configurable by switching materials or changing objects visibility<br><br>
      <b>Configurator options</b><br><br>Scene modifications in these options will be applied when an option is selected. Available modificators:<br><br><b>Material variation: </b> modificator that changes one or multiple materials in the scene (paint color, material of a model part, wood/glass, etc.)<br><br><b>Show objects: </b> this modificator makes visible specific objects in the scene<br><br><b>Hide objects: </b> this modificator makes hidden specific objects in the scene
    </b-tooltip>

    <b-tooltip triggers='hover' target="item-scene-map" placement="left">
      <b>Map</b><br><br>Manage model location on Architeque World Map
    </b-tooltip>
  </div>
</template>

<script>

import SideMenuItem from "./SideMenuItem.vue";

export default {
  components: {
    SideMenuItem
  },
  data: function() {
    return {
      selectedIndex: 0,
      configuratorDisabledTooltip: "Model isn't part of a subscription bundle that allows configurators",
      showDisabledConfiguratorToolip: false
    }
  },
  created() {

  },
  mounted() {
    this.$refs.itemInfo.on = true;

    var items = this.getItems();
    for (var i in items) {
      var item = items[i]
      item.onClick = this.onClickItem
    }
  },
  methods: {
    getItems() {
      return [
        this.$refs.itemInfo,
        this.$refs.itemProps,
        this.$refs.itemAnnotations,
        this.$refs.itemHierarchy,
        this.$refs.itemMaterials,
        this.$refs.itemTextures,
        this.$refs.itemAnimations,
        this.$refs.itemConfigurator,
        this.$refs.itemMap
      ]
    },
    onClickItem(item) {
      var index = item.index
      var items = this.getItems()
      for (var i = 0; i < items.length; ++i) {
        items[i].on = i == index
      }
      this.selectedIndex = index
      this.$editor.onClickMenuItem(index)
    },
    onSceneOpened(jsonProps) {
      this.$refs.itemProps.enabled = true;
      this.$refs.itemAnnotations.enabled = true;
      this.$refs.itemHierarchy.enabled = true;
      this.$refs.itemTextures.enabled = true;
      this.$refs.itemMaterials.enabled = true;
      this.$refs.itemMap.enabled = true;
      //this.$refs.itemConfigurator.enabled = true;
    },
    setUserInfo(info) {

    },
    onSceneInfoReceived(scene) {
      var enableConfigurator = scene.enableConfigurator
      this.$refs.itemConfigurator.enabled = enableConfigurator
      this.showDisabledConfiguratorToolip = !enableConfigurator
    },
    resetMenuItems() {

    },
    onClickSceneInfo() {
      this.$editor.updateAllPanels();
    },
    onClickSceneProperties() {
      this.$editor.updateAllPanels();
    }
  }
}
</script>

<style lang="scss">

.editor-side-menu {
  background-color: #00000021;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.04);
}

</style>