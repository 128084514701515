<template>
	<div class="configurator-editor">
		<div>
			<div ref="outliner" class="configurator-outliner">
				<div id="configurator-outliner-background" @click="deselect"></div>
			</div>
	
			<div id="configurator-outliner-button-container">
				<button                                   @click="onAddConfigurator()" id="configurator-add-btn" class="editor-default-btn">Add</button>
				<button :disabled='!showConfiguratorEdit' @click="onRemoveConfigurator()" id="configurator-remove-btn" class="editor-destructive-btn">Remove</button>
			</div>
	
			<div v-html="selectedConfiguratorName" class="configurator-selected-header"></div>
		</div>

		<div ref="configuratorProperties" v-show="showConfiguratorEdit" class="editor-configurator-properties-container">
		</div>

		<div class="configurator-edit-hint" v-show="!showConfiguratorEdit">Select a config item to edit</div>
    </div>
</template>

<script>

import ConfiguratorItem from './ConfiguratorItem.vue'

import Vue from 'vue'
import { nextTick } from 'vue'

let configuratorEditor;

export default {
	data: function() {
		return {
			configuratorItemList: [],
			configuratorList: [],
			showConfiguratorEdit: false,
			userEditing: true,
			selectedIndex: -1,
			selectedId: -1,
			selectedConfiguratorName: "",
			ignorePropertyChange: false,
			propertyEditConfig: [ 
				{ key: "name", type: "string", title: "Name"}, 
				{ 
					key: "options", 
					type: "configuratorOption", 
					title: "Options", 
					addButtonTitle: "Add option", 
					multiple: true, 
					optionTitleCallback: function(optionIndex) { return configuratorEditor.getOptionTitle(optionIndex); },
					showRemoveButton: false, 
					modificationValues: ["material", "showObjects", "hideObjects"], 
					modificationTitles: ["Material variation", "Show objects", "Hide objects"]}
			],
			propertyEditItemMap: {},
			texturePropTypeMap: {
				"transparency" : "Opacity",
				"baseColor" : "Diffuse",
				"diffuseColor": "diffuse",
				"specularColor": "specular",
				"normalMap" : "NormalMap",
				"metallicRoughness" : "MetallicRoughness",
				"envMap" : "EnvironmentMapCylindrical"
			},
			textureTypeMap: {
				"baseColor": 1,
				"normalMap": 3,
				"specularColor": 4,
				"transparency": 7,
				"metallicRoughness": 18,
				"envMap": 19
			}
		}
	},
	components: {
		
	}, 
	created() {
		configuratorEditor = this
	},
	mounted() {
		
	},
	methods: {
		getOptionTitle(index) {
			if (index == 0) {
				return "Option 1 (Default)"
			} else {
				return "Option " + (index + 1).toString()
			}
		},
		getTypeOptions() {
			return [
				{ value: "material", title: "Material" }
			]
		},
		onChangePropertyType(key, type) {
			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)
			configuratorEntry.type = type
			this.emitConfiguratorChange(false)
		},
		configure(configuratorConfig) {
			var configuratorList = configuratorConfig.entries;

			// remove items
			var itemList = this.configuratorItemList
			for (var itemIndex in itemList) {
				var el = itemList[itemIndex]
				if (el == null) continue;
				el.$el.parentNode.removeChild(el.$el);
			}
			
			this.configuratorItemList = [];
			this.configuratorList = [];

			for (var index in configuratorList) {
				var ComponentClass = Vue.extend(ConfiguratorItem)
				var item = new ComponentClass()

				item.$mount() // pass nothing
				item.configure(this, configuratorList[index], index)
				this.$refs.outliner.appendChild(item.$el)

				this.configuratorItemList.push(item)
			}
			this.configuratorList = configuratorList;
		},
		deselect() {
			this.select(-1, false);
		},
		showPropertiesEditor() {
			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)

			this.ignorePropertyChange = true;
			this.$refs.configuratorProperties.innerHTML = ""

			this.$editor.addProperties(this.propertyEditConfig, configuratorEntry, this.$refs.configuratorProperties, this.propertyEditItemMap, this.onChangeProperty, this.getPropertyType, this.getPropertyValue, this.onChangePropertyType)

			var _this = this
			this.$nextTick(() => {
				_this.ignorePropertyChange = false
			});
		},
		getPropertyType(key) {
			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)
			if (!configuratorEntry) return ""

			return configuratorEntry.type
		},
		getPropertyValue(key, type) {
			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)
			if (!configuratorEntry) return ""

			// return first material from material options
			if (key == "options") {
				if (configuratorEntry.options && configuratorEntry.options.length > 0) {
					configuratorEntry.options
				} else {
					return null;
				}
			} else if (type == "color") {
				return this.convertColorXYZToHex(configuratorEntry[key])
			}

			return configuratorEntry[key]
		},
		onChangeProperty(value, key, customProps) {
			if (this.ignorePropertyChange) return;
			
			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)
			var resetEditor = false
			if (customProps && Object.hasOwn(customProps, "resetEditor")) {
				resetEditor = customProps.resetEditor
			}

			if (Object.hasOwn(value, "type")) {
				if (value.type == "color") {
					value = this.convertHexColorToXYZ(value.value)
				}
			}

			configuratorEntry[key] = value
			
			this.emitConfiguratorChange(resetEditor)
		},
		select(index) {
			var _this = this;

			this.userEditing = false;

			var newSelectedName;
			var newConfiguratorId;

			for (var i in this.configuratorItemList) {
				var item = this.configuratorItemList[i]

				item.select(i == index)

				if (i == index) {
					newConfiguratorId = this.configuratorList[i].id
					this.selectedConfiguratorName = this.configuratorList[i].name
				}
			}
			this.selectedIndex = index;
			this.selectedId = newConfiguratorId
			this.showConfiguratorEdit = index >= 0;

			if (this.showConfiguratorEdit) {
				this.showPropertiesEditor();
			}
			this.$nextTick(() => {
				_this.userEditing = true;
			});
		},
		onAddConfigurator() {
			var configuratorConfig = this.$editor.module.ccall('callFromJS', 'string', ['string'], ["addConfiguratorEntry"]);
			configuratorConfig = eval('(' + configuratorConfig + ')');

			this.configure(configuratorConfig);
			this.$refs.outliner.scrollTo(0, this.$refs.outliner.scrollHeight);
			this.select(this.configuratorItemList.length - 1);
			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		onRemoveConfigurator() {
			var configuratorConfig = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["removeConfiguratorEntry", this.selectedId.toString()]);
			configuratorConfig = eval('(' + configuratorConfig + ')');

			this.select(-1);
			this.configure(configuratorConfig);
			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		updatePropertyItems() {
			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)
			for (var key in this.propertyEditItemMap) {
				var item = this.propertyEditItemMap[key]
				item.value = configuratorEntry[key]
			}
		},
		emitConfiguratorChange(resetEditor) {
			var _this = this;

			var configuratorEntry = this.getConfiguratorEntryForId(this.selectedId)

			var response = this.$editor.module.ccall('callFromJS', 'string', ['string', 'string', 'string'], ["editConfiguratorEntry", configuratorEntry.id.toString(), JSON.stringify(configuratorEntry, null, 2)]);

			var responseObject = eval( '(' + response + ')');
			var configuratorObjectFromEngine = responseObject.configurator;
			var errorObject = responseObject.error;

			this.setConfiguratorEntryForId(configuratorObjectFromEngine, this.selectedId)

			if (errorObject.length > 0) {
				this.$editor.showMessage(errorObject, true)
				this.$nextTick(() => {
					_this.updatePropertyItems()
				});
			}

			this.selectedConfiguratorName = configuratorObjectFromEngine.name
			if (resetEditor) {
				this.showPropertiesEditor()
			}

			this.$editor.onChangeIsMadeForce(true /*scene*/, false /*info*/);
		},
		setConfiguratorEntryForId(entry, id) {
			for (var i in this.configuratorList) {
				var confEntry = this.configuratorList[i]
				if (confEntry.id == id) {
					this.configuratorList[i] = entry
					break;
				}
			}

			for (var index in this.configuratorItemList) {
				var item = this.configuratorItemList[index]

				if (item.configuratorEntry.id == entry.id) {
					item.title = entry.name
				}
			}
		},
		getConfiguratorEntryForId(id) {
			for (var i in this.configuratorList) {
				var confEntry = this.configuratorList[i]
				if (confEntry.id == id) {
					return confEntry
				}
			}
			return null
		}
	},
	watch: {

	}
}

</script>

<style lang="scss">
.configurator-editor {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.editor-configurator-properties-container {
	padding: 0px 10px;
	width: 100%;
	overflow: auto;
	overflow-x: hidden;
	flex-grow: 1;
}
#configurator-outliner-background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
#configurator-outliner-button-container {
	width: 100%;
	height: 42px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
	grid-gap: 5px;
	justify-content: center;
	padding: 5px 5px;
	width: 100%;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.04);
}
.configurator-editor {
	text-align: center;
}
.configurator-edit-hint {
	text-align: center;
	margin: 15px 10px;
	color: #121212;
	position: absolute;
	top: 280px;
	width: 100%;
}
.configurator-selected-header {
	width: 100%;
	height: 30px;
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: #e1e1e1;
	padding-left: 10px;
	font-weight: 600;
	text-align: left;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.configurator-outliner {
	width: 100%;
	height: 150px;
	overflow: auto;
	position: relative;
	overflow-x: hidden;
	background-color: white;
}

</style>
