<template>
  <main>
    <div id="not-found-container">
      <img id="not-found-img" src="../assets/not-found.svg" />
      <h2 class="not-found__text">{{$t('404.text')}}</h2>
    </div>
  </main>
</template>

<script>
export default {
    created() {
      document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["not-found"];
    },
    updated() {
      document.title = this.$root.$i18n.messages[this.$root.$i18n.locale].titles["not-found"];
    }
}
</script>

<style lang="scss">

.not-found {
  padding-bottom: 140px;
}
.not-found__title {
  @extend .title-chapter;
}

.not-found__text {
  margin-top: 150px;
  text-align: center;

  @extend .text;
}

#not-found-container {
  margin-top: 70px !important;
  text-align: center;
  margin: auto;
}
#not-found-img {
  width: 100px;
  margin-bottom: 50px;
}
</style>